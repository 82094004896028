import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Archive, Create, Favorite, FavoriteBorder, Unarchive } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectShowAccelerationSettings, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useNumberFormatter } from '@/common/hooks'

import TbxTooltip from '../TbxTooltip/TbxTooltip'
import UpgradePlanLink from '../UpgradePlanLink/UpgradePlanLink'

const classes = {
    root: {
        gap: '24px',
        maxWidth: '400px',
        minWidth: '300px',
        background: 'background.paper',
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: '8px',
        boxShadow: 'none',
        flex: '1 1 22%',
    },
    archived: {
        color: 'text.disabled',
    },
    cardHeader: {
        paddingBottom: '0px',
    },
    cardHeaderIcon: {
        padding: '6px',
        color: 'text.secondary',
        '&:hover': {
            color: 'text.primary',
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    infoGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoStrong: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
    },
}

const modeOptions = [
    { name: 'None', value: 'None' },
    { name: 'Line Item', value: 'ByLine' },
    { name: 'Material', value: 'ByGroup' },
]

const TbxTechCard = ({ canAddCuttingTech, cuttingTech, onEdit, onToggleAttribute }) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)
    const { n } = useNumberFormatter({
        locale: locale,
    })

    const useImperialUnits = useSelector(selectUseImperialUnits)

    const [isRotary] = useState(cuttingTech.type === 'Rotary')

    const toggleAttribute = (payload) => {
        onToggleAttribute(payload)
    }

    const showAccelerationSettings = useSelector(selectShowAccelerationSettings)

    const containerStyle = Object.assign({}, classes.root, cuttingTech.isDeleted ? classes.archived : {})

    return (
        <TbxTooltip
            title={!canAddCuttingTech && !cuttingTech.isDefault ? <UpgradePlanLink /> : ''}
            arrow
        >
            <Card
                data-testid="cutting-technology-card"
                key={cuttingTech.cuttingTechnologyId}
                sx={containerStyle}
            >
                <CardHeader
                    action={
                        <>
                            <TbxTooltip
                                title={
                                    cuttingTech.isDefault
                                        ? t('Default cutting technology')
                                        : t('Set as default cutting technology')
                                }
                                arrow
                            >
                                <span>
                                    <IconButton
                                        aria-label="set as default"
                                        data-testid="set-as-default-button"
                                        disabled={cuttingTech.isDeleted || !canAddCuttingTech}
                                        size="small"
                                        sx={classes.cardHeaderIcon}
                                        onClick={() =>
                                            !cuttingTech.isDefault &&
                                            toggleAttribute({ ...cuttingTech, isDefault: !cuttingTech.isDefault })
                                        }
                                    >
                                        {cuttingTech.isDefault ? (
                                            <Favorite
                                                color="primary"
                                                fontSize="small"
                                            />
                                        ) : (
                                            <FavoriteBorder fontSize="small" />
                                        )}
                                    </IconButton>
                                </span>
                            </TbxTooltip>

                            <TbxTooltip
                                title={
                                    cuttingTech.isDeleted
                                        ? t('Unarchive cutting technology')
                                        : t('Archive cutting technology')
                                }
                                arrow
                            >
                                <span>
                                    <IconButton
                                        aria-label="archive"
                                        data-testid="archive-button"
                                        disabled={!canAddCuttingTech}
                                        size="small"
                                        sx={classes.cardHeaderIcon}
                                        onClick={() =>
                                            toggleAttribute({
                                                ...cuttingTech,
                                                isDeleted: !cuttingTech.isDeleted,
                                                isDefault: false,
                                            })
                                        }
                                    >
                                        {cuttingTech.isDeleted ? (
                                            <Unarchive fontSize="small" />
                                        ) : (
                                            <Archive fontSize="small" />
                                        )}
                                    </IconButton>
                                </span>
                            </TbxTooltip>

                            <TbxTooltip
                                title={t('Edit cutting technology')}
                                arrow
                            >
                                <span>
                                    <IconButton
                                        aria-label="edit"
                                        data-testid="edit-button"
                                        disabled={
                                            cuttingTech.isDeleted || (!cuttingTech.isDefault && !canAddCuttingTech)
                                        }
                                        size="small"
                                        sx={classes.cardHeaderIcon}
                                        onClick={onEdit(cuttingTech.cuttingTechnologyId)}
                                    >
                                        <Create fontSize="small" />
                                    </IconButton>
                                </span>
                            </TbxTooltip>
                        </>
                    }
                    sx={classes.cardHeader}
                    title={`${t(cuttingTech.type)}: ${cuttingTech.name}`}
                    titleTypographyProps={{
                        variant: 'h6',
                    }}
                />
                <CardContent sx={classes.cardContent}>
                    {isRotary ? (
                        <Box sx={classes.infoGroup}>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-profile-diameter"
                                    variant="body2"
                                >
                                    {t('Maximum profile diameter')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.maximumProfileDiameter) ?? t('None')}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-material-length"
                                    variant="body2"
                                >
                                    {t('Maximum material length')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    data-testid="maximum-material-length-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.maximumMaterialLength) ?? t('None')}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="chuck-allowance"
                                    variant="body2"
                                >
                                    {t('Chuck allowance')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    data-testid="chuck-allowance-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.chuckAllowance) ?? t('None')}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={classes.infoGroup}>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-sheet-length"
                                    variant="body2"
                                >
                                    {t('Maximum sheet width')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    data-testid="maximum-sheet-length-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.maximumSheetWidth) ?? t('None')}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-sheet-length"
                                    variant="body2"
                                >
                                    {t('Maximum sheet length')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    data-testid="maximum-sheet-length-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.maximumSheetLength) ?? t('None')}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box sx={classes.infoGroup}>
                        <Box sx={classes.infoRow}>
                            <Typography
                                data-testid="setup-time-secs"
                                variant="body2"
                            >
                                {t('Setup time (s)')}
                            </Typography>
                            <Typography
                                data-testid="setup-time-secs-value"
                                sx={classes.infoStrong}
                                variant="body2"
                            >
                                {n(cuttingTech.setupTime)}
                            </Typography>
                        </Box>
                        <Box sx={classes.infoRow}>
                            <Typography
                                data-testid="setup-mode"
                                variant="body2"
                            >
                                {t('Setup mode')}
                            </Typography>
                            <Typography
                                data-testid="setup-mode-value"
                                sx={classes.infoStrong}
                                variant="body2"
                            >
                                {t(modeOptions.find((option) => option.value === cuttingTech.setupMode).name)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={classes.infoGroup}>
                        <Box sx={classes.infoRow}>
                            <Typography
                                data-testid="sheet-change-time"
                                variant="body2"
                            >
                                {t(isRotary ? 'Loading time' : 'Sheet change time (s)')}
                            </Typography>
                            <Typography
                                data-testid="sheet-change-time-value"
                                sx={classes.infoStrong}
                                variant="body2"
                            >
                                {n(cuttingTech.sheetChangeTime)}
                            </Typography>
                        </Box>
                        <Box sx={classes.infoRow}>
                            <Typography
                                data-testid="sheet-change-mode"
                                variant="body2"
                            >
                                {t(isRotary ? 'Loading mode' : 'Sheet change mode')}
                            </Typography>
                            <Typography
                                data-testid="sheet-change-mode-value"
                                sx={classes.infoStrong}
                                variant="body2"
                            >
                                {t(modeOptions.find((option) => option.value === cuttingTech.sheetChangeMode).name)}
                            </Typography>
                        </Box>
                    </Box>

                    {showAccelerationSettings ? (
                        <Box sx={classes.infoGroup}>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="sheet-change-time"
                                    variant="body2"
                                >
                                    {t('Linear acceleration')} {useImperialUnits ? '(in/s2)' : '(mm/s2)'}
                                </Typography>
                                <Typography
                                    data-testid="sheet-change-time-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.linearAcceleration)}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="sheet-change-mode"
                                    variant="body2"
                                >
                                    {t('Radial acceleration')} {useImperialUnits ? '(in/s2)' : '(mm/s2)'}
                                </Typography>
                                <Typography
                                    data-testid="sheet-change-mode-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.radialAcceleration)}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="sheet-change-mode"
                                    variant="body2"
                                >
                                    {t('Rapid acceleration')} {useImperialUnits ? '(in/s2)' : '(mm/s2)'}
                                </Typography>
                                <Typography
                                    data-testid="sheet-change-mode-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.rapidAcceleration)}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="sheet-change-mode"
                                    variant="body2"
                                >
                                    {t('Rapid traverse rate')} {useImperialUnits ? '(in/min)' : '(mm/min)'}
                                </Typography>
                                <Typography
                                    data-testid="sheet-change-mode-value"
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(cuttingTech.rapidTraverseRate)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}
                </CardContent>
            </Card>
        </TbxTooltip>
    )
}

TbxTechCard.propTypes = {
    canAddCuttingTech: PropTypes.bool,
    cuttingTech: PropTypes.object,
    onEdit: PropTypes.func,
    onToggleAttribute: PropTypes.func,
}

export default TbxTechCard
