import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { useNumberFormatter } from '@/common/hooks'

const RenderDimensions = ({ quoteItemId, selectedPaths }) => {
    const params = useParams()
    const { quoteId } = params
    const organisationId = useSelector(selectOrganisationId)
    const locale = useSelector(selectLocale)

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const drawingCleanerDecimalPlaces = (quoteItem) => (quoteItem?.isImperial ? 4 : 2)

    const { n } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: drawingCleanerDecimalPlaces(quoteItem),
    })

    const pathDimensions = Object.values(selectedPaths).length === 1 ? Object.values(selectedPaths)[0] : null

    if (!quoteItem || !selectedPaths) return null
    return (
        <Typography variant="body1">
            {pathDimensions
                ? `${n(pathDimensions.width)} x ${n(pathDimensions.height)}`
                : quoteItem?.profile
                  ? quoteItem.profile.toLowerCase() === 'circle'
                      ? `${n(quoteItem.diameter)} x ${n(quoteItem.rotaryPartLength)}`
                      : `${n(quoteItem.rotaryProfileWidth)} x ${n(quoteItem.rotaryProfileLength)} x ${n(
                            quoteItem.rotaryPartLength
                        )}`
                  : `${n(quoteItem.displayBoundBoxWidth)} x ${n(quoteItem.displayBoundBoxHeight)}`}
        </Typography>
    )
}

RenderDimensions.propTypes = {
    quoteItemId: PropTypes.string,
    selectedPaths: PropTypes.object,
}

export default RenderDimensions
