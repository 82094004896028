import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

const classes = {
    root: {
        width: '100%',
        height: 'calc(100% - 32px)', // TODO: Move height calculation to layout depending on number of toolbars
        boxSizing: 'border-box',
        display: 'flex',
        padding: 4,
        marginBottom: 4,
    },
    contentContainer: {
        width: 520,
    },
    title: {
        marginBottom: 2,
    },
    homeButton: {
        marginTop: 4,
        marginBottom: 1,
    },
}

const Unauthorized = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Box sx={classes.root}>
            <Box sx={classes.contentContainer}>
                <Typography
                    sx={classes.title}
                    variant="h4"
                >
                    {t('Unauthorised')}
                </Typography>
                <Typography variant="body1">{t('You are unauthorised to view this page')}</Typography>
                <Button
                    color="primary"
                    sx={classes.homeButton}
                    variant="contained"
                    onClick={() => navigate('/')}
                >
                    {t('Back to Dashboard')}
                </Button>
            </Box>
        </Box>
    )
}

export default Unauthorized
