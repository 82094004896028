import { useCallback, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import clm from 'country-locale-map'
import cc from 'currency-codes'
import { jwtDecode } from 'jwt-decode'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import { useDebounce } from 'use-debounce'

import { useGetXeroProfileMutation } from '@/app/services/integrations'
import { useCreateOrganisationMutation } from '@/app/services/user'
import { selectCurrentUser, setCurrentUser, setUserRole } from '@/app/slices/appSlice'
import { setOrganisation } from '@/app/slices/organisationSlice'
import { tokenRequest } from '@/authConfig'
import LanguageSelect from '@/common/components/LanguageSelect/LanguageSelect'
import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'
import { useToolBoxTreatments } from '@/common/hooks'
import { RightArrowIcon } from '@/common/icons'
import PromotionalCodeService from '@/common/services/PromotionalCodeService'
import {
    COUNTRY_LANGUAGE_MAP,
    CuttingTechnologyPowers,
    getCookieByName,
    getSampleFormattedCurrencyString,
    Paths,
} from '@/common/utils'

const classes = {
    paper: {
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: [0, '8px'],
        marginBlock: [0, 4],
        padding: [3, 4],
    },

    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 2,
        gap: 2,
    },
}

const UserWelcome = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { isReady, showVoucherCode } = useToolBoxTreatments()
    const { instance: msalInstance } = useMsal()

    const currentUser = useSelector(selectCurrentUser)
    const [createOrganisation] = useCreateOrganisationMutation()
    const [getXeroProfile] = useGetXeroProfileMutation()

    const countryList = useMemo(() => {
        return clm.getAllCountries()
    }, [])

    const params = queryString.parse(location.search)

    const isResellerEnvSet = !!import.meta.env.VITE_RESELLER

    const [isValidPromoCode, setIsValidPromoCode] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const { control, formState, getValues, handleSubmit, setValue, trigger, watch } = useForm({
        mode: 'all',
        defaultValues: {
            contactFirstName: currentUser?.firstName || '',
            contactLastName: currentUser?.lastName || '',
            organisationName: '',
            organisationCuttingTechnologyPower: '',
            organisationCountry: '',
            organisationUnits: '',
            organisationLanguage: '',
            organisationCurrencyCode: '',
            organisationLocale: '',
            organisationVoucherCode: '',
        },
    })

    const units = [
        {
            name: t('millimetres, metres, kilograms'),
            value: 'metric',
        },
        {
            name: t('inches, feet, pounds'),
            value: 'imperial',
        },
    ]

    // HubSpot UTK - User token
    const getHutkValue = () => {
        if (params.hutk) {
            return params.hutk
        }

        return getCookieByName('hubspotutk')
    }

    const organisationCountry = watch('organisationCountry')
    const [debouncedOrganisationCountry] = useDebounce(organisationCountry, 500)

    const organisationVoucherCode = watch('organisationVoucherCode')
    const [debouncedOrganisationVoucherCode] = useDebounce(organisationVoucherCode, 800)

    const validateVoucherCode = useCallback(async () => {
        const voucherCode = debouncedOrganisationVoucherCode

        if (voucherCode) {
            try {
                await PromotionalCodeService.validatePromotionalCode(voucherCode)
                setIsValidPromoCode(true)
            } catch (_error) {
                console.error('Invalid voucher code')
                setIsValidPromoCode(false)
            }
        } else {
            setIsValidPromoCode(true)
        }
    }, [debouncedOrganisationVoucherCode])

    const handleNextButtonClick = async (data) => {
        if (formState.isValid) {
            setIsLoading(true)
            dispatch(setUserRole('owner'))

            const hutkValue = getHutkValue()
            const updatedUser = {
                ...currentUser,
                firstName: data.contactFirstName,
                lastName: data.contactLastName,
            }

            // FirstPromoter cookies
            const refId = getCookieByName('_fprom_ref')
            const tId = getCookieByName('_fprom_tid')

            try {
                const options = {
                    name: data.organisationName,
                    language: data.organisationLanguage,
                    locale: data.organisationLocale,
                    useImperialUnits: data.organisationUnits.toLowerCase() === 'imperial',
                    currencyCode: data.organisationCurrencyCode,
                    userFirstName: updatedUser.firstName,
                    userLastName: updatedUser.lastName,
                    country: data.organisationCountry.name,
                    isLegacyMaterials: false,
                    promotionalCode: isValidPromoCode ? data.organisationVoucherCode : undefined,
                    cuttingTechnologyPower: data.organisationCuttingTechnologyPower,
                    hubSpotUtk: isResellerEnvSet ? null : hutkValue,
                    firstPromoterReferralId: refId,
                    firstPromoterVisitorTrackingId: tId,
                }

                const organisationResponse = await createOrganisation({ userId: updatedUser.userId, options }).unwrap()

                dispatch(setUserRole('owner'))
                dispatch(setOrganisation(organisationResponse))
                dispatch(setCurrentUser(updatedUser))

                if (!isResellerEnvSet) {
                    try {
                        // Google tag manager send event
                        const userEmailDomain = currentUser.emailAddress?.split('@')?.[1]

                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'organisation_created',
                                userEmailDomain,
                            },
                        })
                    } finally {
                        // do nothing
                    }
                }

                enqueueSnackbar(t('Organisation created'), {
                    variant: 'success',
                })
                setIsLoading(false)
                navigate(Paths.DASHBOARD_PATHNAME)
            } catch (error) {
                setIsLoading(false)
                enqueueSnackbar(t('An error occurred') + '. ' + t('Please try again') + '.', {
                    variant: 'error',
                })
            }
        }
    }

    const getXeroInfo = async () => {
        const token = await msalInstance.acquireTokenSilent(tokenRequest)
        const decodedToken = jwtDecode(token.accessToken)
        if (decodedToken.idp === 'https://identity.xero.com') {
            const profile = await getXeroProfile({ accessToken: decodedToken.idp_access_token })
            setValue('organisationName', profile.data.name)
            setValue(
                'organisationCountry',
                countryList.find((c) => c.alpha2 === profile.data.countryCode)
            )
            setValue('organisationCurrencyCode', profile.data.currencyCode)
        }
    }

    useEffect(() => {
        if (debouncedOrganisationCountry) {
            const country = debouncedOrganisationCountry
            if (country) {
                const currency = country?.currency || 'USD'
                const language = COUNTRY_LANGUAGE_MAP[country?.alpha2]?.locale || 'en-US'
                const unit = country?.alpha2 === 'US' ? 'imperial' : 'metric'
                const locale = country?.default_locale?.replace('_', '-') || 'en-US'

                setValue('organisationCurrencyCode', currency)
                setValue('organisationLanguage', language)
                setValue('organisationLocale', locale)
                setValue('organisationUnits', unit)

                trigger()
            }
        }
    }, [debouncedOrganisationCountry])

    useEffect(() => {
        if (debouncedOrganisationVoucherCode) {
            validateVoucherCode()
        } else {
            setIsValidPromoCode(true)
        }
    }, [debouncedOrganisationVoucherCode])

    useEffect(() => {
        getXeroInfo()
    }, [])

    return (
        <Paper
            sx={classes.paper}
            variant="outlined"
        >
            <TbxToolbar>
                <TbxToolbarTitle>{t('Welcome / Create organisation')}</TbxToolbarTitle>
            </TbxToolbar>

            <Typography sx={{ typography: { xs: 'h6', sm: 'h5' } }}>
                {t('Set up your account details and defaults')}
            </Typography>
            <Box sx={classes.inputContainer}>
                <Controller
                    control={control}
                    name="contactFirstName"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            inputProps={{
                                sx: classes.input,
                                'data-testid': 'user-welcome-first-name',
                            }}
                            label={t('First name')}
                            sx={classes.input}
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: t('First name is required') }}
                />

                <Controller
                    control={control}
                    name="contactLastName"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            inputProps={{
                                sx: classes.input,
                                'data-testid': 'user-welcome-last-name',
                            }}
                            label={t('Last name')}
                            sx={classes.input}
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: t('Last name is required') }}
                />

                <Controller
                    control={control}
                    name="organisationName"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            inputProps={{
                                sx: classes.input,
                                'data-testid': 'user-welcome-organisation-name',
                            }}
                            label={t('Organisation name')}
                            sx={classes.input}
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: t('Organisation name is required') }}
                />

                <Controller
                    control={control}
                    name="organisationCuttingTechnologyPower"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <Autocomplete
                            className={classes.autoComplete}
                            disableClearable={true}
                            getOptionLabel={(option) => option.toString()}
                            options={CuttingTechnologyPowers}
                            renderInput={(params) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    placeholder={t('4kW')}
                                    {...params}
                                    InputLabelProps={{
                                        sx: classes.inputLabel,
                                        'data-testid': 'user-welcome-cutting-technology-power-label',
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        'data-testid': 'user-welcome-cutting-technology-power',
                                    }}
                                    label={t('Cutting technology power (kW)')}
                                    fullWidth
                                />
                            )}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e, newValue) => onChange(newValue)}
                        />
                    )}
                    rules={{ required: t('Cutting technology power is required') }}
                />

                <Controller
                    control={control}
                    name="organisationCountry"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <Autocomplete
                            className={classes.autoComplete}
                            disableClearable={true}
                            getOptionLabel={(option) => option.name || ''}
                            options={countryList}
                            renderInput={(params) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    {...params}
                                    InputLabelProps={{
                                        sx: classes.inputLabel,
                                        'data-testid': 'user-welcome-country-label',
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        'data-testid': 'user-welcome-country-input',
                                    }}
                                    label={t('Country')}
                                    fullWidth
                                />
                            )}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e, newValue) => {
                                onChange(newValue)
                            }}
                        />
                    )}
                    rules={{ required: t('Country is required') }}
                />

                <Controller
                    control={control}
                    name="organisationUnits"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <FormControl fullWidth>
                            <InputLabel
                                error={fieldState.invalid}
                                id="unit-select-label"
                            >
                                {t('System of measurement')}
                            </InputLabel>
                            <Select
                                id="unit-select"
                                label={t('System of measurement')}
                                labelId="unit-select-label"
                                placeholder={t('Select units')}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.value)}
                            >
                                {units.map((unit) => (
                                    <MenuItem
                                        key={unit.value}
                                        value={unit.value}
                                    >
                                        {unit.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={fieldState.invalid}>
                                {t(fieldState.error?.message || '')}
                            </FormHelperText>
                        </FormControl>
                    )}
                    rules={{ required: t('Units is required') }}
                />

                <Controller
                    control={control}
                    name="organisationLanguage"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <LanguageSelect
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            id="language-select"
                            label={t('Language')}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: t('Language is required') }}
                />

                <Controller
                    control={control}
                    name="organisationCurrencyCode"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <Autocomplete
                            className={classes.autoComplete}
                            disableClearable={true}
                            options={cc.codes()}
                            renderInput={(params) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    {...params}
                                    InputLabelProps={{
                                        sx: classes.inputLabel,
                                        'data-testid': 'user-welcome-currency-label',
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        'data-testid': 'user-welcome-currency-input',
                                    }}
                                    label={t('Currency')}
                                    fullWidth
                                />
                            )}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e, newValue) => {
                                onChange(newValue)
                            }}
                        />
                    )}
                    rules={{ required: t('Currency is required') }}
                />

                <TextField
                    InputLabelProps={{
                        'data-testid': 'user-welcome-currency-preview-label',
                    }}
                    InputProps={{
                        readOnly: true,
                    }}
                    inputProps={{
                        sx: classes.selectInput,
                        'data-testid': 'user-welcome-currency-preview',
                    }}
                    label={t('Currency preview')}
                    value={
                        !watch('organisationLocale') || !watch('organisationCurrencyCode')
                            ? ''
                            : getSampleFormattedCurrencyString(
                                  getValues('organisationLocale'),
                                  getValues('organisationCurrencyCode')
                              )
                    }
                    fullWidth
                />

                {showVoucherCode && !isResellerEnvSet ? (
                    <Controller
                        control={control}
                        name="organisationVoucherCode"
                        render={({ field: { onBlur, onChange, value } }) => (
                            <TextField
                                error={debouncedOrganisationVoucherCode ? !isValidPromoCode : null}
                                helperText={
                                    debouncedOrganisationVoucherCode && !isValidPromoCode
                                        ? t('Invalid promotion or voucher code')
                                        : null
                                }
                                inputProps={{
                                    sx: classes.input,
                                    'data-testid': 'user-welcome-voucher-code',
                                }}
                                label={t('Voucher code')}
                                sx={classes.input}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />
                ) : null}
            </Box>

            <Box
                mt={4}
                textAlign="right"
            >
                <Button
                    color="primary"
                    disabled={!formState.isValid || isLoading || !isReady || !isValidPromoCode}
                    endIcon={isLoading || !isReady ? <CircularProgress size={18} /> : <RightArrowIcon />}
                    variant="contained"
                    onClick={handleSubmit(handleNextButtonClick)}
                >
                    {isLoading ? t('Creating organisation') : t('Create Organisation')}
                </Button>
            </Box>
        </Paper>
    )
}

export default UserWelcome
