import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { LinearProgress, Link } from '@mui/material'
import Box from '@mui/material/Box'
import { green } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'

import { useGetSharedOrganisationQuery, useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useGetSharedQuoteQuery } from '@/app/services/quotes'
import { selectCurrencyCode, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import PublicAppBar from '@/common/components/MainAppBar/PublicAppBar'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'
import { formatCurrency, formatNumber } from '@/common/helpers/formatUtilities'
import { useNumberFormatter } from '@/common/hooks'
import { theme } from '@/common/themes/LightTheme'
import { loadWebStoreFavicon, QuotePaymentStatus } from '@/common/utils'

const classes = {
    paper: {
        borderRadius: '8px',
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 160px)',
        },
    },
    successMessageContainer: {
        gap: 3,
        maxWidth: '500px',
        textAlign: 'center',
        marginInline: 'auto',
        height: '100%',
        padding: 4,
    },

    summaryTitle: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },

    orderSummary: {
        padding: 3,
        gap: 3,
        overflow: 'hidden',
        height: '100%',
    },

    orderInfo: {
        gap: 1.5,
    },
    partsListContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    partsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    partInfo: {
        gap: 0.75,
    },
    partDetailsInfo: {
        gap: 1.5,
    },
    totalInfo: {
        gap: 1.5,
    },
    smallText: {
        fontSize: '0.75rem',
    },
    strongText: {
        fontWeight: 700,
    },

    loadingMessage: {
        fontFamily: 'fontFamily',
        fontSize: 'body2.fontSize',
        maxWidth: 500,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        margin: '48px auto',
    },
}

const PaymentSuccess = () => {
    const { i18n, t } = useTranslation()

    const { quoteId } = useParams()

    const useImperialUnits = useSelector(selectUseImperialUnits)
    const currencyCode = useSelector(selectCurrencyCode)

    const {
        data: quote,
        isLoading,
        isSuccess,
    } = useGetSharedQuoteQuery({
        quoteId,
        params: { includeItems: true },
    })

    const { data: organisation, isLoading: isLoadingOrg } = useGetSharedOrganisationQuery(
        { organisationId: quote?.organisationId },
        { skip: !quote?.organisationId }
    )

    const { data: storeSettings } = useGetStoreSettingsQuery(
        { organisationId: quote?.organisationId },
        { skip: !quote?.organisationId }
    )

    document.title = `${storeSettings?.webStoreDisplayName || organisation?.name} - ${t('Payment success')}`
    loadWebStoreFavicon(storeSettings?.webStoreFaviconUri)

    const language = organisation?.language
    const locale = organisation?.locale

    const units = useImperialUnits ? 'in' : 'mm'

    const isQuotePaid =
        quote?.paymentStatus === QuotePaymentStatus.GatewayPaid ||
        quote?.paymentStatus === QuotePaymentStatus.ManualPaid

    const largesPartDimension = `${formatNumber(quote?.largestBoundBoxWidth, locale)} x ${formatNumber(
        quote?.largestBoundBoxHeight,
        locale
    )} ${units}`

    const totalTax = quote?.lineTaxedPrice - (quote?.minimumQuoteChargePrice || 0) - quote?.linePrice

    const quoteTaxRate = quote?.taxRateId ? quote?.taxRate : totalTax / quote?.linePrice

    const decimalPlaces = quote?.taxRateId ? 4 : 2

    const { n: percentageFormatter } = useNumberFormatter({
        locale: locale,
        numberOfDecimalPlaces: decimalPlaces,
        style: 'percent',
    })

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language)
        }
    }, [language])

    return !isLoading && !isLoadingOrg && isSuccess ? (
        isQuotePaid ? (
            <>
                <PublicAppBar
                    organisationLogo={storeSettings?.webStoreLogoUri}
                    organisationName={storeSettings?.webStoreDisplayName || organisation?.name}
                />
                <Grid
                    p={3}
                    rowSpacing={2}
                    spacing={3}
                    container
                >
                    <Grid
                        component="section"
                        lg={7}
                        md={6}
                        xs={12}
                    >
                        <Paper
                            sx={classes.paper}
                            variant="outlined"
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                sx={classes.successMessageContainer}
                            >
                                <CheckCircleOutlineRounded style={{ fontSize: '88px', color: green[600] }} />
                                <Typography variant="h5">{t('Thank you for your payment')}</Typography>
                                <Typography variant="body1">
                                    {t('Hi {{contact}}, a receipt has been emailed to your supplied email address.', {
                                        contact: quote?.customerContact?.name,
                                    })}
                                </Typography>
                                <Typography variant="h6">
                                    {t('Order number: {{quoteName}}', { quoteName: quote?.name })}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid
                        component="aside"
                        lg={5}
                        md={6}
                        xs={12}
                    >
                        <Paper
                            sx={classes.paper}
                            variant="outlined"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={classes.orderSummary}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        sx={classes.summaryTitle}
                                        variant="body1"
                                    >
                                        {t('Order summary')}
                                    </Typography>
                                    <Typography
                                        sx={classes.summaryTitle}
                                        variant="body1"
                                    >
                                        {quote?.name}
                                    </Typography>
                                </Box>

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={classes.orderInfo}
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body2">{t('Net weight')}</Typography>
                                        <Typography
                                            className="price"
                                            variant="body2"
                                        >
                                            {formatNumber(quote?.totalMass, locale)} kg
                                        </Typography>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body2">{t('Largest dimensions')}</Typography>
                                        <Typography
                                            className="price"
                                            variant="body2"
                                        >
                                            {largesPartDimension}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Divider />

                                <TbxShadowScroll
                                    scrollContainerSx={classes.partsList}
                                    wrapperSx={classes.partsListContainer}
                                >
                                    {quote?.quoteItems?.map((item) => (
                                        <Box
                                            alignItems="end"
                                            className="part"
                                            display="flex"
                                            justifyContent="space-between"
                                            key={item?.id}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={classes.partInfo}
                                            >
                                                <Typography
                                                    sx={classes.strongText}
                                                    variant="body1"
                                                >
                                                    {item?.name}
                                                </Typography>

                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    sx={classes.partDetailsInfo}
                                                >
                                                    <Typography
                                                        sx={classes.smallText}
                                                        variant="body2"
                                                    >
                                                        {t('Material')}: {item?.material.materialName}
                                                    </Typography>
                                                    <Typography
                                                        sx={classes.smallText}
                                                        variant="body2"
                                                    >
                                                        {t('Thickness')}: {item?.thickness}
                                                        {units}
                                                    </Typography>
                                                    <Typography
                                                        sx={classes.smallText}
                                                        variant="body2"
                                                    >
                                                        {t('Quantity')}: {item?.quantity}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Typography
                                                sx={classes.strongText}
                                                variant="body1"
                                            >
                                                {formatCurrency(item?.linePrice, currencyCode, locale)}
                                            </Typography>
                                        </Box>
                                    ))}

                                    {quote?.miscellaneousItems?.map((item) => (
                                        <Box
                                            alignItems="end"
                                            className="part"
                                            display="flex"
                                            justifyContent="space-between"
                                            key={item?.id}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={classes.partInfo}
                                            >
                                                <Typography
                                                    sx={classes.strongText}
                                                    variant="body1"
                                                >
                                                    {item?.name}
                                                </Typography>

                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    sx={classes.partDetailsInfo}
                                                >
                                                    <Typography
                                                        sx={classes.smallText}
                                                        variant="body2"
                                                    >
                                                        {t('Thickness')}: {item?.thickness}
                                                        {units}
                                                    </Typography>
                                                    <Typography
                                                        sx={classes.smallText}
                                                        variant="body2"
                                                    >
                                                        {t('Quantity')}: {item?.quantity}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Typography
                                                sx={classes.strongText}
                                                variant="body1"
                                            >
                                                {formatCurrency(item?.linePrice, currencyCode, locale)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </TbxShadowScroll>

                                <Divider />

                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={classes.totalInfo}
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body1">{t('Subtotal')}</Typography>
                                        <Typography
                                            sx={classes.strongText}
                                            variant="body1"
                                        >
                                            {formatCurrency(quote?.linePrice, currencyCode, locale)}
                                        </Typography>
                                    </Box>

                                    {quote?.minimumQuoteChargePrice ? (
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Typography variant="body1">{t('Minimum order charge')}</Typography>
                                            <Typography
                                                sx={classes.strongText}
                                                variant="body1"
                                            >
                                                {formatCurrency(quote?.minimumQuoteChargePrice, currencyCode, locale)}
                                            </Typography>
                                        </Box>
                                    ) : null}

                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body1">
                                            {`${t('Taxes')} (${quoteTaxRate ? percentageFormatter(quoteTaxRate) : '0%'})`}
                                        </Typography>
                                        <Typography
                                            sx={classes.strongText}
                                            variant="body1"
                                        >
                                            {formatCurrency(totalTax, currencyCode, locale)}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="h6">{t('Total')}</Typography>
                                        <Typography
                                            sx={classes.strongText}
                                            variant="h6"
                                        >
                                            {formatCurrency(quote?.lineTaxedPrice, currencyCode, locale)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid
                        component="footer"
                        sx={classes.footer}
                        xs={12}
                    >
                        <Typography
                            textAlign="center"
                            variant="body2"
                        >
                            {t('Powered by') + ' '}
                            <Link
                                color="primary"
                                href={`https://tempustools.com?org_id=${quote?.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=payment-thank-you`}
                                target="_blank"
                                underline="always"
                            >
                                Tempus Tools
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </>
        ) : (
            <Box sx={classes.loadingMessage}>
                <Typography variant="body1">{t('Your payment is still processing.')}</Typography>
                <Typography variant="body1">{t('Please wait a few minutes and refresh the page.')}</Typography>
            </Box>
        )
    ) : (
        <Box sx={classes.loadingMessage}>
            {t('We are loading your data. Please, wait.')}
            <LinearProgress style={{ marginTop: 50 }} />
        </Box>
    )
}

export default memo(PaymentSuccess)
