import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateContactMutation, useUpdateContactMutation } from '@/app/services/contacts'
import { selectSelectedCustomer, selectSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: 'text.primary',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const AddContactForm = ({ contact, onCancel, onCreate }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const selectedCustomer = useSelector(selectSelectedCustomer)
    const selectedCustomerId = useSelector(selectSelectedCustomerId)

    const [createContact] = useCreateContactMutation()
    const [updateContact] = useUpdateContactMutation()

    const [isSaving, setIsSaving] = useState(false)

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            name: contact?.name || '',
            email: contact?.email || '',
            phone: contact?.phone || '',
        },
    })

    const handleAddContactButtonClick = async (data, _) => {
        try {
            setIsSaving(true)
            if (contact) {
                await updateContact({
                    organisationId,
                    customerId: selectedCustomer?.customerId,
                    contact: {
                        ...contact,
                        ...data,
                    },
                }).unwrap()

                enqueueSnackbar(t('Contact updated successfully'), {
                    variant: 'success',
                })

                onCancel()
            } else {
                const newContact = await createContact({
                    organisationId,
                    customerId: selectedCustomer?.customerId || selectedCustomerId,
                    contact: data,
                }).unwrap()

                enqueueSnackbar(t('Contact added successfully'), {
                    variant: 'success',
                })
                onCreate(newContact)
            }
        } catch (error) {
            enqueueSnackbar(t('An error occurred while saving the contact'), {
                variant: 'error',
            })
            onCancel()
        } finally {
            setIsSaving(false)
        }
    }

    const getTitle = () => {
        return t(contact ? 'Edit Contact' : 'Add Contact')
    }

    const getSaveButtonText = () => {
        return t(contact ? 'Save' : 'Add')
    }

    return (
        <>
            <Typography sx={classes.title}>{getTitle()}</Typography>
            <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Full name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: true }}
            />
            <Controller
                control={control}
                name="email"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('E-mail')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{
                    required: true,
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('Please enter a correct email address'),
                    },
                }}
            />
            <Controller
                control={control}
                name="phone"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Phone number')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddContactButtonClick)}
                >
                    {isSaving ? t('Saving...') : getSaveButtonText()}
                </Button>
            </Box>
        </>
    )
}

AddContactForm.propTypes = {
    contact: PropTypes.object,
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
}

export default AddContactForm
