import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, OrganisationLogo } from '@/common/components'
import { getAddressDetails } from '@/common/helpers/addressUtilities'
import { useNumberFormatter } from '@/common/hooks'

import WorkOrderMiscItemRow from '../../components/WorkOrderMiscItemRow'
import WorkOrderPartRow from '../../components/WorkOrderQuoteItemRow'

const classes = {
    documentContainer: (width) => ({
        minWidth: 1024,
        width: width,
        margin: '24px auto',
        maxWidth: '98%',
    }),
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        py: 3,
        px: 3,
        my: 3,
    },
    noPageBreak: {
        breakInside: 'auto',
    },
    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
}

const MIN_COLSPAN = 3
const MIN_CONTAINER_WIDTH = 578
const PROC_CELL_WIDTH = 160
const NO_MATERIAL_KEY = 'NO_MATERIAL_KEY'

const WorkOrder = ({ customer, customerContact, issueSeverityDictionary, organisation, quote }) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)

    const [containerWidth, setContainerWidth] = useState(1024)

    const userImperialUnits = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial'

    const units = userImperialUnits ? 'in' : 'mm'

    const { n } = useNumberFormatter({
        locale: organisation.locale,
        numberOfDecimalPlaces: userImperialUnits ? 4 : 2,
    })

    const quoteItemsStringified = JSON.stringify(quote.quoteItems)

    const sortQuoteItems = (quoteItems) => {
        return quoteItems.sort((a, b) => a.index - b.index)
    }

    const sortMiscItems = (miscItems) => {
        return miscItems.toSorted((a, b) => a.index - b.index)
    }

    const groupedQuoteItems = useMemo(
        () =>
            quote.quoteItems.reduce((acc, item) => {
                const thicknessLabel = item.thickness ? ` - ${n(item.thickness)}${units}` : ''
                const key = item.material ? `${item.material?.materialName}${thicknessLabel}` : NO_MATERIAL_KEY
                acc[key] = acc[key] || []
                acc[key].push(item)
                return acc
            }, {}),
        [quoteItemsStringified, t]
    )

    const groupedSecondaryProcesses = useMemo(
        () =>
            Object.keys(groupedQuoteItems).reduce((acc, key) => {
                const quoteItems = groupedQuoteItems[key]
                acc[key] = acc[key] || new Set()

                if (quoteItems.length > 0) {
                    quoteItems.forEach((qi) => {
                        if (qi.numberOfFolds) {
                            acc[key].add('Folding')
                        }

                        if (qi.secondaryProcesses?.length > 0) {
                            qi.secondaryProcesses.forEach((sp) => {
                                acc[key].add(sp.secondaryProcess.name)
                            })
                        }
                    })
                }
                return acc
            }, {}),
        [quoteItemsStringified]
    )

    useEffect(() => {
        const MAX_SEC_PROC_LENGTH = Math.max(
            ...Object.keys(groupedSecondaryProcesses).map((key) => groupedSecondaryProcesses[key].size)
        )
        setContainerWidth(MIN_CONTAINER_WIDTH + MAX_SEC_PROC_LENGTH * PROC_CELL_WIDTH)
    }, [groupedSecondaryProcesses])

    return quote && quote.organisationId && quote.quoteItems ? (
        <Box sx={classes.documentContainer(containerWidth)}>
            <Box
                alignItems="flex-end"
                display="flex"
                gap={2}
                id="QuoteHeader"
                justifyContent="space-between"
                sx={classes.card}
            >
                {customer ? (
                    <Box
                        className="quote-info"
                        display="flex"
                        flex={2}
                        flexDirection="column"
                        gap={2}
                    >
                        <Typography
                            textTransform="uppercase"
                            variant="h4"
                        >
                            {`${t('Work Order')}: `}
                            <Typography
                                component="span"
                                fontWeight={700}
                                textTransform="uppercase"
                                variant="h4"
                            >
                                {quote.workOrderNumberText}
                            </Typography>
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1.5}
                        >
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="strong2"
                                >
                                    {t('Last modified by:')}
                                </Typography>
                                <Typography variant="body2">{`${quote.lastModifiedByUserName}`}</Typography>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                            >
                                <Box
                                    display="flex"
                                    gap={1}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="strong2"
                                    >
                                        {t('Customer:')}
                                    </Typography>
                                    <Typography variant="strong2">{customer ? customer.companyName : ''}</Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={0.5}
                                >
                                    <Typography variant="body2">
                                        {[customerContact?.name, customerContact?.email].filter(Boolean).join(' | ')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                className="quote-shipping-info"
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="strong1"
                                >
                                    Shipping
                                </Typography>

                                <Typography variant="body2">
                                    {quote.deliveryOption === 0
                                        ? `${t('Pickup from ')} ${
                                              getAddressDetails(organisation?.pickupAddress) || t('N/A')
                                          }`
                                        : `${t('Delivery to ')} ${
                                              getAddressDetails(quote?.deliveryAddress) || t('N/A')
                                          }`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : null}

                <Box
                    alignItems="flex-end"
                    alignSelf="stretch"
                    className="quote-logo-info"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={2}
                    justifyContent="space-between"
                >
                    <OrganisationLogo
                        organisationId={quote.organisationId}
                        organisationLogoUri={organisation.logoUri}
                        wrapperHeight={100}
                        wrapperWidth="auto"
                    />
                    <Box
                        alignItems="flex-end"
                        className="quote-details-info"
                        display="flex"
                        flexDirection="column"
                        gap={0.5}
                    >
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('Quote number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.name}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('PO number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.poNumber}
                            </Typography>
                        </Box>

                        {quote.orderedDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Ordered date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="body2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.orderedDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}

                        {quote.requiredDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Required shipping date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.requiredDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                height="160px"
                id="orderNotes"
                sx={classes.card}
            >
                <Typography variant="h6">{t('Order notes')}</Typography>
                {quote.customerNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Customer notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.customerNotes}
                        </Typography>
                    </Box>
                ) : null}
                {quote.vendorNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Vendor notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.vendorNotes}
                        </Typography>
                    </Box>
                ) : null}
                {quote.privateNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Private notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.privateNotes}
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            {groupedQuoteItems
                ? Object.keys(groupedQuoteItems)
                      .sort(
                          // sort material groups in lexical order with NO_MATERIAL_KEY set to always be last
                          (a, b) =>
                              b === NO_MATERIAL_KEY
                                  ? -1
                                  : a === NO_MATERIAL_KEY
                                    ? 1
                                    : (a?.localeCompare(b, undefined, { sensitivity: 'base' }) ?? -1)
                      )
                      .map((key) => {
                          const quoteItems = groupedQuoteItems[key]
                          const uniqueSecondaryProcesses = Array.from(groupedSecondaryProcesses[key]).filter(
                              (sp) => sp !== 'Folding'
                          )
                          const hasFolding = groupedSecondaryProcesses[key].has('Folding')

                          const colspan = MIN_COLSPAN + groupedSecondaryProcesses[key].size

                          return (
                              <Box
                                  id={`quote-items-${key.toLowerCase().replace(/ /g, '-')}`}
                                  key={key}
                                  sx={[classes.card, classes.noPageBreak]}
                              >
                                  <TableContainer>
                                      <Table
                                          aria-label="Material Parts Table"
                                          sx={{
                                              minWidth: 1,
                                              tableLayout: 'fixed',
                                              borderCollapse: 'separate',
                                          }}
                                      >
                                          <colgroup>
                                              <col
                                                  key="title"
                                                  style={{ width: '280px' }}
                                              />
                                              <col
                                                  key="qty"
                                                  style={{ width: '40px' }}
                                              />
                                              <col
                                                  key="cutting"
                                                  style={{ width: PROC_CELL_WIDTH }}
                                              />

                                              {hasFolding ? (
                                                  <col
                                                      key="folding"
                                                      style={{ width: PROC_CELL_WIDTH }}
                                                  />
                                              ) : null}

                                              {uniqueSecondaryProcesses.map((sp) => (
                                                  <col
                                                      key={sp}
                                                      style={{ width: PROC_CELL_WIDTH }}
                                                  />
                                              ))}
                                          </colgroup>
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell
                                                      align="left"
                                                      colSpan={colspan}
                                                  >
                                                      <Typography variant="strong1">
                                                          {key === NO_MATERIAL_KEY ? t('No Material') : key}
                                                      </Typography>
                                                  </TableCell>
                                              </TableRow>
                                              <TableRow sx={{ verticalAlign: 'bottom' }}>
                                                  <TableCell
                                                      align="left"
                                                      key="part-details"
                                                  >
                                                      {t('Part details')}
                                                  </TableCell>
                                                  <TableCell
                                                      align="right"
                                                      key="qty"
                                                  >
                                                      {t('Qty')}
                                                  </TableCell>
                                                  <TableCell
                                                      align="left"
                                                      key="cutting"
                                                  >
                                                      {t('Cutting')}
                                                  </TableCell>
                                                  {hasFolding ? (
                                                      <TableCell
                                                          align="left"
                                                          key="folding"
                                                      >
                                                          {t('Folding')}
                                                      </TableCell>
                                                  ) : null}

                                                  {uniqueSecondaryProcesses.map((sp) => (
                                                      <TableCell
                                                          align="left"
                                                          key={sp}
                                                      >
                                                          {t(sp)}
                                                      </TableCell>
                                                  ))}
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {sortQuoteItems(quoteItems).map((part) => {
                                                  return (
                                                      <WorkOrderPartRow
                                                          colspan={colspan}
                                                          hasFolding={hasFolding}
                                                          key={`part-${part.id}`}
                                                          organisation={organisation}
                                                          part={part}
                                                          uniqueSecondaryProcesses={uniqueSecondaryProcesses}
                                                      />
                                                  )
                                              })}
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                              </Box>
                          )
                      })
                : null}

            {/* Miscellaneous Items Table */}
            {quote.miscellaneousItems?.length > 0 ? (
                <Box
                    id="quote-misc-items"
                    sx={[classes.card, classes.noPageBreak]}
                >
                    <TableContainer>
                        <Table
                            aria-label="Miscellaneous Items Table"
                            sx={{
                                minWidth: 1,
                                tableLayout: 'fixed',
                                borderCollapse: 'separate',
                            }}
                        >
                            <colgroup>
                                <col
                                    key="title"
                                    style={{ width: '280px' }}
                                />
                                <col
                                    key="dimensions"
                                    style={{ width: PROC_CELL_WIDTH }}
                                />
                                <col
                                    key="weight"
                                    style={{ width: PROC_CELL_WIDTH }}
                                />
                                <col
                                    key="time"
                                    style={{ width: PROC_CELL_WIDTH }}
                                />
                                <col
                                    key="qty"
                                    style={{ width: '80px' }}
                                />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        colSpan={5}
                                    >
                                        <Typography variant="strong1">Miscellaneous Items</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ verticalAlign: 'bottom' }}>
                                    <TableCell
                                        align="left"
                                        key="part-details"
                                    >
                                        {t('Name')}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        key="dimensions"
                                    >
                                        {t('Dimensions')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        key="weight"
                                    >
                                        {t('Weight')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        key="time"
                                    >
                                        {t('Time')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        key="qty"
                                    >
                                        {t('Qty')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortMiscItems(quote.miscellaneousItems).map((item) => {
                                    return (
                                        <WorkOrderMiscItemRow
                                            colspan={5}
                                            item={item}
                                            key={`item-${item.id}`}
                                            organisation={organisation}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : null}

            {!import.meta.env.VITE_RESELLER ? (
                <Box
                    component="footer"
                    sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                    textAlign="center"
                >
                    <Typography variant="small">
                        {t('Powered by')}{' '}
                        <Link
                            color="primary"
                            href={`https://tempustools.com?org_id=${quote.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=document-branding&utm_content=work-order`}
                            target="_blank"
                            underline="always"
                        >
                            Tempus Tools
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    ) : null
}

WorkOrder.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    quote: PropTypes.object,
}

export default WorkOrder
