import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SaveAlt } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { useLazyGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { selectIsOnFreePlan, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import AlertDialog from '@/common/components/AlertDialog/AlertDialog'
import { useToolBoxTreatments } from '@/common/hooks'
import QuoteService from '@/common/services/QuoteService'
import { browserFileDownload, Paths, PUBLIC_DOCUMENTS, QuoteDownloadDocumentsOptions } from '@/common/utils'

const classes = {
    icon: {
        color: 'text.primary',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
}

const DownloadDocuments = ({
    autoExport = false,
    color = 'primary',
    disableElevation = false,
    disabled = false,
    quote,
    sx,
    useIconButton = false,
    variant = 'contained',
}) => {
    const { t } = useTranslation()
    const [isExporting, setIsExporting] = useState(false)
    const [exportErrorDialogMessage, setExportErrorDialogMessage] = useState(undefined)
    const [downloadAnchorEl, setDownloadAnchorEl] = useState(null)
    const [showExportHas3DDrawingsAlert, setShowExportHas3DDrawingsAlert] = useState(false)
    const [showNotEnoughCreditsAlert, setShowNotEnoughCreditsAlert] = useState(false)
    const downloadMenuOpen = Boolean(downloadAnchorEl)
    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const quoteStatus = quote?.status

    const [getQuoteItems] = useLazyGetQuoteItemsQuery()

    const { showAdminTools, showDownloadPdfs, showProductionPDFs } = useToolBoxTreatments()

    const handleDownloadClick = (event) => {
        setDownloadAnchorEl(event.currentTarget)
    }

    const handleDownloadClose = () => {
        setDownloadAnchorEl(null)
    }

    const handleExporthas3DCancelClose = () => {
        setShowExportHas3DDrawingsAlert(false)
        setIsExporting(false)
    }

    const handleExporthas3DOkClose = (quoteId) => {
        setShowExportHas3DDrawingsAlert(false)
        exportPartsInQuote(quoteId)
    }

    const exportPartsInQuote = (quoteId) => {
        setIsExporting(true)
        handleDownloadClose()
        QuoteService.exportQuote(quoteId)
            .then(() => {
                setIsExporting(false)
            })
            .catch((_error) => {
                const errorMessage = t('$t(An error occurred) exporting this $t(quote)')
                setExportErrorDialogMessage(errorMessage)
                setIsExporting(false)
            })
    }

    const handleExportButtonClick = useCallback(
        async (quote) => {
            try {
                setIsExporting(true)

                const quoteItems = await getQuoteItems({ organisationId, quoteId: quote?.id }).unwrap()

                // get count of 3D flattened quoteItems
                const count3dParts = quoteItems.filter((p) => p.partSource === 'unfold3d' && !p.profile).length

                // No active subscription
                if (organisation.trial?.hasExpired && !organisation.hasActiveSubscription) {
                    setShowNotEnoughCreditsAlert(true)
                    return
                }

                if (count3dParts > 0) {
                    // Warn user before exporting 3D flattened quoteItems
                    setShowExportHas3DDrawingsAlert(true)
                    return
                } else {
                    exportPartsInQuote(quote?.id)
                }
            } catch {
                setExportErrorDialogMessage(t('$t(An error occurred), failed to export the $t(quote).'))
                setIsExporting(false)
            }
        },
        [exportPartsInQuote, organisation.hasActiveSubscription, organisation.trial, quote, t]
    )

    const handlePublicDocumentsClick = (quoteId, document) => {
        window.open(`${Paths.SHARED_PATHNAME}/v2/${quoteId}/${document}/${autoExport ? 'export' : ''}`, '_blank')
        handleDownloadClose()
    }

    const handlePrivateDocumentsClick = (quoteId, document) => {
        window.open(`/quotes/${quoteId}/documents/${document}/${autoExport ? 'export' : ''}`, '_blank')
        handleDownloadClose()
    }

    const handleExportToJSONClick = () => {
        browserFileDownload(JSON.stringify(quote), `${quote?.name ?? 'Untitled'}.json`, 'application/json')
    }

    const downloadOptions = QuoteDownloadDocumentsOptions[quoteStatus]

    const menuItems = useMemo(() => {
        return downloadOptions?.map((option) => {
            const { disabled, hidden, id, label, tagAttrs, type } = option
            return {
                id,
                label,
                disabled,
                hidden: hidden || (type === 'private' && !showProductionPDFs),
                type,
                tagAttrs,
            }
        })
    }, [downloadOptions])

    return (
        <>
            {showDownloadPdfs ? (
                <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
                    {useIconButton ? (
                        <IconButton
                            aria-controls={downloadMenuOpen ? 'download-menu' : undefined}
                            aria-expanded={downloadMenuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            disabled={disabled || isExporting}
                            id="download-button"
                            size="small"
                            onClick={handleDownloadClick}
                        >
                            {isExporting ? (
                                <CircularProgress
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                <SaveAlt
                                    fontSize="small"
                                    sx={quote.isDeleted ? classes.disabledIcon(quote.isDeleted) : classes.icon}
                                />
                            )}
                        </IconButton>
                    ) : (
                        <Button
                            aria-controls={downloadMenuOpen ? 'download-menu' : undefined}
                            aria-expanded={downloadMenuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            color={color}
                            disabled={disabled || isExporting}
                            disableElevation={disableElevation}
                            endIcon={
                                isExporting ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : (
                                    <SaveAlt />
                                )
                            }
                            id="download-button"
                            variant={variant}
                            onClick={handleDownloadClick}
                        >
                            {isExporting ? t('Exporting...') : t('Download')}
                        </Button>
                    )}

                    <Menu
                        anchorEl={downloadAnchorEl}
                        id="download-menu"
                        MenuListProps={{
                            'aria-labelledby': 'download-button',
                        }}
                        open={downloadMenuOpen}
                        onClose={handleDownloadClose}
                    >
                        <Typography
                            component="li"
                            key="title"
                            px={2}
                            py={0.75}
                            variant="strong1"
                        >
                            {t('Download documents')}
                        </Typography>
                        <MenuItem
                            data-testid="drawings-and-csv"
                            disabled={
                                quoteStatus === 'Rejected' || quoteStatus === 'PendingOrderConfirmation' || isOnFreePlan
                            }
                            key="drawings-and-csv"
                            onClick={() => handleExportButtonClick(quote)}
                        >
                            {t('Drawings and CSV')}
                        </MenuItem>
                        <Divider key="divider1" />

                        {menuItems?.map(
                            (menuItem) =>
                                !menuItem.hidden && [
                                    <MenuItem
                                        disabled={
                                            menuItem.disabled ||
                                            (isOnFreePlan && menuItem.id !== PUBLIC_DOCUMENTS.Quote)
                                        }
                                        key={menuItem.id}
                                        onClick={() =>
                                            menuItem.type === 'private'
                                                ? handlePrivateDocumentsClick(quote?.id, menuItem.id)
                                                : handlePublicDocumentsClick(quote?.id, menuItem.id)
                                        }
                                        {...menuItem.tagAttrs}
                                    >
                                        {t(menuItem.label)}
                                    </MenuItem>,

                                    ...(menuItem.id === PUBLIC_DOCUMENTS.TaxInvoice && showProductionPDFs
                                        ? [<Divider key="divider2" />]
                                        : []),
                                ]
                        )}

                        {showAdminTools
                            ? [
                                  <Divider key="divider3" />,
                                  <MenuItem
                                      data-testid="export-quote-to-json"
                                      disabled={!showAdminTools}
                                      key="export-quote-to-json"
                                      onClick={() => handleExportToJSONClick()}
                                  >
                                      {t('Admin: Export as JSON')}
                                  </MenuItem>,
                              ]
                            : null}
                    </Menu>
                </Box>
            ) : (
                <Button
                    color="primary"
                    data-testid="export-button"
                    disabled={disabled || isExporting}
                    startIcon={<SaveAlt />}
                    sx={[...(Array.isArray(sx) ? sx : [sx])]}
                    variant="outlined"
                    onClick={() => handleExportButtonClick(quote?.id)}
                >
                    {isExporting ? t('Exporting...') : t('Export')}
                </Button>
            )}

            <AlertDialog
                content={exportErrorDialogMessage}
                hideCancelButton={true}
                open={Boolean(exportErrorDialogMessage)}
                title={t('Export Failed')}
                onOkClose={() => setExportErrorDialogMessage(undefined)}
            />

            <AlertDialog
                content={t(
                    "You don't have enough credits to export this $t(quote). You will need to upgrade your subscription to continue."
                )}
                hideCancelButton={true}
                open={showNotEnoughCreditsAlert}
                title={t('Not enough credits')}
                onCancelClose={() => setShowNotEnoughCreditsAlert(false)}
                onOkClose={() => setShowNotEnoughCreditsAlert(false)}
            />
            <AlertDialog
                content={t(
                    'Warning: DXF or DWG drawings from flattened parts should not be used for production purposes as they use a generic k-factor of 0.44 for unfolding.'
                )}
                hideCancelButton={false}
                open={showExportHas3DDrawingsAlert}
                title={t('This $t(quote) has flattened parts.')}
                onCancelClose={handleExporthas3DCancelClose}
                onOkClose={() => handleExporthas3DOkClose(quote?.id)}
            />
        </>
    )
}

DownloadDocuments.propTypes = {
    autoExport: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    quote: PropTypes.shape({
        id: PropTypes.string,
        isDeleted: PropTypes.bool,
        name: PropTypes.string,
        quoteItems: PropTypes.array,
        status: PropTypes.string,
    }),
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    useIconButton: PropTypes.bool,
    variant: PropTypes.string,
}

export default DownloadDocuments
