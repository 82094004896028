import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetMaterialQuery } from '@/app/services/materials'
import { useUpdateSheetMutation } from '@/app/services/sheets'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectCurrencySymbol,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { getMaterialConsumptionModes } from '@/common/helpers/MaterialConsumptionModes'
import { useNumberFormatter } from '@/common/hooks'
import { calculateCostPrice, calculateRatePrice, fractionToPercentage, percentageToFraction } from '@/common/utils'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
}

const MaterialProfileTypes = [
    { name: 'Rectangle', value: 'Rectangle' },
    { name: 'Square', value: 'Square' },
    { name: 'Circle', value: 'Circle' },
    { name: 'Flat sided oval', value: 'FlatSidedOval' },
]

const AddSheet = ({ onCancel, onClose, selectedMaterialId, sheet = null }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const locale = useSelector(selectLocale)
    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const paidFeatures = useSelector(selectPaidFeatures)

    const { data: selectedMaterial } = useGetMaterialQuery({ organisationId, materialId: selectedMaterialId })

    const sheets = selectedMaterial?.sheets

    const [updateSheet] = useUpdateSheetMutation()

    const { n } = useNumberFormatter({
        locale: locale,
        numberOfDecimalPlaces: 2,
    })

    const [isSaving, setIsSaving] = useState(false)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    const userTimeZone = dayjs.tz.guess()

    const { control, formState, getValues, handleSubmit, setValue, watch } = useForm({
        mode: 'all',
        defaultValues: {
            sheetId: sheet?.sheetId || '',
            profile: sheet?.profile || '',
            thickness: sheet?.thickness || '',
            sheetWidth: sheet?.sheetWidth || '',
            sheetHeight: sheet?.sheetHeight || '',
            diameter: sheet?.diameter || '',
            materialLength: sheet?.materialLength || '',
            sheetCost: sheet?.sheetCost || '',
            sheetRatePrice: sheet?.sheetRatePrice || '',
            extraMarkupPercent: fractionToPercentage(sheet?.extraMarkupPercent) || 0,
            expiryDate: sheet?.expiryDate ? dayjs.utc(sheet.expiryDate).tz(dayjs.tz.guess()) : null,
            defaultMaterialConsumptionMode: sheet?.defaultMaterialConsumptionMode || 'WholeSheet',
            materialId: selectedMaterial?.materialId || '',
            isSoldByOrganisation: sheet?.isSoldByOrganisation || true,
            isDefault: sheet?.isDefault || false,
            isDeleted: sheet?.isDeleted || false,
        },
    })

    const handleAddSheetButtonClick = async (data, _) => {
        setIsSaving(true)

        if (sheet) {
            data.isSoldByOrganisation = sheet.isSoldByOrganisation
            data.extraMarkupPercent = percentageToFraction(data.extraMarkupPercent)
        } else {
            if (data.extraMarkupPercent != 0) data.extraMarkupPercent = percentageToFraction(data.extraMarkupPercent)
        }
        if (data.profile === 'Square') {
            data.sheetHeight = data.sheetWidth
        }

        if (data.profile === 'Rectangle' && data.sheetHeight === data.sheetWidth) {
            data.profile = 'Square'
        }

        if (data.expiryDate && data.expiryDate.isValid()) {
            data.expiryDate = data.expiryDate.utc().format()
        }

        try {
            await updateSheet({ organisationId, sheet: data })
            enqueueSnackbar(t('Sheet updated'), { variant: 'success' })
            onClose()
            onCancel()
        } catch (error) {
            enqueueSnackbar(t(error.response?.data || 'An error occurred'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    const getTitle = () => {
        return t(sheet ? 'Edit {{type}}' : 'Add {{type}}', { type: materialIs('Rotary') ? t('record') : t('sheet') })
    }

    const getSaveButtonText = () => {
        return t(sheet ? 'Update' : 'Add')
    }

    const foundSimilarSheet = () => {
        const thickness = getValues('thickness')
        const sheetHeight = getValues('sheetHeight')
        const sheetWidth = getValues('sheetWidth')

        return (
            sheets &&
            sheets.find(
                (s) =>
                    s.thickness.toString() === thickness &&
                    s.sheetWidth.toString() === sheetWidth &&
                    s.sheetHeight.toString() === sheetHeight
            )
        )
    }

    const foundSimilarRecord = () => {
        const thickness = getValues('thickness')
        const sheetHeight = getValues('sheetHeight')
        const sheetWidth = getValues('sheetWidth')
        const diameter = getValues('diameter')
        const materialLength = getValues('materialLength')
        const profile = getValues('profile')

        if (profile === 'Circle') {
            return (
                sheets &&
                sheets.find(
                    (s) =>
                        s.profile === profile &&
                        s.thickness.toString() === thickness &&
                        s.materialLength.toString() === materialLength &&
                        s.diameter.toString() === diameter
                )
            )
        } else if (profile === 'Square') {
            return (
                sheets &&
                sheets.find(
                    (s) =>
                        s.profile === profile &&
                        s.thickness.toString() === thickness &&
                        s.sheetWidth.toString() === sheetWidth &&
                        s.materialLength.toString() === materialLength
                )
            )
        } else {
            return (
                sheets &&
                sheets.find(
                    (s) =>
                        s.profile === profile &&
                        s.thickness.toString() === thickness &&
                        s.sheetWidth.toString() === sheetWidth &&
                        s.sheetHeight.toString() === sheetHeight &&
                        s.materialLength.toString() === materialLength
                )
            )
        }
    }

    const canAddSheet = () => {
        let sheetFound = false

        if (materialIs('Rotary')) {
            sheetFound = foundSimilarRecord()
        } else {
            sheetFound = foundSimilarSheet()
        }

        if (sheetFound) {
            return t('The same {{type}} already exists for selected material', {
                type: materialIs('Rotary') ? t('record') : t('sheet'),
            })
        }

        return true
    }

    const extraMarkupPercent = watch('extraMarkupPercent')

    const getAppliedMarkup = () => {
        return (
            fractionToPercentage(selectedMaterial?.standardMarkupPercentage ?? 0) +
            (isNaN(parseFloat(extraMarkupPercent)) ? 0 : parseFloat(extraMarkupPercent))
        )
    }

    const recordProfile = watch('profile')

    const materialIs = useCallback(
        (materialType) => {
            return selectedMaterial?.type === materialType
        },
        [selectedMaterial]
    )

    const costPriceChanged = (val) => {
        const newRatePrice = calculateRatePrice(val, recordProfile, {
            density: selectedMaterial.density,
            thickness: getValues('thickness'),
            width: getValues('sheetWidth'),
            height: getValues('sheetHeight'),
            diameter: getValues('diameter'),
            materialLength: getValues('materialLength'),
            useImperialUnits,
        })
        setValue('sheetRatePrice', newRatePrice, { shouldValidate: true, shouldDirty: true })
    }

    const ratePriceChanged = (val) => {
        const newCostPrice = calculateCostPrice(val, recordProfile, {
            density: selectedMaterial.density,
            thickness: getValues('thickness'),
            width: getValues('sheetWidth'),
            height: getValues('sheetHeight'),
            diameter: getValues('diameter'),
            materialLength: getValues('materialLength'),
            useImperialUnits,
        })
        setValue('sheetCost', newCostPrice, { shouldValidate: true, shouldDirty: true })
    }

    return (
        <>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '24px',
                    lineHeight: '28px',
                    color: 'text.primary',
                    marginBottom: '24px',
                }}
            >
                {getTitle()}
            </Typography>

            {materialIs('Rotary') ? (
                <Controller
                    control={control}
                    name="profile"
                    render={({ field: { onBlur, onChange, value } }) => (
                        <FormControl
                            data-testid="record-profile-selector"
                            sx={{ marginBottom: 2 }}
                            fullWidth
                        >
                            <InputLabel id="simple-select-filled-label">{t('Profile')}</InputLabel>
                            <Select
                                disabled={sheet ? materialIs('Rotary') : null}
                                id="simple-select-filled"
                                labelId="simple-select-filled-label"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                            >
                                <MenuItem
                                    key="default"
                                    value=""
                                >
                                    -
                                </MenuItem>
                                {MaterialProfileTypes.map((profile) => (
                                    <MenuItem
                                        key={profile.value}
                                        value={profile.value}
                                    >
                                        {t(profile.name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    rules={{ required: 'Required' }}
                />
            ) : null}

            <Controller
                control={control}
                name="thickness"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        disabled={Boolean(sheet)}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                            ),
                        }}
                        label={materialIs('Rotary') ? t('Wall thickness') : t('Thickness')}
                        sx={{ marginBottom: 2 }}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: true, min: 0, max: 1000, validate: () => !!sheet || canAddSheet() }}
            />

            {materialIs('Flat') || (materialIs('Rotary') && recordProfile !== 'Circle') ? (
                <Controller
                    control={control}
                    name="sheetWidth"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            disabled={Boolean(sheet)}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                ),
                            }}
                            label={t(materialIs('Rotary') ? 'Width' : 'Sheet width')}
                            sx={{ marginBottom: 2 }}
                            type="number"
                            value={value}
                            fullWidth
                            required
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: true, min: 0, max: 99999999, validate: () => !!sheet || canAddSheet() }}
                />
            ) : null}

            {materialIs('Rotary') && recordProfile === 'Circle' ? (
                <Controller
                    control={control}
                    name="diameter"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            disabled={sheet ? materialIs('Rotary') : null}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                ),
                            }}
                            label={t('Diameter')}
                            sx={{ marginBottom: 2 }}
                            type="number"
                            value={value}
                            fullWidth
                            required
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: true, min: 0, max: 99999999, validate: () => !!sheet || canAddSheet() }}
                />
            ) : null}

            {materialIs('Flat') ||
            (materialIs('Rotary') && (recordProfile === 'Rectangle' || recordProfile === 'FlatSidedOval')) ? (
                <Controller
                    control={control}
                    name="sheetHeight"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            disabled={Boolean(sheet)}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                ),
                            }}
                            label={t(materialIs('Rotary') ? 'Height' : 'Sheet length')}
                            sx={{ marginBottom: 2 }}
                            type="number"
                            value={value}
                            fullWidth
                            required
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: true, min: 0, max: 99999999, validate: () => !!sheet || canAddSheet() }}
                />
            ) : null}

            {materialIs('Rotary') ? (
                <Controller
                    control={control}
                    name="materialLength"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{useImperialUnits ? 'in' : 'mm'}</InputAdornment>
                                ),
                            }}
                            label={t('Material length')}
                            sx={{ marginBottom: 2 }}
                            type="number"
                            value={value}
                            fullWidth
                            required
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{ required: true, min: 0, max: 99999999, validate: () => !!sheet || canAddSheet() }}
                />
            ) : null}

            <Controller
                control={control}
                name="sheetCost"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                        }}
                        label={t(materialIs('Rotary') ? 'Unit Cost' : 'Sheet Cost')}
                        sx={{ marginBottom: 2 }}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={(e) => {
                            costPriceChanged(e.target.value)
                            onChange(e.target.value)
                        }}
                    />
                )}
                rules={{ min: 0, max: 9999999999 }}
            />
            <Controller
                control={control}
                name="sheetRatePrice"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {`${currencySymbol}/${useImperialUnits ? 'lb' : 'kg'}`}
                                </InputAdornment>
                            ),
                        }}
                        label={t(materialIs('Rotary') ? 'Unit rate' : 'Sheet Rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={(e) => {
                            ratePriceChanged(e.target.value)
                            onChange(e.target.value)
                        }}
                    />
                )}
                rules={{ min: 0, max: 9999999999 }}
            />
            <Controller
                control={control}
                name="extraMarkupPercent"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        leaveDelay={1000}
                        title={paidFeatures.hasAdvancedMaterials ? '' : <UpgradePlanLink />}
                        arrow
                        disableFocusListener
                        disableTouchListener
                    >
                        <TextField
                            disabled={!paidFeatures.hasAdvancedMaterials}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            label={t('Extra Markup')}
                            sx={classes.input}
                            type="number"
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
                rules={{ required: false, minLength: 0, min: -9999999999, max: 9999999999 }}
            />

            <TbxTooltip
                leaveDelay={1000}
                title={paidFeatures.hasAdvancedMaterials ? '' : <UpgradePlanLink />}
                arrow
                disableFocusListener
                disableTouchListener
            >
                <TextField
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    label={t('Applied markup')}
                    sx={classes.input}
                    value={n(getAppliedMarkup())}
                    disabled
                    fullWidth
                />
            </TbxTooltip>

            <Controller
                control={control}
                name="expiryDate"
                render={({ field: { onChange, value }, fieldState }) => (
                    <TbxLocalizationProvider>
                        <TbxTooltip
                            leaveDelay={1000}
                            title={paidFeatures.hasAdvancedMaterials ? '' : <UpgradePlanLink />}
                            arrow
                            disableFocusListener
                            disableTouchListener
                        >
                            <Grid>
                                <DatePicker
                                    closeOnSelect={false}
                                    disabled={!paidFeatures.hasAdvancedMaterials}
                                    disableOpenPicker={!paidFeatures.hasAdvancedMaterials}
                                    format="DD-MMM-YYYY"
                                    slotProps={{
                                        textField: {
                                            placeholder: t('Expiry date'),
                                            helperText: fieldState.error?.message || '',
                                            error: fieldState.invalid,
                                            sx: classes.input,
                                            variant: 'standard',
                                            fullWidth: true,
                                            inputProps: {
                                                id: 'sheet-date-picker',
                                            },
                                        },
                                        field: {
                                            readOnly: true,
                                        },
                                        actionBar: {
                                            actions: ['clear', 'cancel', 'accept'],
                                            disableSpacing: true,
                                        },
                                    }}
                                    timezone={userTimeZone}
                                    value={value ? dayjs(value) : null}
                                    disablePast
                                    onAccept={onChange}
                                />
                            </Grid>
                        </TbxTooltip>
                    </TbxLocalizationProvider>
                )}
            />
            <Controller
                control={control}
                name="defaultMaterialConsumptionMode"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <FormControl fullWidth>
                        <InputLabel id="simple-select-filled-label">
                            {t('Default material consumption mode')}
                        </InputLabel>
                        <Select
                            id="simple-select-filled"
                            labelId="simple-select-filled-label"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                        >
                            {getMaterialConsumptionModes(selectedMaterial?.type !== 'Flat').map((mode) => (
                                <MenuItem
                                    key={mode.value}
                                    value={mode.value}
                                >
                                    {t(mode.name)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                rules={{ required: 'Required' }}
            />

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddSheetButtonClick)}
                >
                    {isSaving ? t('Saving...') : getSaveButtonText()}
                </Button>
            </Box>
        </>
    )
}

AddSheet.propTypes = {
    selectedMaterialId: PropTypes.string,
    sheet: PropTypes.object,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
}

export default AddSheet
