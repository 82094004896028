import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ChromeReaderMode, ChromeReaderModeOutlined } from '@mui/icons-material'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetMiscItemsQuery, useUpdateMiscItemMutation } from '@/app/services/miscItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectCurrencyCode,
    selectCurrencySymbol,
    selectOrganisationId,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { getCurrencyFormat, getFormattedPrice, QuoteStatus } from '@/common/utils'

import ItemNotesModal from '../../common/ItemNotesModal'

const classes = {
    ItemDetails_Settings: {
        gap: 2,
    },
}

const MiscItemSettings = ({ miscItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    const useImperialUnits = useSelector(selectUseImperialUnits)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const currencyFormat = getCurrencyFormat(currencyCode, locale)
    const placeholder = getFormattedPrice(0, currencyCode, locale, true)

    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { quoteItem: miscItem } = useGetMiscItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === miscItemId),
            }),
        }
    )

    const [updateQuote] = useUpdateQuoteMutation()
    const [updateMiscItem] = useUpdateMiscItemMutation()

    const [miscItemDimensions, setMiscItemDimensions] = useState(miscItem?.dimensions || '')
    const [miscItemWeight, setMiscItemWeight] = useState(miscItem?.weight || '')
    const [miscItemTime, setMiscItemTime] = useState(miscItem?.productionTimePerItemSeconds || '')
    const [miscItemQuantity, setMiscItemQuantity] = useState(miscItem?.quantity || 1)
    const [miscItemPrice, setMiscItemPrice] = useState(miscItem?.price || '')

    const [openNotesModal, setOpenNotesModal] = useState(false)
    const [quantityError, setQuantityError] = useState(false)

    const partHasNotes = useMemo(() => {
        const { customerNotes, privateNotes, vendorNotes } = miscItem
        return Boolean(customerNotes) || Boolean(vendorNotes) || Boolean(privateNotes)
    }, [miscItem])

    const units = useMemo(() => (useImperialUnits ? 'in' : 'mm'), [useImperialUnits])
    const weightUnits = useMemo(() => (useImperialUnits ? 'lb' : 'kg'), [useImperialUnits])

    const handleUpdateQuote = async () => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    status: QuoteStatus.NotCalculated,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateMiscItem = async (miscItem) => {
        try {
            await updateMiscItem({
                organisationId,
                quoteId,
                miscItemId: miscItem?.id,
                miscItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the miscellaneous item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateMiscItem = useDebouncedCallback(handleUpdateMiscItem, 1000)

    const disableNotesButton = false
    const showNotesModal = () => {
        setOpenNotesModal(true)
    }
    const hideNotesModal = () => {
        setOpenNotesModal(false)
    }

    const handleDimensionsChange = async (event) => {
        debounceHandleUpdateMiscItem.cancel()

        const value = event.target.value
        setMiscItemDimensions(value)
        await debounceHandleUpdateMiscItem({
            ...miscItem,
            dimensions: value,
        })
    }

    const handleWeightChange = async (values) => {
        debounceHandleUpdateMiscItem.cancel()
        const { floatValue } = values

        setMiscItemWeight(floatValue)

        await debounceHandleUpdateMiscItem({
            ...miscItem,
            weight: floatValue,
        })
    }

    const handleTimeChange = async (values) => {
        debounceHandleUpdateMiscItem.cancel()

        const { floatValue } = values
        setMiscItemTime(floatValue)

        await debounceHandleUpdateMiscItem({
            ...miscItem,
            productionTimePerItemSeconds: floatValue,
        })
    }

    const handleQuantityChange = async (values) => {
        debounceHandleUpdateMiscItem.cancel()
        if (selectedQuote.status !== QuoteStatus.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QuoteStatus.NotCalculated))
            handleUpdateQuote()
        }

        const { floatValue } = values
        setQuantityError(!floatValue || isNaN(floatValue) || floatValue <= 0)

        setMiscItemQuantity(floatValue)
        if (!isNaN(floatValue) || floatValue >= 1) {
            await debounceHandleUpdateMiscItem({
                ...miscItem,
                quantity: floatValue,
            })
        }
    }

    const handleItemPriceChange = async (event) => {
        debounceHandleUpdateMiscItem.cancel()
        if (selectedQuote.status !== QuoteStatus.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QuoteStatus.NotCalculated))
            handleUpdateQuote()
        }

        const value = event.floatValue
        setMiscItemPrice(event.floatValue ?? null)

        await debounceHandleUpdateMiscItem({
            ...miscItem,
            price: value ?? 0,
        })
    }

    useEffect(() => {
        setMiscItemDimensions(miscItem?.dimensions || '')
    }, [miscItem?.cuttingTechnologyId])

    useEffect(() => {
        setMiscItemWeight(miscItem?.weight || '')
    }, [miscItem?.materialId])

    useEffect(() => {
        setMiscItemTime(miscItem?.productionTimePerItemSeconds || '')
    }, [miscItem?.thickness])

    useEffect(() => {
        setMiscItemQuantity(miscItem.quantity)
    }, [miscItem?.quantity])

    useEffect(() => {
        setMiscItemPrice(miscItem?.price || '')
    }, [miscItem?.price])

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="0 1 auto"
            flexDirection="column"
            justifyContent="flex-start"
            sx={classes.ItemDetails_Settings}
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
            >
                <Typography
                    data-testid="quote-item-settings-section-title"
                    style={{ fontWeight: 700 }}
                    variant="body1"
                >
                    {t('Settings')}
                </Typography>

                <Button
                    color="secondary"
                    data-testid="misc-item-notes-button"
                    disabled={disableNotesButton}
                    size="small"
                    startIcon={partHasNotes ? <ChromeReaderMode /> : <ChromeReaderModeOutlined />}
                    variant="text"
                    onClick={showNotesModal}
                >
                    {t('Notes')}
                </Button>
            </Box>

            <Box
                display="flex"
                gap={3}
                justifyContent="space-between"
            >
                <Box flex="1 1 20%">
                    <Typography data-testid="misc-item-dimensions-label">{t('Dimensions')}</Typography>
                    <TextField
                        color="primary"
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'misc-item-dimensions',
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{units}</InputAdornment>,
                        }}
                        size="small"
                        type="text"
                        value={miscItemDimensions}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={handleDimensionsChange}
                    />
                </Box>

                <Box flex="0 1 100px">
                    <Typography data-testid="misc-item-weight-label">{t('Weight')}</Typography>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'misc-item-weight',
                        }}
                        placeholder="kg"
                        size="small"
                        suffix={` ${weightUnits}`}
                        sx={{ '.MuiInputRoot.MuiDisabled': classes.disabled }}
                        thousandSeparator={currencyFormat.group}
                        value={miscItemWeight}
                        variant="outlined"
                        onValueChange={handleWeightChange}
                    />
                </Box>

                <Box flex="0 1 100px">
                    <Typography data-testid="misc-item-time-label">{t('Time')}</Typography>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'misc-item-time',
                        }}
                        placeholder="s"
                        size="small"
                        suffix=" s"
                        sx={{ '.MuiInputRoot.MuiDisabled': classes.disabled }}
                        thousandSeparator={currencyFormat.group}
                        value={miscItemTime}
                        variant="outlined"
                        onValueChange={handleTimeChange}
                    />
                </Box>

                <Box flex="0 1 100px">
                    <Typography data-testid="misc-item-quantity-label">{t('Quantity')}</Typography>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        error={quantityError}
                        helperText={quantityError ? t('Quantity cannot be less than 1') : null}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'misc-item-quantity',
                        }}
                        placeholder="0"
                        size="small"
                        sx={{ '.MuiInputRoot.MuiDisabled': classes.disabled }}
                        thousandSeparator={currencyFormat.group}
                        value={miscItemQuantity}
                        variant="outlined"
                        onValueChange={handleQuantityChange}
                    />
                </Box>

                <Box flex="0 1 120px">
                    <Typography data-testid="misc-item-unit-price-label">{t('Price')}</Typography>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalScale={currencyFormat.decimalPlaces}
                        decimalSeparator={currencyFormat.decimal}
                        fixedDecimalScale={true}
                        inputProps={{
                            style: { textAlign: 'right' },
                        }}
                        InputProps={{
                            fullWidth: true,
                        }}
                        placeholder={placeholder}
                        prefix={currencyFormat.currencySymbolPosition === 'before' ? currencySymbol : undefined}
                        size="small"
                        suffix={currencyFormat.currencySymbolPosition === 'after' ? currencySymbol : undefined}
                        thousandSeparator={currencyFormat.group}
                        value={miscItemPrice}
                        variant="outlined"
                        onValueChange={handleItemPriceChange}
                    />
                </Box>
            </Box>

            {openNotesModal ? (
                <ItemNotesModal
                    handleUpdate={handleUpdateMiscItem}
                    miscItem={miscItem}
                    open={openNotesModal}
                    onClose={hideNotesModal}
                />
            ) : null}
        </Box>
    )
}

export default memo(MiscItemSettings)

MiscItemSettings.propTypes = {
    miscItemId: PropTypes.string,
}
