import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Divider, Typography } from '@mui/material'

import { selectMiscItems } from '@/app/services/miscItems'
import { selectQuoteItems, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { selectQuote, useCalculateQuoteMutation } from '@/app/services/quotes'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { selectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { getLargestDimensions, QuoteStatus, secondsToDHMS } from '@/common/utils'
import { quoteHasIssues, quoteIssuesCount } from '@/common/utils/quoteUtilities'
import { formatNumber } from '@/features/web-store/helpers/utilities'

import QuoteItemStatus from '../common/QuoteItemStatus'

const classes = {
    summaryContainer: {
        borderRadius: 2,
        border: '1px solid #B0BEC5',
        background: '#FFF',
        height: '624px',
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const Summary = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const paidFeatures = useSelector(selectPaidFeatures)

    const { showMiscItems } = useToolBoxTreatments()

    const quote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const miscItems = useSelector((state) => selectMiscItems(state, { organisationId, quoteId }))

    const hideTimes = useSelector(selectedQuoteStatus) === QuoteStatus.NotCalculated

    const isMiscItemsEnabled = showMiscItems && paidFeatures?.hasMiscellaneousItems

    const [, { isLoading: isUpdatingQuoteItem }] = useUpdateQuoteItemMutation({
        fixedCacheKey: 'updateQuoteItem',
    })

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const isPerformingAsyncActions = isUpdatingQuoteItem || isCalculating

    const areAllItemsCalculated = useMemo(() => {
        return quoteItems?.every((item) => Boolean(item.cutPrice))
    }, [quote, quoteItems])

    const hasIssues = useMemo(() => quoteHasIssues(quoteItems), [quoteItems])
    const issuesCount = useMemo(() => quoteIssuesCount(quoteItems), [quoteItems])

    const totalCuttingTime = quoteItems.reduce((total, quoteItem) => {
        const itemCuttingTime =
            (quoteItem?.setupTimePerItemSeconds ?? 0) +
            (quoteItem?.sheetChangeTimePerItemSeconds ?? 0) +
            (quoteItem?.runtime ?? 0) +
            (quoteItem?.unloadTimePerItemSeconds ?? 0)
        return total + itemCuttingTime * quoteItem.quantity
    }, 0)
    const totalFoldingTime = quoteItems.reduce(
        (total, quoteItem) => total + (quoteItem.foldingTotalTimePerItemSeconds ?? 0) * quoteItem.quantity,
        0
    )
    const totalSecProcessesTime = quoteItems.reduce(
        (total, quoteItem) => total + (quoteItem.secondaryProcessTotalTimePerItemSeconds ?? 0) * quoteItem.quantity,
        0
    )

    const totalmiscItemsTime = miscItems.reduce(
        (total, miscItem) => total + (miscItem.productionTimePerItemSeconds ?? 0) * miscItem.quantity,
        0
    )

    const totalMiscItemsWeight = miscItems.reduce(
        (total, miscItem) => total + (miscItem.weight ?? 0) * miscItem.quantity,
        0
    )

    const totalTime = totalCuttingTime + totalFoldingTime + totalSecProcessesTime + totalmiscItemsTime

    const largestDimensions = getLargestDimensions(quoteItems, organisation)

    return (
        <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            sx={classes.summaryContainer}
        >
            <ItemRow>
                <Typography
                    data-testid="quote-summary-section-title"
                    variant="strong1"
                >
                    {t('SUMMARY')}
                </Typography>
                {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        style={{ gap: '6px' }}
                    >
                        {hasIssues && Boolean(issuesCount.errors) ? (
                            <QuoteItemStatus
                                count={issuesCount.errors}
                                status="error"
                            />
                        ) : null}
                        {hasIssues && Boolean(issuesCount.warnings) ? (
                            <QuoteItemStatus
                                count={issuesCount.warnings}
                                status="warning"
                            />
                        ) : null}
                        {issuesCount.success ? (
                            <QuoteItemStatus
                                count={issuesCount.success}
                                status="success"
                            />
                        ) : null}
                    </Box>
                ) : null}
            </ItemRow>
            <ItemRow>
                <Typography
                    fontWeight="bold"
                    textTransform="uppercase"
                    variant="small"
                >
                    {t('Parts')}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Number of unique parts')}</Typography>
                <Typography variant="strong2">{quoteItems.length}</Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Net weight')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? `${formatNumber(quote.totalMass, locale)} ${useImperialUnits ? 'lb' : 'kg'}`
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Largest long-dimension of a part')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? largestDimensions.long
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Largest short-dimension of a part')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? largestDimensions.short
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>

            <Divider flexItem />

            {isMiscItemsEnabled ? (
                <>
                    <ItemRow>
                        <Typography
                            fontWeight="bold"
                            textTransform="uppercase"
                            variant="small"
                        >
                            {t('Miscellaneous Items')}
                        </Typography>
                    </ItemRow>
                    <ItemRow>
                        <Typography variant="body2">{t('Number of unique items')}</Typography>
                        <Typography variant="strong2">{miscItems.length}</Typography>
                    </ItemRow>
                    <ItemRow>
                        <Typography variant="body2">{t('Total weight')}</Typography>
                        <Typography variant="strong2">
                            {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                                ? `${formatNumber(totalMiscItemsWeight, locale)} ${useImperialUnits ? 'lb' : 'kg'}`
                                : String.fromCharCode(8212)}
                        </Typography>
                    </ItemRow>
                    <Divider flexItem />
                </>
            ) : null}

            <ItemRow>
                <Typography
                    fontWeight="bold"
                    textTransform="uppercase"
                    variant="small"
                >
                    {t('Times')}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Total cutting time')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? secondsToDHMS(totalCuttingTime)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Total folding time')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? secondsToDHMS(totalFoldingTime)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography variant="body2">{t('Total secondary processes time')}</Typography>
                <Typography variant="strong2">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? secondsToDHMS(totalSecProcessesTime)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
            {isMiscItemsEnabled ? (
                <ItemRow>
                    <Typography variant="body2">{t('Total miscellaneous items time')}</Typography>
                    <Typography variant="strong2">
                        {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                            ? secondsToDHMS(totalmiscItemsTime)
                            : String.fromCharCode(8212)}
                    </Typography>
                </ItemRow>
            ) : null}

            <ItemRow>
                <Typography variant="strong1">{t('Total time')}</Typography>
                <Typography variant="strong1">
                    {!hideTimes && areAllItemsCalculated && !isPerformingAsyncActions
                        ? secondsToDHMS(totalTime)
                        : String.fromCharCode(8212)}
                </Typography>
            </ItemRow>
        </Box>
    )
}

export default Summary
