import { useState } from 'react'
import { Controller, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Script from 'react-load-script'
import { LocationOn } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import PropTypes from 'prop-types'
import usePlacesAutocomplete from 'use-places-autocomplete'

const classes = {
    chips: {
        gap: 1,
    },

    icon: {
        color: 'text.secondary',
        marginRight: 2,
    },
}

const LocationPicker = ({
    control,
    disabled = false,
    label,
    name,
    required = false,
    rules,
    shouldUnregister = false,
    variant = 'outlined',
}) => {
    const { t } = useTranslation()

    const {
        clearSuggestions,
        ready,
        setValue,
        suggestions: { data },
        value: address,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['country', 'administrative_area_level_1'],
        },
        debounce: 300,
    })

    const { field } = useController({ name, control, rules })
    const { onChange, value: locations } = field

    const handleChange = async (_name, newInputValue, reason, onChange) => {
        if (reason === 'clear') {
            clearSuggestions()
        }

        if (newInputValue) {
            if (locations.length > 0) {
                const newInputValueExists = locations.some((option) => {
                    if (option.placeId && option.placeId === newInputValue.place_id) {
                        return true
                    }
                    if (option.place_id && option.place_id === newInputValue.place_id) {
                        return true
                    }
                })

                if (!newInputValueExists) {
                    onChange([...locations, newInputValue])
                }
            } else {
                onChange([newInputValue])
            }
        }
    }

    const handleLocationRemoval = (placeId) => {
        const newOptions = locations.filter((option) => {
            if (option.placeId && option.placeId !== placeId) {
                return option
            }
            if (option.place_id && option.place_id !== placeId) {
                return option
            }
        })
        onChange(newOptions)
    }

    return (
        <>
            <Box
                display="flex"
                flexWrap="wrap"
                my={1}
                sx={classes.chips}
            >
                {locations?.map((location) => {
                    const placeId = location.place_id || location.placeId
                    const label = location.region || location.structured_formatting?.main_text
                    return (
                        <Chip
                            key={placeId}
                            label={label}
                            variant="outlined"
                            onDelete={() => handleLocationRemoval(placeId)}
                        />
                    )
                })}
            </Box>

            <Controller
                control={control}
                name={name}
                render={({ field: { onChange }, fieldState }) => (
                    <Autocomplete
                        disabled={!ready || disabled}
                        filterOptions={(options) => options}
                        getOptionKey={(option) => option.place_id}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                        id="google-places-locations"
                        inputValue={address ?? ''}
                        options={data}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!fieldState.error}
                                helperText={t(fieldState.error?.message)}
                                label={label}
                                required={required}
                                variant={variant}
                                fullWidth
                            />
                        )}
                        renderOption={(props, option) => {
                            const { key, ...rest } = props
                            const matches = option?.structured_formatting?.main_text_matched_substrings || []
                            const parts = parse(
                                option?.structured_formatting?.main_text,
                                matches?.map((match) => [match?.offset, match?.offset + match?.length])
                            )
                            return (
                                <Grid
                                    alignItems="center"
                                    component="li"
                                    key={key}
                                    container
                                    {...rest}
                                >
                                    <Grid item>
                                        <LocationOn sx={classes.icon} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                    >
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}

                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                        >
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }}
                        value=""
                        autoComplete
                        clearOnEscape
                        fullWidth
                        includeInputInList
                        onChange={(_event, newInputValue, reason) => {
                            handleChange(name, newInputValue, reason, onChange)
                        }}
                        onInputChange={(_event, newInputValue) => {
                            setValue(newInputValue)
                        }}
                    />
                )}
                rules={rules}
                shouldUnregister={shouldUnregister}
            />
        </>
    )
}

LocationPicker.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    rules: PropTypes.object,
    shouldUnregister: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

const FormLocationPicker = (props) => {
    const [scriptLoaded, setScriptLoaded] = useState(false)

    const handleScriptLoad = () => {
        setScriptLoaded(true)
    }

    return (
        <>
            <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${
                    import.meta.env.VITE_GOOGLE_API_KEY
                }&libraries=places`}
                onLoad={handleScriptLoad}
            />

            {scriptLoaded ? <LocationPicker {...props} /> : null}
        </>
    )
}

FormLocationPicker.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    rules: PropTypes.object,
    shouldUnregister: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

export default FormLocationPicker
