import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { selectQuoteItems } from '@/app/services/quoteItems'
import { useGetSheetsQuery } from '@/app/services/sheets'
import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'
import { useNumberFormatter } from '@/common/hooks'

const classes = {
    numSheetsContainer: {
        borderRadius: 2,
        border: '1px solid #B0BEC5',
        background: '#FFF',
        height: '624px',
        overflow: 'hidden',
    },
}

const ItemRow = ({ children, ...props }) => (
    <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        width="100%"
        {...props}
    >
        {children}
    </Box>
)

const MaterialRequirements = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)

    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)

    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const organisationLengthUnit = useImperialUnits ? 'in' : 'mm'

    const { data: materials, isLoading: isLoadingMaterials } = useGetMaterialsQuery({ organisationId })
    const { data: sheets, isLoading: isLoadingSheets } = useGetSheetsQuery({ organisationId })

    const summariseMaterialGroupsAndProcesses = useCallback(
        (quoteItems) => {
            if (isLoadingMaterials || isLoadingSheets) {
                return {
                    materialGroups: {},
                }
            }

            return quoteItems.reduce(
                (result, currentItem) => {
                    // Material groups
                    const isCurrentItemRotary = 'profile' in currentItem

                    const currentItemGroupKey =
                        currentItem.materialId +
                        currentItem.sheetId +
                        currentItem.thickness +
                        (currentItem.isCustomerSuppliedMaterial ? 1 : 0)

                    const requiredWholeSheets = currentItem.quantity / currentItem.partsPerSheet || 0

                    let requiredNestBoundsSheets = 0

                    if (isCurrentItemRotary) {
                        requiredNestBoundsSheets =
                            requiredWholeSheets *
                                (currentItem.rotaryProfileConsumption / currentItem.totalRotaryLength) || 0
                    } else {
                        requiredNestBoundsSheets =
                            requiredWholeSheets * (currentItem.nestArea / currentItem.totalSheetArea) || 0
                    }

                    const cuttingTimeMinutes =
                        (currentItem.quantity *
                            ((currentItem.setupTimePerItemSeconds ?? 0) +
                                (currentItem.sheetChangeTimePerItemSeconds ?? 0) +
                                (currentItem.runtime ?? 0) +
                                (currentItem.unloadTimePerItemSeconds ?? 0))) /
                        60

                    result.processesTotalTimeMinutes += cuttingTimeMinutes

                    if (result.materialGroups[currentItemGroupKey] === undefined) {
                        const material = materials[currentItem.materialId]
                        const sheet = sheets[currentItem.sheetId]

                        result.materialGroups[currentItemGroupKey] = {
                            key: currentItemGroupKey,
                            name: material?.materialName,
                            diameter: sheet?.diameter,
                            sheetWidth: sheet?.sheetWidth,
                            sheetHeight: sheet?.sheetHeight,
                            materialLength: sheet?.materialLength,
                            processingTotalTime: cuttingTimeMinutes,
                            thickness: currentItem.thickness,
                            requiredWholeSheets: requiredWholeSheets,
                            requiredNestBoundsSheets: requiredNestBoundsSheets,
                            netWeight: currentItem.mass * currentItem.quantity,
                            isCustomerSuppliedMaterial: currentItem.isCustomerSuppliedMaterial,
                            isRotary: 'profile' in currentItem,
                            profile: currentItem.profile,
                        }
                    } else {
                        result.materialGroups[currentItemGroupKey].processingTotalTime += cuttingTimeMinutes
                        result.materialGroups[currentItemGroupKey].requiredWholeSheets += requiredWholeSheets
                        result.materialGroups[currentItemGroupKey].requiredNestBoundsSheets += requiredNestBoundsSheets
                        result.materialGroups[currentItemGroupKey].netWeight += currentItem.mass * currentItem.quantity
                    }

                    return result
                },
                {
                    materialGroups: {},
                }
            )
        },
        [quoteItems, materials, sheets, isLoadingMaterials, isLoadingSheets]
    )

    const { n: dimensionFormat } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: useImperialUnits ? 4 : 2,
    })

    const { n } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: 2,
    })

    const getSheetSize = (sheet) => {
        if (sheet?.profile)
            if (sheet?.profile === 'Circle') {
                return `${dimensionFormat(sheet?.diameter)} x ${dimensionFormat(sheet?.materialLength)}`
            } else {
                return `${dimensionFormat(sheet?.sheetWidth)} x ${dimensionFormat(
                    sheet?.sheetHeight
                )} x ${dimensionFormat(sheet?.materialLength)}`
            }
        else {
            return `${dimensionFormat(sheet?.sheetWidth)} x ${dimensionFormat(sheet?.sheetHeight)}`
        }
    }

    const materialGroupsAndProcesses = summariseMaterialGroupsAndProcesses(quoteItems)

    return (
        <Box
            display="flex"
            flex={1}
            flexDirection="column"
            gap={1}
            justifyContent="space-between"
            p={3}
            sx={classes.numSheetsContainer}
        >
            <ItemRow
                flexDirection="column"
                gap="1"
                mb={1}
            >
                <Typography
                    data-testid="number-of-sheets-section-title"
                    variant="strong1"
                >
                    {t('ESTIMATED MATERIAL REQUIREMENTS')}
                </Typography>
                <Typography
                    data-testid="consumption-mode-subtitle"
                    variant="caption"
                >
                    {t('Material consumption modes:')}
                </Typography>
                <Typography
                    data-testid="connsumption-mode-abbr"
                    variant="caption"
                >
                    WS: {t('Whole Sheet')}, WL: {t('Whole Length')}, NB: {t('Nest Bounds')}
                </Typography>
            </ItemRow>

            <ItemRow key="header">
                <Typography
                    flex="1 0 200px"
                    fontWeight={800}
                    variant="small"
                >
                    {t('Material and size')}
                </Typography>

                <Typography
                    align="right"
                    flex="0 0 80px"
                    fontWeight={800}
                    variant="small"
                >
                    {t('Thickness')}
                </Typography>
                <Typography
                    align="right"
                    flex="0 0 100px"
                    fontWeight={800}
                    variant="small"
                >
                    {t('Sheets/Records')}
                </Typography>
            </ItemRow>

            <TbxShadowScroll wrapperSx={{ height: '100%' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    {materialGroupsAndProcesses?.materialGroups
                        ? Object.values(materialGroupsAndProcesses.materialGroups)
                              ?.filter((group) => typeof group.key == 'string' && group.sheetWidth !== undefined)
                              .map((group) => (
                                  <ItemRow key={group.key}>
                                      <Box flex="1 0 200px">
                                          <Typography variant="body2">{group.name}</Typography>

                                          <Typography variant="body2">
                                              {getSheetSize(group)} {organisationLengthUnit}
                                          </Typography>
                                      </Box>
                                      <Typography
                                          align="right"
                                          flex="0 0 80px"
                                          variant="body2"
                                      >
                                          {dimensionFormat(group.thickness)} {organisationLengthUnit}
                                      </Typography>
                                      <Typography
                                          align="right"
                                          flex="0 0 100px"
                                          variant="strong2"
                                      >
                                          {n(group.requiredWholeSheets) === 0
                                              ? String.fromCharCode(8212)
                                              : `${n(group.requiredWholeSheets)} ${group.isRotary ? '(WL)' : '(WS)'}`}
                                          <br />
                                          {n(group.requiredNestBoundsSheets) === 0
                                              ? String.fromCharCode(8212)
                                              : `${n(group.requiredNestBoundsSheets)} (NB)`}
                                      </Typography>
                                  </ItemRow>
                              ))
                        : null}
                </Box>
            </TbxShadowScroll>

            <ItemRow gap={1}>
                <Typography variant="strong2">{t('Note:')}</Typography>
                <Typography variant="body2">
                    {t('This is only an estimate and should not be used for ordering materials.')}
                </Typography>
            </ItemRow>
        </Box>
    )
}

export default MaterialRequirements
