import { webStoreApi } from './webStoreApi'

export const webStoreQuoteApi = webStoreApi
    .enhanceEndpoints({
        addTagTypes: ['WebStoreQuote'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getQuote: builder.query({
                query: ({ organisationId, quoteId }) => `/${organisationId}/${quoteId}`,
                providesTags: ['WebStoreQuote'],
            }),

            createQuote: builder.mutation({
                query: ({ organisationId, payload }) => ({
                    url: `/${organisationId}`,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
                invalidatesTags: ['WebStoreQuote'],
            }),

            updateQuote: builder.mutation({
                query: ({ organisationId, quote, quoteId }) => ({
                    url: `/${organisationId}/${quoteId}`,
                    method: 'PUT',
                    body: quote,
                }),
                invalidatesTags: ['WebStoreQuote'],
            }),

            calculateQuote: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${organisationId}/${quoteId}/calculate`,
                    method: 'POST',
                }),
                invalidatesTags: ['WebStoreQuote', 'WebStoreQuoteItems'],
            }),

            confirmOrder: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${organisationId}/${quoteId}/confirm-order`,
                    method: 'PUT',
                }),
                invalidatesTags: ['WebStoreQuote', 'WebStoreQuoteItems'],
            }),
        }),
    })

export const selectQuote = (state, query) => {
    return webStoreQuoteApi.endpoints.getQuote.select(query)(state)?.data
}

export const {
    useCalculateQuoteMutation,
    useConfirmOrderMutation,
    useCreateQuoteMutation,
    useGetQuoteQuery,
    useUpdateQuoteMutation,
} = webStoreQuoteApi
