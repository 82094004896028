import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { useGetMiscItemsQuery } from '@/app/services/miscItems'
import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { useGetQuoteQuery } from '@/app/services/quotes'
import { setSelectedCustomer, setSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { Paths } from '@/common/utils'
import { QuoteStatus } from '@/common/utils/Constants/Constants'

import MiscellaneousItemList from '../components/MiscellaneousItemList/MiscellaneousItemList'
import MiscItemListActions from '../components/MiscellaneousItemList/MiscItemListActions'
import QuoteHeader from '../components/QuoteHeader/QuoteHeader'
import QuoteItemList from '../components/QuoteItemList/QuoteItemList'
import QuoteItemListActions from '../components/QuoteItemList/QuoteItemListActions'
import QuoteReview from '../components/QuoteReview/QuoteReview'
import QuoteSummary from '../components/QuoteSummary/QuoteSummary'
import QuoteToolbar from '../components/QuoteToolbar/QuoteToolbar'

const classes = {
    root: {
        width: '100%',
        maxWidth: '1400px',
        margin: 3,
    },
    empty: {
        paddingBlock: 14,
        height: 'calc(100vh - 385px)',
    },
    content: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        width: '100%',
        padding: 3,
    },
}

const Quote = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const { showMiscItems } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)

    const {
        data: quote,
        isLoading,
        isSuccess,
    } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { data: quoteItems, isLoading: isLoadingItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const { data: miscItems, isLoading: isLoadingMiscItems } = useGetMiscItemsQuery({
        organisationId,
        quoteId,
    })

    const isQuoteEditable = useMemo(() => {
        return [QuoteStatus.NotCalculated, QuoteStatus.Calculated].includes(quote?.status)
    }, [quote?.status])

    useEffect(() => {
        return () => {
            dispatch(setSelectedCustomerId(null))
            dispatch(setSelectedCustomer(null))
        }
    }, [])

    if (!isLoading && isSuccess) {
        return (
            <Box sx={classes.root}>
                <QuoteToolbar
                    hasMiscItems={Boolean(miscItems?.length)}
                    hasQuoteParts={Boolean(quoteItems?.length)}
                />

                <QuoteHeader />

                {isLoadingItems ? <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={[classes.content, classes.empty]}
                                  >
                        <Box textAlign="center">
                            <Typography
                                component="h2"
                                variant="h6"
                            >
                                {t('Loading quote items, please wait...')}
                            </Typography>
                        </Box>
                    </Box> : null}

                {!isLoadingItems && !quoteItems?.length && !miscItems?.length ? <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={[classes.content, classes.empty]}
                                                                                >
                        <Box textAlign="center">
                            <Typography
                                component="h2"
                                variant="h6"
                            >
                                {t('No parts in this $t(quote)')}
                            </Typography>
                            <Typography>{t('Please add your parts, select the material and get a quote.')}</Typography>
                        </Box>
                    </Box> : null}

                {isQuoteEditable && !isLoadingItems && Boolean(quoteItems?.length) ? <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.content}
                                                                                     >
                        <Typography
                            mx={1.5}
                            textTransform="uppercase"
                            variant="strong1"
                        >
                            {t('Parts')}
                        </Typography>

                        <QuoteItemListActions />

                        <QuoteItemList />
                    </Box> : null}

                {showMiscItems && isQuoteEditable && !isLoadingMiscItems && Boolean(miscItems?.length) ? <Box
                        display="flex"
                        flexDirection="column"
                        mt={3}
                        sx={classes.content}
                                                                                                         >
                        <Typography
                            mx={1.5}
                            textTransform="uppercase"
                            variant="strong1"
                        >
                            {t('Miscellaneous items')}
                        </Typography>
                        <MiscItemListActions />
                        <MiscellaneousItemList />
                    </Box> : null}

                {!isQuoteEditable && !isLoadingItems && (Boolean(quoteItems?.length) || Boolean(miscItems?.length)) ? <QuoteReview /> : null}

                {!isLoadingItems && (Boolean(quoteItems?.length) || Boolean(miscItems?.length)) ? <QuoteSummary /> : null}
            </Box>
        )
    }
}

export default memo(Quote)
