import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { blueGrey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import { Box } from '@mui/system'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { useGetQuoteQuery } from '@/app/services/quotes'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { QuoteStatus } from '@/common/utils/Constants/Constants'

import QuotePaidStatusChip from '../common/QuotePaidStatusChip'
import QuoteSourceChip from '../common/QuoteSourceChip'
import QuoteStatusChip from '../common/QuoteStatusChip'
import QuoteTaxRateChip from '../common/QuoteTaxRateChip'

import QuoteCustomerContact from './QuoteCustomerContact'
import QuoteDetailsOptions from './QuoteDetailsOptions'
import QuoteNotes from './QuoteNotes'
import QuotePriceAdjustment from './QuotePriceAdjustment'

const classes = {
    content: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        width: '100%',
        marginBottom: 3,
        padding: 3,
    },

    divider: {
        borderStyle: 'dashed',
        borderColor: blueGrey[200],
    },
}
const QuoteHeader = () => {
    const { quoteId } = useParams()
    const { showMultipleTaxRates, showPayments } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)

    const { data: quote, refetch } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { data: quoteItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const { data: taxRates } = useGetTaxRatesQuery({ organisationId, params: { includeDeleted: true } })

    const hasPartsFromLibrary = useMemo(() => {
        return quoteItems?.some((item) => item?.partLibraryEntryId)
    }, [quoteItems])

    const quoteStatus = quote?.status
    const quoteSource = quote?.quoteSourceType
    const quoteTaxRate = taxRates?.find((taxRate) => taxRate.id === quote?.taxRateId) ?? null

    const isEditable = useMemo(() => {
        return [QuoteStatus.NotCalculated, QuoteStatus.Calculated].includes(quoteStatus)
    }, [quote?.status])

    const isDraft = useMemo(() => {
        return [QuoteStatus.NotCalculated, QuoteStatus.Calculated, QuoteStatus.Draft].includes(quoteStatus)
    }, [quote?.status])

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={3}
            sx={classes.content}
        >
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Box
                    display="flex"
                    gap={1}
                >
                    <QuoteStatusChip quoteStatus={quoteStatus} />
                    <QuoteSourceChip quoteSource={quoteSource} />
                    {showMultipleTaxRates && !isEditable && quoteTaxRate ? (
                        <QuoteTaxRateChip quoteTaxRate={quoteTaxRate} />
                    ) : null}
                    {showPayments && !isEditable ? <QuotePaidStatusChip paymentStatus={quote?.paymentStatus} /> : null}
                </Box>

                <Box
                    display="flex"
                    gap={1}
                >
                    {isDraft ? (
                        <QuotePriceAdjustment
                            quote={quote}
                            quoteItems={quoteItems}
                        />
                    ) : null}
                    {!paidFeatures?.isFreePlan ? <QuoteNotes /> : null}
                </Box>
            </Box>

            <Box
                alignItems="center"
                display="flex"
                gap={3}
            >
                <QuoteCustomerContact
                    hasPartsFromLibrary={hasPartsFromLibrary}
                    quote={quote}
                    refetch={refetch}
                />

                <Divider
                    orientation="vertical"
                    sx={classes.divider}
                    flexItem
                />

                <QuoteDetailsOptions quote={quote} />
            </Box>
        </Box>
    )
}

export default memo(QuoteHeader)
