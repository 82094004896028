import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useGetQuoteQuery } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarMessages from '@/common/components/TbxToolbar/TbxToolbarMessages'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'
import { QuoteStatus } from '@/common/utils'

import DownloadDocuments from '../DownloadDocuments/DownloadDocuments'

import ActionsButtonGroup from './ActionsButtonGroup'
import AddPartsButtonGroup from './AddPartsButtonGroup'

const QuoteToolbar = ({ hasMiscItems = false, hasQuoteParts = false }) => {
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)

    const { data: quote, isFetching, isLoading } = useGetQuoteQuery({ organisationId, quoteId })

    const isAddPartsDisabled = useMemo(() => {
        return [
            QuoteStatus.Draft,
            QuoteStatus.Issued,
            QuoteStatus.Ordered,
            QuoteStatus.Invoiced,
            QuoteStatus.Cancelled,
            QuoteStatus.PendingOrderConfirmation,
            QuoteStatus.Rejected,
            QuoteStatus.Voided,
        ].includes(quote?.status)
    }, [quote?.status])

    return (
        <TbxToolbar>
            <TbxToolbarTitle></TbxToolbarTitle>
            <TbxToolbarMessages />
            <TbxToolbarActions>
                {hasQuoteParts || hasMiscItems || isAddPartsDisabled ? <ActionsButtonGroup /> : null}

                {isAddPartsDisabled ? (
                    <DownloadDocuments
                        data-testid="documents-button"
                        disabled={isLoading || isFetching}
                        quote={quote}
                        disableElevation
                    />
                ) : null}

                {!isAddPartsDisabled ? <AddPartsButtonGroup /> : null}
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

QuoteToolbar.propTypes = {
    hasMiscItems: PropTypes.bool,
    hasQuoteParts: PropTypes.bool,
}

export default memo(QuoteToolbar)
