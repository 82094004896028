import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CssBaseline, LinearProgress, ThemeProvider } from '@mui/material'
import saveAs from 'file-saver'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useLazyGetCustomerQuery } from '@/app/services/customers'
import { useCreateStripeCheckoutSessionMutation } from '@/app/services/integrations'
import { useLazyGetOrganisationQuery, useLazyGetSharedOrganisationQuery } from '@/app/services/organisation'
import { useLazyGetIssuesQuery, useLazyGetQuoteQuery, useLazyGetSharedQuoteQuery } from '@/app/services/quotes'
import { useLazyGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { AlertDialog } from '@/common/components'
import DocumentsAppBar from '@/common/components/MainAppBar/DocumentsAppBar'
import { extractCSSFromDOM } from '@/common/components/PDFExporter/PDFExporterHelpers'
import { useOnAllImagesLoaded, useToolBoxTreatments } from '@/common/hooks'
import PdfService from '@/common/services/PdfService'
import { theme as lightTheme } from '@/common/themes/LightTheme'
import { PrintDocumentsOverrides } from '@/common/themes/overrides/PrintDocumentsOverrides'
import { StrikerThemeOverride } from '@/common/themes/overrides/StrikerThemeOverride'
import { WhitelabelThemeOverride } from '@/common/themes/overrides/WhitelabelThemeOverride'
import { Constants, Paths } from '@/common/utils'

import PrivateDocuments from './PrivateDocuments'
import PublicDocuments from './PublicDocuments'

const classes = {
    loadingMessage: {
        fontFamily: 'fontFamily',
        fontSize: 'body2.fontSize',
        maxWidth: 500,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        margin: '48px auto',
    },
}

const Documents = ({ isPublicDocument = false }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { action, documentType, quoteId } = useParams()

    const organisationFromContext = useSelector(selectOrganisation)
    const imageWrapperRef = useRef(null)
    const { isImageLoading } = useOnAllImagesLoaded(imageWrapperRef)

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isInitiatingPayment, setIsInitiatingPayment] = useState(false)
    const [quote, setQuote] = useState(null)
    const [customer, setCustomer] = useState()
    const [customerContact, setCustomerContact] = useState(null)
    const [taxRate, setTaxRate] = useState(null)
    const [showErrorLoadingDialog, setShowErrorLoadingDialog] = useState(false)
    const [organisation, setOrganisation] = useState(organisationFromContext)
    const [isExporting, setIsExporting] = useState(false)
    const [isAutoExportDone, setIsAutoExportDone] = useState(false)
    const [issueSeverityDictionary, setIssueSeverityDictionary] = useState(null)
    const { showLibellulaWhitelabelling, showStrikerWhitelabelling } = useToolBoxTreatments()

    const [getOrganisation] = useLazyGetOrganisationQuery()
    const [getSharedOrganisation] = useLazyGetSharedOrganisationQuery()
    const [getCustomer] = useLazyGetCustomerQuery()
    const [getQuote] = useLazyGetQuoteQuery()
    const [getSharedQuote] = useLazyGetSharedQuoteQuery()
    const [getIssues] = useLazyGetIssuesQuery()
    const [getTaxRates] = useLazyGetTaxRatesQuery()
    const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation()

    const theme = useMemo(
        () =>
            PrintDocumentsOverrides(
                showLibellulaWhitelabelling
                    ? WhitelabelThemeOverride(lightTheme)
                    : showStrikerWhitelabelling
                      ? StrikerThemeOverride(lightTheme)
                      : lightTheme
            ),
        [showLibellulaWhitelabelling, showStrikerWhitelabelling]
    )

    const getQuoteQuery = useCallback(
        (organisationId, quoteId, params) =>
            isPublicDocument ? getSharedQuote({ quoteId, params }) : getQuote({ organisationId, quoteId, params }),
        [isPublicDocument]
    )

    const getOrganisationQuery = useCallback(
        (organisationId) =>
            isPublicDocument ? getSharedOrganisation({ organisationId }) : getOrganisation({ organisationId }),
        [isPublicDocument]
    )

    const handleErrorAlertClose = () => {
        setShowErrorLoadingDialog(false)
    }

    const exportPdf = useCallback(
        async (closeWindow) => {
            if (documentType === 'work-order' || documentType === 'production-labels') {
                window.print()

                // if (closeWindow) {
                //     setTimeout(function () {
                //         window.close()
                //     }, 1000)
                // }
                return
            }
            setIsExporting(true)
            const content = document.getElementById(Constants.EXPORTABLE_CONTENT)
            const htmlBody = content.outerHTML
            const cssContent = extractCSSFromDOM()

            try {
                const data = await PdfService.getPdf(quote?.name, `<style>${cssContent}</style>`, htmlBody)
                saveAs(data, `${quote?.name}-${documentType}.pdf`, { autoBom: true })
            } finally {
                setIsExporting(false)

                // if (closeWindow) {
                //     setTimeout(function () {
                //         window.close()
                //     }, 1000)
                // }
            }
        },
        [quote]
    )

    const handleGetIssues = async () => {
        const issues = await getIssues({ quoteId }).unwrap()
        setIssueSeverityDictionary(issues)
    }

    const handlePaymentButton = useCallback(async () => {
        setIsInitiatingPayment(true)
        try {
            const stripeCheckoutSessionUrl = await createStripeCheckoutSession({
                organisationId: quote.organisationId,
                quoteId: quoteId,
                successUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.PAYMENTS_PATHNAME}/${quoteId}/${Paths.PAYMENTS_SUCCESS_PATHNAME}`,
                cancelUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quoteId}/${documentType}`,
            }).unwrap()

            enqueueSnackbar('Redirecting to stripe...', {
                variant: 'info',
                onExited: () => {
                    window.open(stripeCheckoutSessionUrl, '_self')
                    setIsInitiatingPayment(false)
                },
            })
        } catch (error) {
            console.error('Documents', 'Payment Button Error', error)
            enqueueSnackbar('Error initiating payment', {
                variant: 'error',
            })
        }
    }, [createStripeCheckoutSession, documentType, enqueueSnackbar, quote, quoteId])

    useEffect(() => {
        const documentTypes = isPublicDocument
            ? Object.values(Constants.PUBLIC_DOCUMENTS)
            : Object.values(Constants.PRIVATE_DOCUMENTS)

        if (!documentTypes.includes(documentType)) {
            navigate(Paths.NOT_FOUND)
        }
    }, [documentType])

    useEffect(() => {
        let mounted = true
        setIsLoading(true)

        const fetchAsync = async () => {
            try {
                const quoteResponse = await getQuoteQuery(organisation?.organisationId, quoteId, {
                    includeItems: true,
                }).unwrap()

                if (!organisation || organisation.organisationId !== quoteResponse?.organisationId) {
                    const organisationResponse = await getOrganisationQuery(quoteResponse?.organisationId).unwrap()
                    setOrganisation(organisationResponse)
                }

                if (quoteResponse?.customerContact?.customerId) {
                    try {
                        const customerResponse = await getCustomer({
                            organisationId: quoteResponse.organisationId,
                            customerId: quoteResponse.customerContact?.customerId,
                        }).unwrap()
                        const contactFound = customerResponse.contacts.find(
                            (c) => c.contactId === quoteResponse.customerContactId
                        )
                        setCustomerContact(contactFound)
                        setCustomer(customerResponse)
                    } catch {
                        //
                    }
                }

                if (quoteResponse?.taxRateId) {
                    try {
                        const taxRatesResponse = await getTaxRates({
                            organisationId: quoteResponse.organisationId,
                            params: { includeDeleted: true },
                        }).unwrap()

                        const taxRateFound = taxRatesResponse.find((taxRate) => taxRate.id === quoteResponse.taxRateId)
                        setTaxRate(taxRateFound)
                    } catch (error) {
                        //
                    }
                }

                setQuote(quoteResponse)
            } catch (error) {
                if (mounted) {
                    setIsLoading(false)
                    setShowErrorLoadingDialog(true)
                }

                navigate(Paths.NOT_FOUND)
            } finally {
                setIsLoading(false)
            }

            return () => {
                mounted = false
            }
        }

        fetchAsync()
    }, [quoteId, navigate, organisation?.organisationId])

    useEffect(() => {
        if (!issueSeverityDictionary) {
            handleGetIssues()
        }
    }, [issueSeverityDictionary])

    useEffect(() => {
        if (action === 'export' && quote && !isLoading && !isImageLoading && !isAutoExportDone) {
            exportPdf(true)
            setIsAutoExportDone(true)
        }
    }, [action, quote, exportPdf, isLoading, isImageLoading, isAutoExportDone])

    return !isLoading && !_.isEmpty(quote) && organisation ? (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <DocumentsAppBar
                buttonLabel={
                    documentType === 'work-order' || documentType === 'production-labels'
                        ? 'Print'
                        : isImageLoading
                          ? 'Loading document...'
                          : 'Export'
                }
                disabled={isImageLoading}
                handleExportButton={exportPdf}
                handlePaymentButton={handlePaymentButton}
                isExporting={isExporting}
                isInitiatingPayment={isInitiatingPayment}
                isPublicDocument={isPublicDocument}
                paymentsEnabled={organisation.paymentsEnabledInternal}
                paymentStatus={quote?.paymentStatus}
                quoteStatus={quote?.status}
            />
            <div
                id="image-wrapper"
                ref={imageWrapperRef}
            >
                {isPublicDocument ? (
                    <PublicDocuments
                        customer={customer}
                        customerContact={customerContact}
                        documentType={documentType}
                        issueSeverityDictionary={issueSeverityDictionary}
                        organisation={organisation}
                        paymentsEnabled={organisation.paymentsEnabledInternal}
                        quote={quote}
                        taxRate={taxRate}
                    />
                ) : (
                    <PrivateDocuments
                        customer={customer}
                        customerContact={customerContact}
                        documentType={documentType}
                        issueSeverityDictionary={issueSeverityDictionary}
                        organisation={organisation}
                        quote={quote}
                    />
                )}
            </div>
            <AlertDialog
                content={
                    t('$t(An error occurred) loading this $t(quote)') +
                    '. ' +
                    t('You will be redirected to the $t(quotes) page')
                }
                hideCancelButton={true}
                open={showErrorLoadingDialog}
                title={t('Error')}
                onOkClose={handleErrorAlertClose}
            />
        </ThemeProvider>
    ) : (
        <Box sx={classes.loadingMessage}>
            {t('We are loading your data. Please, wait.')}
            <LinearProgress style={{ marginTop: 50 }} />
        </Box>
    )
}

Documents.propTypes = {
    isPublicDocument: PropTypes.bool,
}

export default Documents
