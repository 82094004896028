import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

import { getStatusLabel, QuoteStatus } from '@/common/utils'

const statusChipColor = (status) => {
    switch (status) {
        case (status = QuoteStatus.NotCalculated):
            return 'text.secondary'
        case (status = QuoteStatus.Calculated):
            return 'text.secondary'
        case (status = QuoteStatus.Draft):
            return 'warning.main'
        case (status = QuoteStatus.PendingOrderConfirmation):
            return 'info.dark'
        case (status = QuoteStatus.Issued):
            return 'info.main'
        case (status = QuoteStatus.Ordered):
            return 'success.light'
        case (status = QuoteStatus.Invoiced):
            return 'success.main'
        case (status = QuoteStatus.Dispatched):
            return 'success.dark'
        case (status = QuoteStatus.Lost):
            return 'error.light'
        case (status = QuoteStatus.Cancelled):
            return 'error.main'
        case (status = QuoteStatus.Rejected):
            return 'error.dark'
        case (status = QuoteStatus.Voided):
            return 'error.dark'
        default:
            return 'secondary'
    }
}
const QuoteStatusChip = ({ quoteStatus }) => {
    const { t } = useTranslation()

    return (
        <Chip
            label={t(getStatusLabel(quoteStatus))}
            size="small"
            sx={{
                textTransform: 'uppercase',
                fontSize: '11px',
                backgroundColor: statusChipColor(quoteStatus),
                color: 'white',
                userSelect: 'none',
            }}
            title={t(getStatusLabel(quoteStatus))}
        />
    )
}

QuoteStatusChip.propTypes = {
    quoteStatus: PropTypes.string,
}

export default QuoteStatusChip
