import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetMaterialsQuery } from '@/app/services/materials'
import { useCalculateQuoteMutation, useUpdateQuoteMutation } from '@/app/services/quotes'
import { useGetSheetsQuery } from '@/app/services/sheets'
import { selectLocale } from '@/app/slices/appSlice'
import {
    selectCurrencySymbol,
    selectOrganisation,
    selectOrganisationId,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { Dialog } from '@/common/components'
import CurrencyInput from '@/common/components/CurrencyInput/CurrencyInput'
import SetupModeSelect from '@/common/components/SetupModeSelect/SetupModeSelect'
import SheetChangeModeSelect from '@/common/components/SheetChangeModeSelect/SheetChangeModeSelect'
import { useCurrencyFormatter, useNumberFormatter } from '@/common/hooks'
import { organisationLengthPrecision, QuoteStatus } from '@/common/utils'

const classes = {
    dialog: {
        // width: 900,
    },
    inputLabel: {
        width: 200,
    },
    input: {
        width: 100,
    },
    formInput: {
        width: 'auto',
    },
    table: {
        tableLayout: 'fixed',
        border: '1px solid rgba(52, 73, 94, 0.12)',
        boxSizing: 'border-box',
        borderRadius: '6px',
        borderCollapse: 'separate',
        '& tr:last-child td': {
            borderBottom: 'none',
        },
    },
    headerTableCell: {
        padding: '5px 10px',
        verticalAlign: 'bottom',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        // alignItems: 'center',
        color: '#9FA2B4',
        wordBreak: 'break-word',
    },
    headerTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerTitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },
    headerSubtitle: {
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
        color: 'text.secondary',
    },
    cell: {
        fontFamily: 'roboto',
        padding: '5px 10px',
        color: 'text.primary',
    },
    titleSection: {
        display: 'flex',
        marginBottom: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    overrides: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '24px',
    },
}

function getHourlyRate(quoteItem) {
    return (quoteItem?.cuttingPriceUnroundedPerItem / quoteItem?.runtime) * 3600
}

function getTotalRuntime(quoteItem) {
    return quoteItem?.runtime * quoteItem?.quantity
}

const PriceSettingsDialog = ({ onClose, open, quote, quoteItems }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)

    const { data: materials } = useGetMaterialsQuery({ organisationId })
    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: sheets } = useGetSheetsQuery({ organisationId })

    const [updateQuote, { isLoading: isUpdating }] = useUpdateQuoteMutation()
    const [calculateQuote, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const dispatch = useDispatch()

    const quoteName = quote?.name
    const quoteId = quote?.id

    const [cuttingTechnologyOverrides, setCuttingTechnologyOverrides] = useState(null)
    const [initialCuttingTechnologyOverrides, setInitialCuttingTechnologyOverrides] = useState(null)
    const [sheetOverrides, setSheetOverrides] = useState(null)
    const [initialSheetOverrides, setInitialSheetOverrides] = useState(null)

    const isCuttingTechnologyOverridesChanged = () => {
        return !_.isEqual(cuttingTechnologyOverrides, initialCuttingTechnologyOverrides)
    }

    const isSheetOverridesChanged = () => {
        return !_.isEqual(sheetOverrides, initialSheetOverrides)
    }

    const { formatCurrency } = useCurrencyFormatter({
        locale,
        currencyCode: organisation.currencyCode,
    })

    const { n: dimensionFormat } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: organisation ? organisationLengthPrecision(organisation) : 2,
    })

    const handlePriceChanges = async () => {
        const data = {
            quoteId: quote?.id,
            cuttingTechnologyOverrides: Object.values(cuttingTechnologyOverrides),
            sheetOverrides: Object.values(sheetOverrides),
        }

        try {
            const updateResponse = await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    id: quoteId,
                    organisationId: organisationId,
                    name: quote?.name,
                    quoteOverrides: data,
                },
            }).unwrap()

            if (updateResponse) {
                await calculateQuote({ organisationId, quoteId }).unwrap()
                dispatch(setSelectedQuoteStatus(updateResponse.status))
            }
        } catch (error) {
            const errorMessage = t('$t(An error occurred) changing $t(quote) status.')
            enqueueSnackbar(errorMessage, { variant: 'error' })
            dispatch(setSelectedQuoteStatus(quote.status))
        } finally {
            onClose()
        }
    }

    const handleCancelClick = (_e) => {
        setCuttingTechnologyOverrides(structuredClone(initialCuttingTechnologyOverrides))
        setSheetOverrides(structuredClone(initialSheetOverrides))
        onClose()
    }

    const renderColumnHeader = (columnTitle, columnSubtitle) => (
        <Box sx={classes.headerTextContainer}>
            <Typography sx={classes.headerTitle}>{columnTitle}</Typography>
            {columnSubtitle ? <Typography sx={classes.headerSubtitle}>{columnSubtitle}</Typography> : null}
            {!columnSubtitle ? <Typography sx={classes.headerSubtitle}>&nbsp;</Typography> : null}
        </Box>
    )

    const handleSetupModeChanged = (cuttingTechnologyId, newSetupMode) => {
        cuttingTechnologyOverrides[cuttingTechnologyId].setupMode = newSetupMode
        setCuttingTechnologyOverrides({ ...cuttingTechnologyOverrides })
    }

    const handleSheetChangeModeChanged = (cuttingTechnologyId, newSheetChangeMode) => {
        cuttingTechnologyOverrides[cuttingTechnologyId].sheetChangeMode = newSheetChangeMode
        setCuttingTechnologyOverrides({ ...cuttingTechnologyOverrides })
    }

    const handleCuttingHourlyRateAdjustmentChanged = (cuttingTechnologyId, newRate) => {
        cuttingTechnologyOverrides[cuttingTechnologyId].extraHourlyRatePrice = newRate
        setCuttingTechnologyOverrides({ ...cuttingTechnologyOverrides })
    }

    const getAppliedCuttingHourlyRate = (cto) => {
        return cto.averageWeightedHourlyRate + (cto?.extraHourlyRatePrice ?? 0)
    }

    const handleSheetPriceAdjustmentChanged = (sheetId, newRate) => {
        sheetOverrides[sheetId].extraSheetPrice = newRate
        setSheetOverrides({ ...sheetOverrides })
    }

    const getAppliedSheetPrice = (sheetPrice, extraSheetPrice) => {
        return (sheetPrice ?? 0) + (extraSheetPrice ?? 0)
    }

    const sheetSize = (sheetHeight, sheetWidth) => {
        let resultSize = ''
        if (sheetHeight > sheetWidth) {
            resultSize = dimensionFormat(sheetHeight) + ' x ' + dimensionFormat(sheetWidth)
        } else {
            resultSize = dimensionFormat(sheetWidth) + ' x ' + dimensionFormat(sheetHeight)
        }
        return resultSize
    }

    const rotarySize = (part) => {
        return `${dimensionFormat(part.sheetWidth)} x ${dimensionFormat(part.sheetHeight)} x ${dimensionFormat(
            part.materialLength
        )}`
    }

    // Cutting and Material price adjustments Overrides
    useEffect(() => {
        const cuttingTechMap = {}
        if (quoteItems && cuttingTechnologies) {
            quoteItems.forEach((quoteItem) => {
                const { cuttingPriceUnroundedPerItem, cuttingTechnologyId, runtime } = quoteItem

                const totalRuntime = getTotalRuntime(quoteItem)
                const hourlyRate = getHourlyRate(quoteItem)
                if (cuttingTechnologies[cuttingTechnologyId]) {
                    const { name, setupMode, sheetChangeMode } = cuttingTechnologies[cuttingTechnologyId]

                    if (!cuttingTechMap[cuttingTechnologyId]) {
                        cuttingTechMap[cuttingTechnologyId] = {
                            cuttingTechnologyId,
                            name,
                            setupMode,
                            sheetChangeMode,
                            cuttingTimePerItem: runtime,
                            totalCuttingTimePerLine: totalRuntime,
                            totalHourlyRateTimesTotalRuntime: hourlyRate * totalRuntime,
                            cuttingPriceUnroundedPerItem,
                            extraHourlyRatePrice: 0,
                        }
                    } else {
                        cuttingTechMap[cuttingTechnologyId].cuttingTimePerItem += runtime
                        cuttingTechMap[cuttingTechnologyId].totalCuttingTimePerLine += totalRuntime
                        cuttingTechMap[cuttingTechnologyId].totalHourlyRateTimesTotalRuntime +=
                            hourlyRate * totalRuntime
                        cuttingTechMap[cuttingTechnologyId].cuttingPriceUnroundedPerItem += cuttingPriceUnroundedPerItem
                    }
                }
            })
        }

        Object.values(cuttingTechMap).forEach((item) => {
            item.averageWeightedHourlyRate = item.totalHourlyRateTimesTotalRuntime / item.totalCuttingTimePerLine
        })

        const sheetMap = {}

        if (quoteItems && sheets) {
            quoteItems.forEach((quoteItem) => {
                const { sheetId, sheetPriceUnrounded } = quoteItem
                const sheet = sheets[sheetId]
                if (sheet) {
                    const { materialId, materialLength, profile, sheetCost, sheetHeight, sheetWidth, thickness } = sheet
                    const material = materials[materialId]
                    const { materialName } = material
                    if (!sheetMap[quoteItem?.sheetId]) {
                        sheetMap[quoteItem?.sheetId] = {
                            sheetId,
                            materialId,
                            materialName,
                            thickness,
                            sheetHeight,
                            sheetWidth,
                            sheetCost,
                            profile,
                            materialLength,
                            sheetPrice: sheetPriceUnrounded,
                            rotaryProfileWidth: sheetWidth,
                            rotaryProfileLength: sheetHeight,
                            extraSheetPrice: 0,
                        }
                    }
                }
            })
        }

        if (quote?.quoteOverrides) {
            quote.quoteOverrides.cuttingTechnologyOverrides.forEach((cuttingTechOverride) => {
                const { cuttingTechnologyId, extraHourlyRatePrice, setupMode, sheetChangeMode } = cuttingTechOverride

                const item = cuttingTechMap[cuttingTechnologyId]
                if (item) {
                    item.setupMode = setupMode
                    item.sheetChangeMode = sheetChangeMode
                    item.extraHourlyRatePrice = extraHourlyRatePrice || 0
                    item.averageWeightedHourlyRate -= cuttingTechOverride.extraHourlyRatePrice || 0
                }
            })

            quote?.quoteOverrides.sheetOverrides.forEach((sheetOverride) => {
                if (sheetMap[sheetOverride.sheetId]) {
                    sheetMap[sheetOverride.sheetId].extraSheetPrice = sheetOverride.extraSheetPrice
                }
            })
        }

        setInitialCuttingTechnologyOverrides(structuredClone(cuttingTechMap))
        setCuttingTechnologyOverrides(cuttingTechMap)

        setInitialSheetOverrides(structuredClone(sheetMap))
        setSheetOverrides(sheetMap)
    }, [open])

    return (
        <Dialog
            actions={[
                <Button
                    color="secondary"
                    data-testid="priceAdjustmentCancel"
                    disabled={isCalculating || isUpdating}
                    key="cancel"
                    variant="outlined"
                    onClick={handleCancelClick}
                >
                    {t('Cancel')}
                </Button>,
                <Button
                    color="primary"
                    data-testid="priceAdjustmentRecalculate"
                    disabled={
                        isCalculating ||
                        isUpdating ||
                        (!isCuttingTechnologyOverridesChanged() && !isSheetOverridesChanged())
                    }
                    key="recalculate"
                    variant="contained"
                    onClick={handlePriceChanges}
                >
                    {isCalculating ? t('Recalculating...') : t('Recalculate')}
                </Button>,
            ]}
            actionsPosition="end"
            className={classes.dialog}
            fullWidth={true}
            maxWidth="lg"
            open={open}
            PaperProps={{
                'data-testid': 'quotePriceAdjustments',
            }}
            showCloseButton={false}
            title={t('Price adjustments for quote: {{quoteName}}', { quoteName })}
        >
            <div
                data-testid="quotePriceAdjustments"
                style={{ marginBottom: '10px' }}
            >
                <Box sx={classes.titleSection}>
                    <Typography variant="h6">{t('Cutting price adjustments')}</Typography>
                </Box>
                <Box
                    style={{ marginBottom: '50px' }}
                    sx={classes.overrides}
                >
                    <Table
                        data-testid="cuttingPriceAdjustment"
                        sx={classes.table}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    data-testid="cuttingPriceAdjustmentCuttingTechnology"
                                    style={{ width: '200px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Cutting technology'), null)}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    data-testid="cuttingPriceAdjustmentSetupMode"
                                    style={{ width: '120px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Setup mode'), null)}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    data-testid="cuttingPriceAdjustmentSheetChangeMode"
                                    style={{ width: '120px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Sheet change mode'), null)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="cuttingPriceAdjustmentAverageCuttingHourlyRate"
                                    style={{ width: '120px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Average cutting hourly rate'), currencySymbol + '/hr')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="cuttingPriceAdjustmentCuttingHourlyRateAdjustment"
                                    style={{ width: '120px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Cutting hourly rate adjustment'), currencySymbol + '/hr')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="cuttingPriceAdjustmentAppliedCuttingHourlyRate"
                                    style={{ width: '120px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Applied cutting hourly rate'), currencySymbol + '/hr')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cuttingTechnologyOverrides
                                ? Object.values(cuttingTechnologyOverrides)?.map((cto, index) => (
                                      <TableRow key={`${cuttingTechnologyOverrides.cuttingTechnologyId}-${index}`}>
                                          <TableCell
                                              align="left"
                                              style={{ width: '150px' }}
                                              sx={classes.cell}
                                          >
                                              {cto?.name ?? ''}
                                          </TableCell>
                                          <TableCell
                                              align="left"
                                              style={{ width: '120px' }}
                                              sx={classes.cell}
                                          >
                                              {
                                                  <SetupModeSelect
                                                      fullWidth={false}
                                                      includeNoneOption={true}
                                                      inputProps={{
                                                          'data-testid': 'setupMode',
                                                          sx: classes.input,
                                                      }}
                                                      labelText={''}
                                                      value={cto?.setupMode ?? ''}
                                                      onChange={(value) =>
                                                          handleSetupModeChanged(cto.cuttingTechnologyId, value)
                                                      }
                                                  />
                                              }
                                          </TableCell>
                                          <TableCell
                                              align="left"
                                              style={{ width: '120px' }}
                                              sx={classes.cell}
                                          >
                                              {
                                                  <SheetChangeModeSelect
                                                      fullWidth={false}
                                                      includeNoneOption={true}
                                                      inputProps={{
                                                          'data-testid': 'sheetChangeMode',
                                                          sx: classes.input,
                                                      }}
                                                      labelText={t('')}
                                                      value={cto?.sheetChangeMode ?? ''}
                                                      onChange={(value) =>
                                                          handleSheetChangeModeChanged(cto.cuttingTechnologyId, value)
                                                      }
                                                  />
                                              }
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '120px' }}
                                              sx={classes.cell}
                                          >
                                              {formatCurrency(cto.averageWeightedHourlyRate)}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '120px', float: 'right' }}
                                              sx={classes.cell}
                                          >
                                              {
                                                  <CurrencyInput
                                                      allowNegativeValues={true}
                                                      fullWidth={true}
                                                      inputProps={{
                                                          'data-testid': 'quoteCuttingHourlyRateAdjustment',
                                                          sx: classes.input,
                                                      }}
                                                      labelText={t('')}
                                                      showErrorHelperText={true}
                                                      textAlign="right"
                                                      value={
                                                          !isNaN(cto?.extraHourlyRatePrice)
                                                              ? cto?.extraHourlyRatePrice
                                                              : ''
                                                      }
                                                      onChange={(value) =>
                                                          handleCuttingHourlyRateAdjustmentChanged(
                                                              cto.cuttingTechnologyId,
                                                              value
                                                          )
                                                      }
                                                  />
                                              }
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '120px' }}
                                              sx={classes.cell}
                                          >
                                              {formatCurrency(getAppliedCuttingHourlyRate(cto))}
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : undefined}
                        </TableBody>
                    </Table>
                </Box>
                <Box sx={classes.titleSection}>
                    <Typography variant="h6">{t('Material price adjustments')}</Typography>
                </Box>
                <Box sx={classes.overrides}>
                    <Table
                        data-testid="materialPriceAdjustment"
                        sx={classes.table}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    data-testid="materialPriceAdjustmentMaterial"
                                    style={{ width: '150px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Material'), null)}
                                </TableCell>
                                <TableCell
                                    data-testid="materialPriceAdjustmentProfile"
                                    style={{ width: '90px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Profile'), null)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentThickness"
                                    style={{ width: '90px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Thickness'), useImperialUnits ? 'in' : 'mm')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentSheetSize"
                                    style={{ width: '100px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Material size'), useImperialUnits ? 'in' : 'mm')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentSheetCost"
                                    style={{ width: '100px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Material unit cost'), currencySymbol)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentSheetPrice"
                                    style={{ width: '110px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Material unit sell price (after mark-ups)'), currencySymbol)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentSheetPriceAdjustment"
                                    style={{ width: '100px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Material unit sell price adjustment'), currencySymbol)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    data-testid="materialPriceAdjustmentAppliedSheetPrice"
                                    style={{ width: '100px' }}
                                    sx={classes.headerTableCell}
                                >
                                    {renderColumnHeader(t('Applied Material unit sell price'), currencySymbol)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sheetOverrides
                                ? Object.values(sheetOverrides)?.map((sheetRow) => (
                                      <TableRow key={sheetRow.sheetId}>
                                          <TableCell
                                              style={{ width: '150px' }}
                                              sx={classes.cell}
                                          >
                                              {sheetRow?.materialName ?? ''}
                                          </TableCell>
                                          <TableCell
                                              style={{ width: '150px' }}
                                              sx={classes.cell}
                                          >
                                              {sheetRow?.profile ?? 'Flat'}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '90px' }}
                                              sx={classes.cell}
                                          >
                                              {sheetRow?.thickness ?? ''}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '100px' }}
                                              sx={classes.cell}
                                          >
                                              {sheetRow.profile
                                                  ? rotarySize(sheetRow)
                                                  : sheetSize(sheetRow?.sheetHeight, sheetRow?.sheetWidth)}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '100px' }}
                                              sx={classes.cell}
                                          >
                                              {formatCurrency(sheetRow.sheetCost)}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '100px' }}
                                              sx={classes.cell}
                                          >
                                              {formatCurrency(sheetRow?.sheetPrice)}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '110px', float: 'right' }}
                                              sx={classes.cell}
                                          >
                                              {
                                                  <CurrencyInput
                                                      allowNegativeValues={true}
                                                      fullWidth={true}
                                                      inputProps={{
                                                          'data-testid': 'quoteSheetPriceAdjustment',
                                                          sx: classes.input,
                                                      }}
                                                      labelText={t('')}
                                                      showErrorHelperText={true}
                                                      textAlign="right"
                                                      value={sheetRow?.extraSheetPrice ?? ''}
                                                      onChange={(value) =>
                                                          handleSheetPriceAdjustmentChanged(sheetRow.sheetId, value)
                                                      }
                                                  />
                                              }
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              style={{ width: '100px' }}
                                              sx={classes.cell}
                                          >
                                              {formatCurrency(
                                                  getAppliedSheetPrice(sheetRow.sheetPrice, sheetRow.extraSheetPrice)
                                              )}
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : undefined}
                        </TableBody>
                    </Table>
                </Box>
            </div>
        </Dialog>
    )
}

PriceSettingsDialog.propTypes = {
    open: PropTypes.bool,
    quote: PropTypes.object,
    quoteItems: PropTypes.array,
    onClose: PropTypes.func,
}

export default PriceSettingsDialog
