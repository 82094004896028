import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import {
    AccountBalance,
    AccountBalanceWalletRounded,
    AccountCircle,
    BusinessRounded,
    LocalShippingRounded,
    MergeTypeRounded,
    OpenInNewRounded,
    ReceiptRounded,
    SpeakerNotesRounded,
    StoreRounded,
    SupervisorAccountRounded,
} from '@mui/icons-material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/system'

import { selectIsCurrentUserAdmin, selectIsCurrentUserOwner, selectUserRole } from '@/app/slices/appSlice'
import { selectOrganisation, selectResolvedPlanMetadata } from '@/app/slices/organisationSlice'
import ScrollToAnchor from '@/common/components/ScrollToAnchor/ScrollToAnchor'
import { useToolBoxTreatments } from '@/common/hooks'
import { LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const classes = {
    paper: {
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: '8px',
        position: 'sticky',
        top: 'calc(64px + 24px)',
    },

    icon: {
        minWidth: 36,
    },

    iconColor: {
        color: (theme) => theme.palette.text.secondary,
    },

    selected: {
        color: (theme) => theme.palette.primary.main,
        fontWeight: 'bold',
    },

    selectedItemButton: {
        '&.Mui-selected': {
            backgroundColor: 'transparent',
        },

        '&.Mui-selected .MuiListItemIcon-root .MuiSvgIcon-root': {
            color: (theme) => theme.palette.primary.main,
        },
    },
}

const Settings = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { showIntegrations, showLibellulaWhitelabelling, showPayments, showWebStore } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const resolvedPlanMetadata = useSelector(selectResolvedPlanMetadata)
    const isUserAdmin = useSelector(selectIsCurrentUserAdmin)
    const isUserOwner = useSelector(selectIsCurrentUserOwner)

    const handleOnChangePlanClick = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    return (
        <Box
            px={3}
            py={3}
            sx={{ flexGrow: 1 }}
        >
            <ScrollToAnchor />
            <Grid
                spacing={3}
                container
            >
                <Grid
                    lg={3}
                    lgOffset={1}
                    md={3}
                    mdOffset={0}
                    xl={2}
                    xlOffset={2.5}
                >
                    <Paper
                        elevation={0}
                        sx={classes.paper}
                    >
                        <List
                            aria-labelledby="settings-list-subheader"
                            component="nav"
                            sx={{ paddingInline: 1 }}
                        >
                            {/* ACCOUNT */}
                            <ListSubheader
                                component="div"
                                data-testid="settings-account-title"
                                id="settings-list-account"
                                sx={location.pathname === '/settings/account' ? classes.selected : null}
                            >
                                {t('Account')}
                            </ListSubheader>

                            <ListItem
                                data-testid="settings-account-link"
                                dense
                            >
                                <ListItemButton
                                    LinkComponent={NavLink}
                                    selected={location.pathname === '/settings/account'}
                                    sx={classes.selectedItemButton}
                                    to="account"
                                >
                                    <ListItemIcon sx={classes.icon}>
                                        <AccountCircle sx={classes.iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Profile')} />
                                </ListItemButton>
                            </ListItem>
                            {/* END-ACCOUNT */}

                            {/* ORGANISATION */}
                            {organisation && (isUserAdmin || isUserOwner) ? (
                                <>
                                    <ListSubheader
                                        component="div"
                                        data-testid="settings-organisation-title"
                                        id="settings-list-organisation"
                                        sx={location.pathname === '/settings/organisation' ? classes.selected : null}
                                    >
                                        {t('Organisation')}
                                    </ListSubheader>

                                    <ListItem
                                        data-testid="settings-organisation-defaults-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/organisation' ? !location.hash : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="organisation"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <BusinessRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Defaults')} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        data-testid="settings-organisation-taxes-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/organisation'
                                                    ? location.hash === '#taxes'
                                                    : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="organisation#taxes"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <AccountBalance sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Taxes')} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        data-testid="settings-organisation-quote-and-documents-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/organisation'
                                                    ? location.hash === '#quote-and-documents'
                                                    : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="organisation#quote-and-documents"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <ReceiptRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Quote')} />
                                        </ListItemButton>
                                    </ListItem>

                                    {!import.meta.env.VITE_RESELLER && showPayments && isUserOwner ? (
                                        <ListItem
                                            data-testid="settings-organisation-payments-link"
                                            dense
                                        >
                                            <ListItemButton
                                                LinkComponent={NavLink}
                                                selected={
                                                    location.pathname === '/settings/organisation'
                                                        ? location.hash === '#payments'
                                                        : null
                                                }
                                                sx={classes.selectedItemButton}
                                                to="organisation#payments"
                                            >
                                                <ListItemIcon sx={classes.icon}>
                                                    <AccountBalanceWalletRounded sx={classes.iconColor} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Payments')} />
                                            </ListItemButton>
                                        </ListItem>
                                    ) : null}

                                    <ListItem
                                        data-testid="settings-organisation-user-management-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/organisation'
                                                    ? location.hash === '#user-management'
                                                    : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="organisation#user-management"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <SupervisorAccountRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('User management')} />
                                        </ListItemButton>
                                    </ListItem>

                                    {!import.meta.env.VITE_RESELLER &&
                                    (showIntegrations || resolvedPlanMetadata?.xero) ? (
                                        <ListItem
                                            data-testid="settings-organisation-integrations-link"
                                            dense
                                        >
                                            <ListItemButton
                                                LinkComponent={NavLink}
                                                selected={
                                                    location.pathname === '/settings/organisation'
                                                        ? location.hash === '#integrations'
                                                        : null
                                                }
                                                sx={classes.selectedItemButton}
                                                to="organisation#integrations"
                                            >
                                                <ListItemIcon sx={classes.icon}>
                                                    <MergeTypeRounded sx={classes.iconColor} />
                                                </ListItemIcon>
                                                <ListItemText primary={t('Integrations')} />
                                            </ListItemButton>
                                        </ListItem>
                                    ) : null}
                                </>
                            ) : null}
                            {/* END-ORGANISATION */}

                            {/* STORE */}
                            {organisation && (isUserAdmin || isUserOwner) && showWebStore ? (
                                <>
                                    <ListSubheader
                                        component="div"
                                        data-testid="settings-webstore-title"
                                        id="settings-list-store"
                                        sx={location.pathname === '/settings/store' ? classes.selected : null}
                                    >
                                        {t('Web Store')}
                                    </ListSubheader>

                                    <ListItem
                                        data-testid="settings-webstore-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={location.pathname === '/settings/store' ? !location.hash : null}
                                            sx={classes.selectedItemButton}
                                            to="store"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <StoreRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Branding')} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        data-testid="settings-webstore-shipping-options-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/store'
                                                    ? location.hash === '#shipping-options'
                                                    : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="store#shipping-options"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <LocalShippingRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Shipping')} />
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            ) : null}
                            {/* END-STORE */}

                            {/* SUBSCRIPTION */}
                            {organisation && isUserOwner && !import.meta.env.VITE_RESELLER ? (
                                <>
                                    <ListSubheader
                                        component="div"
                                        data-testid="settings-subscription-title"
                                        id="settings-list-subscription"
                                        sx={location.pathname === '/settings/subscription' ? classes.selected : null}
                                    >
                                        {t('Subscription')}
                                    </ListSubheader>

                                    <ListItem
                                        data-testid="settings-subscription-manage-subscription-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            selected={
                                                location.pathname === '/settings/subscription'
                                                    ? location.hash === '#manage-subscription'
                                                    : null
                                            }
                                            sx={classes.selectedItemButton}
                                            to="subscription#manage-subscription"
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <OpenInNewRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Manage subscription')} />
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem
                                        data-testid="settings-subscription-billing-history-link"
                                        dense
                                    >
                                        <ListItemButton
                                            LinkComponent={NavLink}
                                            onClick={() => handleOnChangePlanClick()}
                                        >
                                            <ListItemIcon sx={classes.icon}>
                                                <SpeakerNotesRounded sx={classes.iconColor} />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Request new plan')} />
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            ) : null}
                            {/* END-SUBSCRIPTION */}
                        </List>
                    </Paper>
                </Grid>

                <Grid
                    lg={7}
                    md={9}
                    xl={5}
                >
                    <Paper
                        elevation={0}
                        sx={[classes.paper, { height: '100%' }]}
                    >
                        <Outlet />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Settings
