import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { useDeleteQuoteMutation, useGetQuotesByCustomerQuery, useUnarchiveQuoteMutation } from '@/app/services/quotes'
import { selectIsOnFreePlan, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { ErrorSnackbar, SearchTextField, TbxShowToggle } from '@/common/components'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_VARIABLE, QuoteStatus } from '@/common/utils'

import { QuoteHistoryTable } from './QuoteHistoryTable'

const classes = {
    formControl: {
        minHeight: 50,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 2,
    },
    searchBar: {
        display: 'flex',
        width: '100%',
        marginBottom: '24px',
        justifyContent: 'space-between',
    },
    paper: {
        textAlign: 'center',
        color: 'text.secondary',
    },
    tabContainer: {
        marginTop: '24px',
    },
    tableLoader: {
        border: 'none',
        position: 'relative',
        marginBottom: -1,
        height: 8,
        borderRadius: '8px 8px 0 0',
    },
}

const QuoteHistoryTab = ({ customerId }) => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage] = useLocalStorage(PARTS_PER_PAGE_VARIABLE, PARTS_PER_PAGE_DEFAULT_VALUE)
    const [orderBy, setOrderBy] = useState('lastModifiedDateUtc')
    const [order, setOrder] = useState('desc')
    const [quoteStatusFilter, setQuoteStatusFilter] = useState('All')
    const [quoteArchivedFilter, setQuoteArchivedFilter] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const { data, isError, isLoading, refetch } = useGetQuotesByCustomerQuery({
        organisationId,
        params: {
            customerId,
            pageNumber: currentPage,
            pageSize: currentRowsPerPage,
            searchTerm: searchTerm,
            orderedByProperty: orderBy,
            orderedBy: order,
            includeArchived: quoteArchivedFilter,
            status: quoteStatusFilter,
        },
    })

    const [deleteQuote] = useDeleteQuoteMutation()
    const [unarchiveQuote] = useUnarchiveQuoteMutation()

    const quotes = data?.quotes
    const totalQuoteCount = data?.count ?? 0
    const availableStatuses = data?.availableStatuses ?? []

    const handlePageOrderChanged = (currentPage, currentRowsPerPage, order, orderBy) => {
        setCurrentPage(currentPage)
        setOrderBy(orderBy)
        setOrder(order)
        refetch()
    }

    const handleSearchChange = (value) => {
        setCurrentPage(0)
        setSearchTerm(value)
        refetch()
    }

    const handleQuoteStatusFilter = (event) => {
        setCurrentPage(0)
        setQuoteStatusFilter(event.target.value)
        refetch()
    }

    const handleQuoteArchivedFilter = (event) => {
        setCurrentPage(0)
        setQuoteArchivedFilter(event.target.checked)
        refetch()
    }

    const handleArchiveConfirm = async (quoteId) => {
        try {
            await deleteQuote({ organisationId, quoteId })
        } catch (error) {
            setShowErrorAlert(true)
        }
    }

    const handleUnarchiveConfirm = async (quoteId) => {
        try {
            unarchiveQuote({ organisationId, quoteId })
        } catch (error) {
            setShowErrorAlert(true)
        }
    }

    const handleCloseAlert = () => {
        setShowErrorAlert(false)
    }

    return (
        <div>
            <Box sx={classes.searchBar}>
                <Box sx={classes.paper}>
                    {!isError ? (
                        <SearchTextField
                            debounceInput={true}
                            label={t('Search $t(quote)')}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    ) : null}
                </Box>

                <Box sx={classes.paper}>
                    <FormControl
                        sx={classes.formControl}
                        variant="outlined"
                    >
                        <InputLabel id="demo-simple-select-outlined-label">{t('Filter by status')}</InputLabel>
                        <Select
                            id="demo-simple-select-outlined"
                            label={t('Filter by status')}
                            labelId="demo-simple-select-outlined-label"
                            size="small"
                            value={quoteStatusFilter}
                            onChange={handleQuoteStatusFilter}
                        >
                            <MenuItem value="All">{t('All')}</MenuItem>
                            {Object.keys(availableStatuses).map((key) => (
                                <MenuItem
                                    key={availableStatuses[key]}
                                    value={availableStatuses[key]}
                                >
                                    {t(QuoteStatus[availableStatuses[key]])}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={classes.paper}>
                    <TbxShowToggle
                        checked={quoteArchivedFilter}
                        label={t('Show archived $t(quotes)')}
                        onChange={handleQuoteArchivedFilter}
                    />
                </Box>
            </Box>
            {!isOnFreePlan ? (
                <QuoteHistoryTable
                    currentPage={currentPage}
                    filteredQuotes={quotes}
                    handlePageOrderChanged={handlePageOrderChanged}
                    isLoading={isLoading}
                    order={order}
                    orderBy={orderBy}
                    totalQuoteCount={totalQuoteCount}
                    onArchiveQuoteClick={handleArchiveConfirm}
                    onUnarchiveQuoteClick={handleUnarchiveConfirm}
                />
            ) : null}
            <ErrorSnackbar
                open={showErrorAlert}
                onClose={handleCloseAlert}
            >
                {t('$t(An error occurred)')}
            </ErrorSnackbar>
        </div>
    )
}

QuoteHistoryTab.propTypes = {
    customerId: PropTypes.string.isRequired,
}

export default QuoteHistoryTab
