import { useTranslation } from 'react-i18next'
import { blueGrey } from '@mui/material/colors'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import useNumberFormatter from '@/common/hooks/useNumberFormatter'
import MiscItemIcon from '@/common/icons/MiscItemIcon/MiscItemIcon'
import { getFormattedPrice } from '@/common/utils'

const classes = {
    noPageBreak: {
        breakInside: 'avoid',
    },

    colDivider: {
        py: 2,
        borderRight: `1px dashed ${blueGrey[200]}`,
        typography: 'body2',
        '&:last-of-type': {
            borderRight: 0,
        },
    },

    rowDivider: {
        borderBottom: `1px dashed ${blueGrey[200]}`,
    },
}

const QuoteOrderMiscItemRow = ({ colspan, index, item, organisation }) => {
    const { t } = useTranslation()

    const { n: q } = useNumberFormatter({ locale: organisation.locale, numberOfDecimalPlaces: 0 })

    const hasVendorNotes = Boolean(item.vendorNotes)

    const renderPartNameandId = (item) => {
        return (
            <>
                <Typography variant="body2">{item.name}</Typography>
                {item.partId ? <Typography variant="body2">{t('Item ID: ') + item.partId}</Typography> : null}
            </>
        )
    }

    return (
        <>
            <TableRow>
                <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {index + 1}
                </TableCell>
                <TableCell
                    align="center"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    <MiscItemIcon
                        fontSize="large"
                        titleAccess={t('Miscellaneous item icon')}
                    />
                </TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                >
                    {renderPartNameandId(item)}
                </TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                ></TableCell>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasVendorNotes && classes.rowDivider]}
                ></TableCell>
                <TableCell
                    align="right"
                    sx={[classes.colDivider, hasVendorNotes && classes.rowDivider, { typography: 'strong2' }]}
                >
                    {q(item.quantity)}
                </TableCell>
                <TableCell
                    align="right"
                    sx={[{ py: 2, typography: 'strong2' }, hasVendorNotes && classes.rowDivider]}
                >
                    {getFormattedPrice(item.itemPrice, organisation.currencyCode, organisation.locale)}
                </TableCell>
                <TableCell
                    align="right"
                    sx={[{ py: 2, typography: 'strong2' }, hasVendorNotes && classes.rowDivider]}
                >
                    {getFormattedPrice(item.linePrice, organisation.currencyCode, organisation.locale)}
                </TableCell>
            </TableRow>

            {item.vendorNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={[{ py: 2, borderColor: blueGrey[200] }]}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Vendor Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={item.vendorNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    )
}

QuoteOrderMiscItemRow.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
    colspan: PropTypes.number,
}

export default QuoteOrderMiscItemRow
