import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { useNumberFormatter } from '@/common/hooks'

const classes = {
    root: {
        width: '100%',
        height: 64,
        padding: 2,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}

const DrawingCleanerFooter = ({
    className,
    diameter,
    heightValue = 0,
    isRotary,
    numberDecimalPlaces = 2,
    partLength,
    pointerPosition,
    profileLength,
    profileWidth,
    widthValue = 0,
}) => {
    const locale = useSelector(selectLocale)

    const { n } = useNumberFormatter({
        locale: locale,
        numberOfDecimalPlaces: numberDecimalPlaces,
    })

    const containerStyle = Object.assign({}, classes.root, className)

    return (
        <Box sx={containerStyle}>
            <Typography variant="body1">
                {!isRotary && pointerPosition ? `${n(pointerPosition.x)} x ${n(pointerPosition.y)}` : null}
            </Typography>
            <Typography variant="body1">
                {isRotary
                    ? diameter
                        ? `${n(diameter)} x ${n(diameter)} x ${n(partLength)}`
                        : `${n(profileWidth)} x ${n(profileLength)} x ${n(partLength)}`
                    : `${n(widthValue)} x ${n(heightValue)}`}
            </Typography>
        </Box>
    )
}

DrawingCleanerFooter.propTypes = {
    className: PropTypes.object,
    diameter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRotary: PropTypes.bool,
    numberDecimalPlaces: PropTypes.number,
    partLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pointerPosition: PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    profileLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profileWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    widthValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DrawingCleanerFooter
