import { useCallback } from 'react'

import { getCurrencyFormatter } from '@/common/utils'

export function useCurrencyFormatter(props) {
    const { currencyCode, locale, ...options } = props

    const formatter = getCurrencyFormatter(currencyCode, locale, options)

    const formatCurrency = useCallback(
        (value) => {
            return value ? formatter.format(value) : value
        },
        [formatter]
    )

    return {
        formatCurrency,
        formatter,
    }
}
