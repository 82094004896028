import { theme as LightTheme } from '../../themes/LightTheme'
import { WhitelabelThemeOverride } from '../../themes/overrides/WhitelabelThemeOverride'

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

export const Resellers = {
    libellula: {
        name: 'libellula',
        title: 'Libellula.QUOTATION',
        theme: WhitelabelThemeOverride,
        logo: 'libellula-logo.png',
        organisationUnits: 'metric',
        selectedLanguage: 'it-IT',
        organisationLocale: 'it-IT',
        organisationCurrencyCode: 'EUR',
        hasWebStoreAccess: false,
    },
    striker: {
        name: 'striker',
        title: 'Striker Quote',
        theme: WhitelabelThemeOverride,
        logo: 'striker-logo.png',
        organisationUnits: 'metric',
        selectedLanguage: 'en-US',
        organisationLocale: 'en-US',
        organisationCurrencyCode: 'USD',
        hasWebStoreAccess: false,
    },
}

export const QuoteSourceTypes = {
    Internal: 0,
    WebStore: 1,
}

export const ShippingOptions = [
    {
        value: 0,
        label: 'Pickup',
    },
    {
        value: 1,
        label: 'Delivery',
    },
]

export const PUBLIC_DOCUMENTS = {
    Quote: 'quote',
    OrderConfirmation: 'order-confirmation',
    ProformaInvoice: 'proforma',
    TaxInvoice: 'tax-invoice',
}

export const PRIVATE_DOCUMENTS = {
    WorkOrderSummary: 'work-order-summary',
    WorkOrder: 'work-order',
    DeliveryDocket: 'delivery-docket',
    ProductionLabels: 'production-labels',
}

export const ProductDetails = {
    name: 'toolbox',
    title: 'ToolBox',
    theme: LightTheme,
    logo: 'app-logo.svg',
    organisationUnits: 'imperial',
    selectedLanguage: 'en-US',
    organisationLocale: 'en-US',
    organisationCurrencyCode: 'USD',
    hasWebStoreAccess: true,
}

export const WebStoreQuoteStatus = {
    NotCalculated: 'NotCalculated',
    Calculated: 'Calculated',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Dispatched: 'Dispatched',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    PendingOrderConfirmation: 'PendingOrderConfirmation',
    Rejected: 'Rejected',
    Voided: 'Voided',
    Issued: 'Issued',
}

export const QuoteStatus = {
    NotCalculated: 'NotCalculated',
    Calculated: 'Calculated',
    Draft: 'Draft',
    Issued: 'Issued',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    PendingOrderConfirmation: 'PendingOrderConfirmation',
    Rejected: 'Rejected',
    Voided: 'Voided',
    Dispatched: 'Dispatched',
}

export const QuoteStatusOrder = [
    {
        index: 0,
        status: 'Editing',
    },
    {
        index: 1,
        status: 'Draft',
    },
    {
        index: 2,
        status: 'Issued',
    },
    {
        index: 3,
        status: 'Pending Confirmation',
    },
    {
        index: 4,
        status: 'Ordered',
    },
    {
        index: 5,
        status: 'Invoiced',
    },
    {
        index: 6,
        status: 'Lost',
    },
    {
        index: 7,
        status: 'Rejected',
    },
    {
        index: 8,
        status: 'Cancelled',
    },
    {
        index: 9,
        status: 'Voided',
    },
]

export const QuotePageStatusLabel = {
    NotCalculated: 'Editing',
    Calculated: 'Editing',
    Draft: 'Draft',
    Issued: 'Issued',
    PendingOrderConfirmation: 'Pending Confirmation',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    Voided: 'Voided',
    Rejected: 'Rejected',
    Dispatched: 'Dispatched',
}

export const QuotePaymentStatus = {
    Unpaid: 'Unpaid',
    ManualPaid: 'ManualPaid',
    GatewayPaid: 'GatewayPaid',
}

export const QuotePaymentStatusLabel = {
    Unpaid: 'Unpaid',
    ManualPaid: 'Paid',
    GatewayPaid: 'Paid via Stripe',
}

export const getStatusLabel = (status) => {
    return QuotePageStatusLabel[status]
}

export const getPaymentStatusLabel = (status) => {
    if (status) {
        return QuotePaymentStatusLabel[status]
    }
    return QuotePaymentStatusLabel.Unpaid
}

export const QuoteActions = {
    reviewQuote: {
        id: 'reviewQuote',
        label: 'Review quote',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Draft,
        tagAttrs: { 'data-testid': 'review-quote-button' },
    },
    calculateQuote: {
        id: 'calculateQuote',
        label: 'Calculate',
        handler: 'handleCalculateQuote',
        tagAttrs: { 'data-testid': 'calculate-quote-button' },
    },
    editQuote: {
        id: 'editQuote',
        label: 'Edit quote',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Calculated,
        tagAttrs: { 'data-testid': 'edit-quote-button' },
    },
    editPendingOrder: {
        id: 'editPendingOrder',
        label: 'Edit pending order',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Calculated,
        tagAttrs: { 'data-testid': 'edit-pending-order-button' },
    },
    markAsIssued: {
        id: 'markAsIssued',
        label: 'Mark as issued',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Issued,
        tagAttrs: { 'data-testid': 'mark-as-issued-button' },
    },
    markAsOrdered: {
        id: 'markAsOrdered',
        label: 'Mark as ordered',
        handler: 'handleMarkAsOrdered',
        tagAttrs: { 'data-testid': 'mark-as-ordered-button' },
    },
    markAsLost: {
        id: 'markAsLost',
        label: 'Mark as lost',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Lost,
        tagAttrs: { 'data-testid': 'mark-as-lost-button' },
    },
    markAsInvoiced: {
        id: 'markAsInvoiced',
        label: 'Mark as invoiced',
        handler: 'handleMarkAsInvoiced',
        tagAttrs: { 'data-testid': 'mark-as-invoiced-button' },
    },
    markAsCancelled: {
        id: 'markAsCancelled',
        label: 'Mark as cancelled',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Cancelled,
        tagAttrs: { 'data-testid': 'mark-as-cancelled-button' },
    },
    markAsVoided: {
        id: 'markAsVoided',
        label: 'Mark as voided',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Voided,
        tagAttrs: { 'data-testid': 'mark-as-voided-button' },
    },
    markAsConfirmed: {
        id: 'markAsConfirmed',
        label: 'Confirm pending order',
        handler: 'handleMarkAsOrdered',
        tagAttrs: { 'data-testid': 'mark-as-confirmed-button' },
    },
    markAsRejected: {
        id: 'markAsRejected',
        label: 'Reject pending order',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.Rejected,
        tagAttrs: { 'data-testid': 'mark-as-rejected-button' },
    },
    markAsPending: {
        id: 'markAsPending',
        label: 'Revert to pending order',
        handler: 'handleQuoteStatusChange',
        param: QuoteStatus.PendingOrderConfirmation,
        tagAttrs: { 'data-testid': 'mark-as-pending-button' },
    },
    markAsPaid: {
        id: 'markAsPaid',
        label: 'Mark as paid',
        handler: 'handleQuotePaymentStatusChange',
        param: QuotePaymentStatus.ManualPaid,
        tagAttrs: { 'data-testid': 'mark-as-paid-button' },
        hidden: (showPayments) => !showPayments,
    },
    markAsPaidAndOrdered: {
        id: 'markAsPaidAndOrdered',
        label: 'Mark as paid and ordered',
        handler: 'handleMarkAsPaidAndOrdered',
        param: QuotePaymentStatus.ManualPaid,
        tagAttrs: { 'data-testid': 'mark-as-paid-and-ordered-button' },
        hidden: (showPayments) => !showPayments,
    },
    markAsUnpaid: {
        id: 'markAsUnpaid',
        label: 'Mark as unpaid',
        handler: 'handleQuotePaymentStatusChange',
        param: QuotePaymentStatus.Unpaid,
        tagAttrs: { 'data-testid': 'mark-as-unpaid-button' },
        hidden: (showPayments) => !showPayments,
    },
    copyQuoteLink: {
        id: 'copyQuoteLink',
        label: 'Copy quote link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.Quote,
        tagAttrs: { 'data-testid': 'copy-quote-link-button' },
    },
    copyOrderLink: {
        id: 'copyOrderLink',
        label: 'Copy order confirmation link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.OrderConfirmation,
        tagAttrs: { 'data-testid': 'copy-order-link-button' },
    },
    copyProformaLink: {
        id: 'copyProformaLink',
        label: 'Copy proforma invoice link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.ProformaInvoice,
        tagAttrs: { 'data-testid': 'copy-proforma-link-button' },
    },
    copyInvoiceLink: {
        id: 'copyInvoiceLink',
        label: 'Copy invoice link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.TaxInvoice,
        tagAttrs: { 'data-testid': 'copy-invoice-link-button' },
    },
    emailQuote: {
        id: 'emailQuote',
        label: 'Email quote',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.Quote,
        tagAttrs: { 'data-testid': 'email-quote-button' },
    },
    emailOrder: {
        id: 'emailOrder',
        label: 'Email order confirmation',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.OrderConfirmation,
        tagAttrs: { 'data-testid': 'email-order-button' },
    },
    emailProformaInvoice: {
        id: 'emailProformaInvoice',
        label: 'Email proforma invoice',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.ProformaInvoice,
        tagAttrs: { 'data-testid': 'email-proforma-invoice-button' },
    },
    emailTaxInvoice: {
        id: 'emailTaxInvoice',
        label: 'Email tax invoice',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.TaxInvoice,
        tagAttrs: { 'data-testid': 'email-tax-invoice-button' },
    },
    emailCustomer: {
        id: 'emailCustomer',
        label: 'Email customer',
        handler: 'handleSendEmail',
        param: 'sendCustomer',
        tagAttrs: { 'data-testid': 'email-customer-button' },
    },

    sendToPartLibrary: {
        id: 'sendToPartLibrary',
        label: 'Send to part library',
        handler: 'handleSendToPartLibrary',
        tagAttrs: { 'data-testid': 'send-to-part-library-button' },
    },
    duplicateQuote: {
        id: 'duplicateQuote',
        label: 'Duplicate quote',
        handler: 'handleDuplicateQuote',
        tagAttrs: { 'data-testid': 'duplicate-quote-button' },
    },
    resendInvoiceData: {
        id: 'resendInvoiceData',
        label: 'Resend invoice data',
        handler: 'handleResendInvoiceData',
        tagAttrs: { 'data-testid': 'resend-invoice-data-button' },
        hidden: (showRetryIntegration) => !showRetryIntegration,
    },
}

export const QuoteStateToActionMap = (status, paymentStatus) => {
    let actions = null
    switch (status) {
        case QuoteStatus.NotCalculated:
            actions = [
                QuoteActions.calculateQuote,
                QuoteActions.emailQuote,
                QuoteActions.markAsIssued,
                QuoteActions.copyQuoteLink,
                QuoteActions.sendToPartLibrary,
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.Calculated:
            actions = [
                QuoteActions.reviewQuote,
                QuoteActions.emailQuote,
                QuoteActions.markAsIssued,
                QuoteActions.copyQuoteLink,
                QuoteActions.sendToPartLibrary,
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.Draft:
            actions = [
                QuoteActions.markAsIssued,
                QuoteActions.editQuote,
                QuoteActions.emailQuote,
                QuoteActions.copyQuoteLink,
                QuoteActions.sendToPartLibrary,
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.Issued:
            actions = [
                QuoteActions.markAsOrdered,
                ...(paymentStatus === QuotePaymentStatus.Unpaid
                    ? [QuoteActions.markAsPaidAndOrdered]
                    : [QuoteActions.markAsUnpaid]),
                QuoteActions.editQuote,
                QuoteActions.emailQuote,
                QuoteActions.copyQuoteLink,
                QuoteActions.markAsLost,
                QuoteActions.sendToPartLibrary,
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.PendingOrderConfirmation:
            actions = [
                QuoteActions.emailCustomer,
                QuoteActions.markAsConfirmed,
                ...(paymentStatus === QuotePaymentStatus.Unpaid
                    ? [QuoteActions.markAsPaidAndOrdered]
                    : [QuoteActions.markAsUnpaid]),
                QuoteActions.editPendingOrder,

                QuoteActions.markAsRejected,
            ]
            break
        case QuoteStatus.Ordered:
            actions = [
                QuoteActions.markAsInvoiced,
                ...(paymentStatus === QuotePaymentStatus.Unpaid
                    ? [QuoteActions.markAsPaid]
                    : [QuoteActions.markAsUnpaid]),
                QuoteActions.emailOrder,
                QuoteActions.copyOrderLink,
                QuoteActions.emailProformaInvoice,
                QuoteActions.copyProformaLink,

                QuoteActions.markAsCancelled,
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.Invoiced:
            actions = [
                QuoteActions.emailTaxInvoice,
                QuoteActions.copyInvoiceLink,
                ...(paymentStatus === QuotePaymentStatus.Unpaid
                    ? [QuoteActions.markAsPaid]
                    : [QuoteActions.markAsUnpaid]),
                QuoteActions.markAsVoided,
                QuoteActions.duplicateQuote,
                QuoteActions.resendInvoiceData,
            ]
            break
        case QuoteStatus.Lost:
            actions = [
                QuoteActions.editQuote,
                QuoteActions.emailCustomer,
                ...(paymentStatus === QuotePaymentStatus.Unpaid
                    ? [QuoteActions.markAsPaidAndOrdered]
                    : [QuoteActions.markAsUnpaid]),
                QuoteActions.duplicateQuote,
            ]
            break
        case QuoteStatus.Cancelled:
            actions = [QuoteActions.duplicateQuote]
            break
        case QuoteStatus.Voided:
            actions = [QuoteActions.duplicateQuote]
            break
        case QuoteStatus.Rejected:
            actions = [QuoteActions.emailCustomer, QuoteActions.markAsPending]
            break
        case QuoteStatus.Dispatched:
            actions = [QuoteActions.markAsLost, QuoteActions.markAsCancelled]
            break
        default:
            actions = null
    }

    return actions
}

export const QuoteAddPartsOptions = [
    {
        id: 'add2dParts',
        label: 'Add 2D Parts',
        handler: 'handleAdd2dParts',
        tagAttrs: { 'data-testid': 'add-2d-parts-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'add3dParts',
        label: 'Add 3D Parts',
        handler: 'handleAdd3dParts',
        tagAttrs: { 'data-testid': 'add-3d-parts-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'addRotaryParts',
        label: 'Add Rotary Parts',
        handler: 'handleAddRotaryParts',
        tagAttrs: { 'data-testid': 'add-rotary-parts-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'addFromQuickParts',
        label: 'Add from Quick Part',
        handler: 'handleAddFromQuickParts',
        tagAttrs: { 'data-testid': 'add-from-quickpart-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'addPdfParts',
        label: 'Add parts from PDF',
        handler: 'handleAddPdfParts',
        tagAttrs: { 'data-testid': 'add-pdf-part-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'addFromPartsLibrary',
        label: 'Add from Part Library',
        handler: 'handleAddFromPartsLibrary',
        tagAttrs: { 'data-testid': 'add-from-part-library-button' },
        source: QuoteSourceTypes.Internal,
    },
    {
        id: 'addMiscellaneousItem',
        label: 'Add Miscellaneous Item',
        handler: 'handleAddMiscellaneousItem',
        tagAttrs: { 'data-testid': 'add-miscellaneous-item-button' },
        source: QuoteSourceTypes.Internal,
    },
]

export const WebStoreQuoteAddPartsOptions = [
    {
        id: 'add2dParts',
        label: 'Add 2D Parts',
        handler: 'handleAdd2dParts',
        tagAttrs: { 'data-testid': 'add-2d-parts-button' },
        source: QuoteSourceTypes.WebStore,
    },
    {
        id: 'addFromQuickParts',
        label: 'Add from Quick Part',
        handler: 'handleAddFromQuickParts',
        tagAttrs: { 'data-testid': 'add-from-quickpart-button' },
        source: QuoteSourceTypes.WebStore,
    },
    // {
    //     id: 'add3dParts',
    //     label: 'Add 3D Parts',
    //     handler: 'handleAdd3dParts',
    //     tagAttrs: { 'data-testid': 'add-3d-parts-button' },
    // },
    // {
    //     id: 'addRotaryParts',
    //     label: 'Add Rotary Parts',
    //     handler: 'handleAddRotaryParts',
    //     tagAttrs: { 'data-testid': 'add-rotary-parts-button' },
    // },
    // {
    //     id: 'addPdfParts',
    //     label: 'Add parts from PDF',
    //     handler: 'handleAddPdfParts',
    //     tagAttrs: { 'data-testid': 'add-pdf-part-button' },
    // },
    // {
    //     id: 'addFromPartsLibrary',
    //     label: 'Add from Part Library',
    //     handler: 'handleAddFromPartsLibrary',
    //     tagAttrs: { 'data-testid': 'add-from-part-library-button' },
    // },
]

const DownloadDocumentsOptionsKeys_A = [
    QuoteStatus.NotCalculated,
    QuoteStatus.NotCalculated,
    QuoteStatus.Draft,
    QuoteStatus.Issued,
    QuoteStatus.Lost,
    QuoteStatus.PendingOrderConfirmation,
    QuoteStatus.Rejected,
]

const DownloadDocumentsOptionsKeys_B = [QuoteStatus.Ordered, QuoteStatus.Cancelled, QuoteStatus.Voided]

export const QuoteDownloadDocumentsOptions = {
    ...Object.fromEntries(
        DownloadDocumentsOptionsKeys_A.map((key) => [
            key,
            [
                {
                    id: PUBLIC_DOCUMENTS.Quote,
                    label: 'Quote',
                    disabled: false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-quote-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.OrderConfirmation,
                    label: 'Order confirmation',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.ProformaInvoice,
                    label: 'Proforma invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.TaxInvoice,
                    label: 'Tax invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrderSummary,
                    label: 'Work order summary',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrder,
                    label: 'Work order',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.DeliveryDocket,
                    label: 'Delivery docket',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.ProductionLabels,
                    label: 'Production labels',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-production-labels-button' },
                },
            ],
        ])
    ),
    ...Object.fromEntries(
        DownloadDocumentsOptionsKeys_B.map((key) => [
            key,
            [
                {
                    id: PUBLIC_DOCUMENTS.Quote,
                    label: 'Quote',
                    disabled: false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-quote-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.OrderConfirmation,
                    label: 'Order confirmation',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.ProformaInvoice,
                    label: 'Proforma invoice',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.TaxInvoice,
                    label: 'Tax invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrderSummary,
                    label: 'Work order summary',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrder,
                    label: 'Work order',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.DeliveryDocket,
                    label: 'Delivery docket',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.ProductionLabels,
                    label: 'Production labels',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-production-labels-button' },
                },
            ],
        ])
    ),
    [QuoteStatus.Invoiced]: [
        {
            id: PUBLIC_DOCUMENTS.Quote,
            label: 'Quote',
            disabled: false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-quote-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.OrderConfirmation,
            label: 'Order confirmation',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.ProformaInvoice,
            label: 'Proforma invoice',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.TaxInvoice,
            label: 'Tax invoice',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.WorkOrderSummary,
            label: 'Work order summary',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.WorkOrder,
            label: 'Work order',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-work-order-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.DeliveryDocket,
            label: 'Delivery docket',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.ProductionLabels,
            label: 'Production labels',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-production-labels-button' },
        },
    ],
}

export const QuoteUserType = {
    Customer: 'Customer',
    Guest: 'Guest',
    Internal: 'Internal',
}

export const QuadernoJurisdictions = [
    {
        country: 'BE',
        name: 'Belgica',
    },
    {
        country: 'BG',
        name: 'Bulgaria',
    },
    {
        country: 'CY',
        name: 'Cyprus',
    },
    {
        country: 'CZ',
        name: 'Czech Republic',
    },
    {
        country: 'DE',
        name: 'Germany',
    },
    {
        country: 'DK',
        name: 'Denmark',
    },
    {
        country: 'EE',
        name: 'Estonia',
    },
    {
        country: 'EL',
        name: 'Greece',
    },
    {
        country: 'ES',
        name: 'Spain',
    },
    {
        country: 'FI',
        name: 'Finland',
    },
    {
        country: 'FR',
        name: 'France',
    },
    {
        country: 'HR',
        name: 'Croatia',
    },
    {
        country: 'HU',
        name: 'Hungary',
    },
    {
        country: 'IE',
        name: 'Ireland',
    },
    {
        country: 'IT',
        name: 'Italy',
    },
    {
        country: 'LR',
        name: 'Lithuania',
    },
    {
        country: 'LU',
        name: 'Luxembourg',
    },
    {
        country: 'LV',
        name: 'Latvia',
    },
    {
        country: 'MT',
        name: 'Malta',
    },
    {
        country: 'NL',
        name: 'The Netherlands',
    },
    {
        country: 'PL',
        name: 'Poland',
    },
    {
        country: 'PT',
        name: 'Portugal',
    },
    {
        country: 'RO',
        name: 'Romania',
    },
    {
        country: 'SE',
        name: 'Sweden',
    },
    {
        country: 'SI',
        name: 'Slovenia',
    },
    {
        country: 'SK',
        name: 'Slovakia',
    },
    {
        country: 'XI',
        name: 'Northern Ireland',
    },
    {
        country: 'AU',
        name: 'Australia',
    },
    {
        country: 'GB',
        name: 'United Kingdom of Great Bretain and Northern Ireland',
    },
    {
        country: 'CH',
        name: 'Switzerland',
    },
    {
        country: 'NZ',
        name: 'New Zealand',
    },
    {
        country: 'CA',
        name: 'Canada',
    },
]

export const EXPORTABLE_CONTENT = 'exportable-content'
export const FOOTER_BRANDING = 'exportable-footer-branding'
export const FOOTER_SELECTOR = 'exportable-footer'
export const IS_EXPORTING_SELECTOR = 'exportable-is-exporting'

export const CuttingTechnologyPowers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const PARTS_PER_PAGE_VARIABLE = 'parts-per-page'
export const PARTS_PER_PAGE_DEFAULT_VALUE = 10
export const PARTS_PER_PAGE_OPTIONS = [10, 25, 50]

export const ITEMS_PER_PAGE_VARIABLE = 'items-per-page'
export const ITEMS_PER_PAGE_DEFAULT_VALUE = 5
export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

export const USERS_PER_PAGE_VARIABLE = 'users-per-page'
export const USERS_PER_PAGE_DEFAULT_VALUE = 5
export const USERS_PER_PAGE_OPTIONS = [5, 10, 15]

export const TAX_RATES_PER_PAGE_VARIABLE = 'tax-rates-per-page'
export const TAX_RATES_PER_PAGE_DEFAULT_VALUE = 5
export const TAX_RATES_PER_PAGE_OPTIONS = [5, 10, 15]

export const INVOICES_PER_PAGE_VARIABLE = 'invoices-per-page'
export const INVOICES_PER_PAGE_DEFAULT_VALUE = 5
export const INVOICES_PER_PAGE_OPTIONS = [5, 10, 15]

export const DEFAULT_QUOTE_FOOTER = `Thank you for your enquiry. This estimate is valid for 30 days.
All items are subject to the availability and price of raw materials at the time of order.
Any revisions to this quotation may require a recalculation of price.`

export const DEFAULT_CONTACT_DETAILS = `Business name
Tax ID 1234567890
123 Sample Lane
City, State, Zip Code
+1 (123) 456-7890
sales@yourbusiness.com`

export const DEFAULT_PAYMENT_DETAILS = `Bank name: Big Bank
Routing number: 123456789
Account number 123456789000
SWIFT code: WWWFFF`

export const PROFORMA_INVOICE_NOTES = `This proforma invoice must be paid in full before work can commence.
Please note this is not a tax invoice. A full tax invoice will follow delivery of goods.`

export const LIBELLULA_PRICING_REDIRECT_URL = 'mailto:contact@libellula.eu'
export const TOOLBOX_PRICING_REDIRECT_URL = 'https://tempustools.com/contact-us/pricing-request/?stripe_customer_id='

export const COUNTRY_LANGUAGE_MAP = {
    AU: { locale: 'en-GB', language: 'English' },
    CA: { locale: 'en-US', language: 'English' },
    CL: { locale: 'es-ES', language: 'Spanish' },
    FR: { locale: 'fr-FR', language: 'French' },
    DE: { locale: 'de-DE', language: 'German' },
    IT: { locale: 'it-IT', language: 'Italian' },
    NZ: { locale: 'en-GB', language: 'English' },
    GB: { locale: 'en-GB', language: 'English' },
    US: { locale: 'en-US', language: 'English' },
    BR: { locale: 'pt-PT', language: 'Portuguese' },
    PT: { locale: 'pt-PT', language: 'Portuguese' },
}
