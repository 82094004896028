import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Archive, Edit, MonetizationOn, MoneyOff, Store, Unarchive } from '@mui/icons-material'
import { Box, IconButton, Switch, TableCell, TableRow } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetRatesQuery } from '@/app/services/rates'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import {
    useSetSheetAsWebStoreEnabledMutation,
    useUpdateSheetCuttingTechnologyMutation,
    useUpdateSheetMutation,
} from '@/app/services/sheets'
import { selectCurrentUser, selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation, selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { addToSelectedSheets, removeFromSelectedSheets, selectSelectedSheets } from '@/app/slices/sheetsSlice'
import { DateTime, TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { useCurrencyFormatter, useNumberFormatter, useToolBoxTreatments } from '@/common/hooks'
import { fractionToPercentage, organisationLengthPrecision, sheetCanBeCut } from '@/common/utils'

import ArchiveSheetDialog from './ArchiveSheetDialog'

const MaterialConsumptionModes = (materialType) => {
    if (materialType === 'Flat') {
        return [
            { label: 'Whole Sheet', value: 'WholeSheet' },
            { label: 'Nest Bounds', value: 'NestBounds' },
        ]
    }
    return [
        { label: 'Whole Length', value: 'WholeSheet' },
        { label: 'Nest Bounds', value: 'NestBounds' },
    ]
}

const MaterialProfileTypes = [
    { name: 'Rectangle', value: 'Rectangle' },
    { name: 'Square', value: 'Square' },
    { name: 'Circle', value: 'Circle' },
    { name: 'Flat sided oval', value: 'FlatSidedOval' },
]

const classes = {
    cell: (isDeleted) => ({
        padding: '14px 5px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    cellDisabled: {
        padding: '14px 5px',
        color: 'text.disabled',
    },
    cellExpired: {
        padding: '14px 5px',
        color: 'error.main',
    },
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        color: 'text.primary',
    },
    activatedIcon: {
        color: 'text.primary',
    },
    deactivatedIcon: {
        color: 'text.disabled',
    },
    button: {
        margin: '0px 4px',
    },
    selectionCheckbox: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    switchTrackDisabled: {
        backgroundColor: 'rgba(52, 73, 94, 0.38)',
    },
    switchBaseDisabled: {
        cursor: 'not-allowed',
    },
}

const SheetsTableRow = ({ onEditClick, selectedMaterial, sheet, validCuttingTechnologies }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const [isSaving, setIsSaving] = useState(false)

    const currentUser = useSelector(selectCurrentUser)

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const locale = useSelector(selectLocale)

    const { data: rateTables } = useGetRateTablesQuery({ organisationId })
    const { data: rates, isLoading: isLoadingRates } = useGetRatesQuery({ organisationId })

    const selectedSheets = useSelector(selectSelectedSheets)

    const [itemSheet, setItemSheet] = useState(sheet)

    const [updateSheet] = useUpdateSheetMutation()
    //const [setSheetAsDefault] = useSetSheetAsDefaultMutation()
    const [setSheetAsWebStoreEnabled] = useSetSheetAsWebStoreEnabledMutation()
    const [updateSheetCuttingTechnology] = useUpdateSheetCuttingTechnologyMutation()

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const { showPricing, showWebStore } = useToolBoxTreatments()

    const showEnableSheetForWebStoreButton = showWebStore && paidFeatures.hasWebStore

    const { n } = useNumberFormatter({
        locale,
    })

    const { n: n2 } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: 2,
    })

    const { n: dimensionFormat } = useNumberFormatter({
        locale,
        numberOfDecimalPlaces: organisation ? organisationLengthPrecision(organisation) : 2,
    })

    const { formatCurrency } = useCurrencyFormatter({
        locale,
        currencyCode: organisation.currencyCode,
    })

    const handleSetSheetAsDefaultClick = async () => {
        try {
            setIsSaving(true)
            const setNotAsDefault = selectedMaterial?.sheets.find(
                (s) => s.thickness == sheet.thickness && s.isDefault == true
            )

            if (setNotAsDefault) {
                setNotAsDefault.isDefault = false
                await updateSheet({ organisationId, sheet: setNotAsDefault }).unwrap()
            }

            const setAsDefault = sheet
            setAsDefault.isDefault = true
            await updateSheet({ organisationId, sheet: setAsDefault }).unwrap()
            enqueueSnackbar(t('Sheet set as default'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Error setting sheet as default'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    const handleEditSheetIsSoldByOrganisationClick = async () => {
        setIsSaving(true)

        const updatedSheet = structuredClone(sheet)
        updatedSheet.isSoldByOrganisation = updatedSheet.isSoldByOrganisation ? false : true
        if (!updatedSheet.isSoldByOrganisation) {
            updatedSheet.isWebStoreEnabled = false
        }

        try {
            await updateSheet({ organisationId, sheet: updatedSheet }).unwrap()
            enqueueSnackbar(
                t(
                    updatedSheet.isSoldByOrganisation
                        ? 'Sheet is now for sale'
                        : 'Sheet is now only for customer-supplied'
                ),
                { variant: 'success' }
            )
        } catch (error) {
            enqueueSnackbar(
                t(
                    updatedSheet.isSoldByOrganisation
                        ? 'Error enabling sheet for sale'
                        : 'Error disabling sheet for sale'
                ),
                { variant: 'error' }
            )
        } finally {
            setIsSaving(false)
        }
    }

    const handleWebStoreEnabledClick = async () => {
        setIsSaving(true)

        const isWebStoreEnabled = sheet.isWebStoreEnabled ? false : true

        try {
            await setSheetAsWebStoreEnabled({ organisationId, sheetId: sheet.sheetId, isWebStoreEnabled }).unwrap()
            enqueueSnackbar(t(isWebStoreEnabled ? 'Sheet enabled for Web Store' : 'Sheet disabled for Web Store'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(
                t(isWebStoreEnabled ? 'Error enabling sheet for Web Store' : 'Error disabling sheet for Web Store'),
                { variant: 'error' }
            )
        } finally {
            setIsSaving(false)
        }
    }

    const hideArchiveDialog = () => {
        setShowDeleteDialog(false)
    }

    const handleArchiveSheetClick = () => {
        setShowDeleteDialog(true)
    }

    const confirmArchiveSheet = async () => {
        setIsSaving(true)
        if (sheet) {
            /* TODO Re-enable when default sheets are reintroduced */
            // const setNotAsDefault = selectedMaterial?.sheets.find(s => s.thickness == sheet.thickness &&
            //     s.isDefault == true)
            // if (setNotAsDefault)
            // {
            //     const setAsDefault = selectedMaterial?.sheets.find(s => s.thickness == sheet.thickness &&
            //         s.isDeleted == false && s.sheetId != sheet.sheetId)
            //     setAsDefault.isDefault = true
            //     await updateSheet({ organisationId, sheet: setAsDefault }).unwrap()
            //     sheet.isDefault = false
            // }
        }
        const updatedSheet = structuredClone(sheet)
        updatedSheet.isDeleted = true
        try {
            await updateSheet({ organisationId, sheet: updatedSheet }).unwrap()
            enqueueSnackbar(t('Sheet archived'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Error archiving sheet'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
        hideArchiveDialog()
    }

    const confirmUnarchiveSheet = async () => {
        setIsSaving(true)

        const updatedSheet = structuredClone(sheet)
        updatedSheet.isDeleted = false
        updatedSheet.isDefault = false
        try {
            await updateSheet({ organisationId, sheet: updatedSheet }).unwrap()
            enqueueSnackbar(t('Sheet unarchived'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Error unarchiving sheet'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
        hideArchiveDialog()
    }

    const handleEditSheetClick = () => {
        onEditClick(sheet)
    }
    const handleCuttingTechnologyToggleChecked = (checkValue) => {
        let res = false
        if (!checkValue || checkValue.isEnabled) {
            res = true
        }
        return res
    }

    const handleCuttingTechnologyToggleChangeCreator = (cuttingTechnologyId) => async (e, value) => {
        setIsSaving(true)

        const sheetCuttingTechnologies = structuredClone(itemSheet.sheetCuttingTechnology)
        let sheetCuttingTechnology = {}
        sheetCuttingTechnology = itemSheet.sheetCuttingTechnology?.find(
            (sct) => sct.cuttingTechnologyId === cuttingTechnologyId
        ) ?? {
            sheetId: itemSheet.sheetId,
            cuttingTechnologyId: cuttingTechnologyId,
        }

        const index = sheetCuttingTechnologies?.findIndex((sct) => sct.cuttingTechnologyId === cuttingTechnologyId)

        sheetCuttingTechnology = structuredClone(sheetCuttingTechnology)
        sheetCuttingTechnology.isEnabled = value

        if (index !== -1) {
            sheetCuttingTechnologies[index] = sheetCuttingTechnology
        } else {
            sheetCuttingTechnologies.push(sheetCuttingTechnology)
        }
        setItemSheet({ ...itemSheet, sheetCuttingTechnology: sheetCuttingTechnologies })

        debouncedhandleUpdateSheetCuttingTechnology(organisationId, sheetCuttingTechnology)
    }

    const handleUpdateSheetCuttingTechnology = async (organisationId, sheetCuttingTechnology) => {
        try {
            await updateSheetCuttingTechnology({ organisationId, sheetCuttingTechnology }).unwrap()
            enqueueSnackbar(t('Sheet cutting technology updated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Error updating sheet cutting technology'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    const debouncedhandleUpdateSheetCuttingTechnology = useDebouncedCallback(handleUpdateSheetCuttingTechnology, 500)

    const sheetSize = () => {
        if (sheet?.profile === 'Circle') {
            return n(sheet.diameter)
        }

        if (sheet?.profile === 'Square') {
            return n(sheet.sheetWidth)
        }

        if (sheet.sheetWidth > sheet.sheetHeight) {
            return (
                (sheet?.sheetWidth ? n(sheet?.sheetWidth) : '') +
                ' x ' +
                (sheet?.sheetHeight ? n(sheet?.sheetHeight) : '')
            )
        }

        return (
            (sheet?.sheetWidth ? n(sheet?.sheetWidth) : '') + ' x ' + (sheet?.sheetHeight ? n(sheet?.sheetHeight) : '')
        )
    }

    const getIconClassName = (isDisabled) => (isDisabled ? classes.deactivatedIcon : classes.activatedIcon)

    // const percent = (value) => percentageFormatter(value/100)

    const toggleSheetSelection = (event) => {
        if (!sheet) return
        const { checked } = event.target
        checked ? dispatch(addToSelectedSheets(sheet)) : dispatch(removeFromSelectedSheets(sheet))
    }

    const isSelected = useMemo(() => {
        if (!sheet || !selectedSheets || !selectedSheets?.length) return false
        return selectedSheets.some((s) => s.sheetId === sheet.sheetId)
    }, [sheet, selectedSheets])

    const materialIs = useCallback(
        (materialType) => {
            return selectedMaterial?.type === materialType
        },
        [selectedMaterial]
    )

    //check if sheet expire date is in the past
    const isExpired = useCallback(() => {
        if (!sheet.expiryDate) return false
        const today = new Date()
        const expireDate = new Date(sheet.expiryDate)
        return today > expireDate
    }, [sheet.expiryDate])

    useEffect(() => {
        if (sheet) {
            setItemSheet(sheet)
        }
    }, [sheet])

    return (
        <TableRow key={sheet.sheetId}>
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    sx={classes.selectionCheckbox}
                    onChange={toggleSheetSelection}
                />
            </TableCell>
            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {dimensionFormat(sheet?.thickness) ?? ''}
            </TableCell>

            {materialIs('Rotary') ? (
                <TableCell
                    align="left"
                    sx={classes.cell(sheet?.isDeleted)}
                >
                    {t(MaterialProfileTypes.find((mpt) => mpt.value === sheet?.profile)?.name)}
                </TableCell>
            ) : null}

            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {sheetSize()}
            </TableCell>

            {materialIs('Rotary') ? (
                <TableCell
                    align="right"
                    style={{ width: '80px' }}
                    sx={classes.cell(sheet?.isDeleted)}
                >
                    {n(sheet?.materialLength) ?? ''}
                </TableCell>
            ) : null}

            {showPricing ? (
                <TableCell
                    align="right"
                    sx={classes.cell(sheet?.isDeleted)}
                >
                    {formatCurrency(sheet?.sheetCost) ?? ''}
                </TableCell>
            ) : null}

            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {formatCurrency(sheet?.sheetRatePrice) ?? ''}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {sheet.extraMarkupPercent ? `${n2(fractionToPercentage(sheet.extraMarkupPercent))}` : '–'}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {`${n2(
                    fractionToPercentage(selectedMaterial?.standardMarkupPercentage) +
                        fractionToPercentage(sheet?.extraMarkupPercent)
                )}%`}
            </TableCell>

            <TableCell
                align="right"
                sx={
                    isExpired()
                        ? classes.cellExpired
                        : sheet?.expiryDate
                          ? classes.cell(sheet?.isDeleted)
                          : classes.cellDisabled
                }
            >
                {sheet?.expiryDate ? (
                    <DateTime
                        format="DD-MMM-YYYY"
                        locale={locale}
                    >
                        {sheet.expiryDate}
                    </DateTime>
                ) : (
                    t('No expiry')
                )}
            </TableCell>

            <TableCell
                align="right"
                sx={sheet?.lastUsedDate ? classes.cell(sheet?.isDeleted) : classes.cellDisabled}
            >
                {sheet?.lastUsedDate ? (
                    <DateTime
                        format="DD-MMM-YYYY"
                        locale={locale}
                    >
                        {sheet.lastUsedDate}
                    </DateTime>
                ) : (
                    t('Not Used')
                )}
            </TableCell>

            <TableCell sx={classes.cell(sheet?.isDeleted)}>
                {t(
                    MaterialConsumptionModes(selectedMaterial?.type).find(
                        (option) => option.value === sheet?.defaultMaterialConsumptionMode
                    ).label ?? ''
                )}
            </TableCell>

            {!!validCuttingTechnologies.length && !isLoadingRates
                ? validCuttingTechnologies.map((ct, ctIndex) => {
                      const currentMaterialRateTable = selectedMaterial?.rateTables.find(
                          (mrt) => rateTables[mrt.rateTableId]?.cuttingTechnologyId === ct.cuttingTechnologyId
                      )
                      const currentRateTable = rateTables[currentMaterialRateTable?.rateTableId]

                      const cuttingTechValidationResult = sheetCanBeCut(sheet, ct, currentRateTable, rates)
                      const cuttingTechErrorMessage = cuttingTechValidationResult.errorMessage

                      return (
                          <TableCell
                              key={`${sheet.sheetId}_ct_${ctIndex}`}
                              sx={classes.cell(sheet?.isDeleted)}
                          >
                              <Box sx={classes.toggleContainer}>
                                  {cuttingTechValidationResult.canBeCut ||
                                  sheet.isDeleted ||
                                  selectedMaterial?.isDeleted ? (
                                      <Switch
                                          checked={handleCuttingTechnologyToggleChecked(
                                              itemSheet.sheetCuttingTechnology?.find(
                                                  (sct) => sct.cuttingTechnologyId === ct.cuttingTechnologyId
                                              )
                                          )}
                                          color="primary"
                                          disabled={sheet.isDeleted || selectedMaterial?.isDeleted}
                                          size="small"
                                          onChange={handleCuttingTechnologyToggleChangeCreator(ct.cuttingTechnologyId)}
                                      />
                                  ) : (
                                      <TbxTooltip
                                          title={t(cuttingTechErrorMessage)}
                                          arrow
                                      >
                                          <Switch
                                              checked={false}
                                              classes={{
                                                  track: classes.switchTrackDisabled,
                                                  switchBase: classes.switchBaseDisabled,
                                              }}
                                              size="small"
                                          />
                                      </TbxTooltip>
                                  )}
                              </Box>
                          </TableCell>
                      )
                  })
                : null}

            <TableCell
                align="right"
                sx={classes.cell(sheet?.isDeleted)}
            >
                {!selectedMaterial?.isDeleted ? (
                    <>
                        {/* TODO Re-enable when default sheets are reintroduced */}
                        {/* <TbxTooltip
                            arrow
                            title={t('Set as default sheet')}
                        >
                            <IconButton
                                disabled={sheet?.isDeleted || disabled}
                                size="small"
                                className={classes.button}
                                onClick={handleSetSheetAsDefaultClick}
                            >
                                {sheet?.isDefault ?
                                    <Favorite className={getIconClassName(sheet?.isDeleted || selectedMaterial?.isDeleted)} /> :
                                    <FavoriteBorder className={getIconClassName(sheet?.isDeleted || selectedMaterial?.isDeleted)} />
                                }
                            </IconButton>
                        </TbxTooltip> */}
                        {showEnableSheetForWebStoreButton ? (
                            <TbxTooltip
                                title={t(
                                    sheet?.isSoldByOrganisation
                                        ? sheet?.isWebStoreEnabled
                                            ? 'Enabled for Web Store'
                                            : 'Disabled for Web Store'
                                        : 'Customer-supplied sheets are not available for Web Store'
                                )}
                                arrow
                            >
                                <span>
                                    <IconButton
                                        disabled={
                                            sheet?.isDeleted ||
                                            selectedMaterial?.isDeleted ||
                                            !sheet?.isSoldByOrganisation
                                        }
                                        size="small"
                                        sx={classes.button}
                                        onClick={handleWebStoreEnabledClick}
                                    >
                                        <Store
                                            style={{ fontSize: 18 }}
                                            sx={getIconClassName(!sheet?.isWebStoreEnabled)}
                                        />
                                    </IconButton>
                                </span>
                            </TbxTooltip>
                        ) : null}
                        <TbxTooltip
                            title={
                                paidFeatures.hasCustomerSuppliedMaterial ? (
                                    t(
                                        sheet?.isSoldByOrganisation
                                            ? 'For sale'
                                            : 'Not for sale. Customer-supplied only.'
                                    )
                                ) : (
                                    <UpgradePlanLink />
                                )
                            }
                            arrow
                        >
                            <IconButton
                                size="small"
                                sx={classes.button}
                                onClick={
                                    !sheet?.isDeleted &&
                                    !selectedMaterial?.isDeleted &&
                                    paidFeatures.hasCustomerSuppliedMaterial
                                        ? handleEditSheetIsSoldByOrganisationClick
                                        : null
                                }
                            >
                                {sheet?.isSoldByOrganisation ? (
                                    <MonetizationOn
                                        style={{ fontSize: 18 }}
                                        sx={getIconClassName(
                                            sheet?.isDeleted ||
                                                selectedMaterial?.isDeleted ||
                                                !paidFeatures.hasCustomerSuppliedMaterial
                                        )}
                                    />
                                ) : (
                                    <MoneyOff
                                        style={{ fontSize: 18 }}
                                        sx={getIconClassName(
                                            sheet?.isDeleted ||
                                                selectedMaterial?.isDeleted ||
                                                !paidFeatures.hasCustomerSuppliedMaterial
                                        )}
                                    />
                                )}
                            </IconButton>
                        </TbxTooltip>
                        <IconButton
                            disabled={sheet?.isDeleted || selectedMaterial?.isDeleted}
                            size="small"
                            sx={classes.button}
                            onClick={handleEditSheetClick}
                        >
                            <Edit
                                style={{ fontSize: 18 }}
                                sx={getIconClassName(sheet?.isDeleted || selectedMaterial?.isDeleted)}
                            />
                        </IconButton>
                        <TbxTooltip
                            title={t(sheet?.isDeleted ? 'Unarchive {{type}}' : 'Archive {{type}}', {
                                type: materialIs('Rotary') ? t('record') : t('sheet'),
                            })}
                            arrow
                        >
                            <IconButton
                                disabled={selectedMaterial?.isDeleted}
                                size="small"
                                sx={classes.button}
                                onClick={handleArchiveSheetClick}
                            >
                                {sheet?.isDeleted ? (
                                    <Unarchive
                                        style={{ fontSize: 18 }}
                                        sx={classes.icon}
                                    />
                                ) : (
                                    <Archive
                                        style={{ fontSize: 18 }}
                                        sx={classes.icon}
                                    />
                                )}
                            </IconButton>
                        </TbxTooltip>
                    </>
                ) : null}
            </TableCell>
            {showDeleteDialog ? (
                <ArchiveSheetDialog
                    materialType={selectedMaterial?.type}
                    mode={sheet.isDeleted ? 'unarchive' : 'archive'}
                    sheet={sheet}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={sheet.isDeleted ? confirmUnarchiveSheet : confirmArchiveSheet}
                />
            ) : null}
        </TableRow>
    )
}

SheetsTableRow.propTypes = {
    selectedMaterial: PropTypes.object,
    sheet: PropTypes.object,
    validCuttingTechnologies: PropTypes.array,
    onEditClick: PropTypes.func,
}

export default SheetsTableRow
