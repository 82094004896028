import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FiberManualRecord } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { WebStoreQuoteStatus } from '@/common/utils/Constants/Constants'

const CommonProjectStatusStyles = {
    fontWeight: '500',
    fontSize: '0.75rem',
}

const classes = {
    text: {
        color: 'text.primary',
    },
    NotCalculated: {
        color: '#F78F1E',
        ...CommonProjectStatusStyles,
    },
    Calculated: {
        color: '#007DAB',
        ...CommonProjectStatusStyles,
    },
    Ordered: {
        color: '#00BE35',
        ...CommonProjectStatusStyles,
    },
    Invoiced: {
        color: '#1EBEF7',
        ...CommonProjectStatusStyles,
    },
    Voided: {
        color: '#C62828',
        ...CommonProjectStatusStyles,
    },
    Issued: {
        color: '#0288D1',
        ...CommonProjectStatusStyles,
    },
    Lost: {
        color: '#800080',
        ...CommonProjectStatusStyles,
    },
    Cancelled: {
        color: '#E12626',
        ...CommonProjectStatusStyles,
    },
    PendingOrderConfirmation: {
        color: '#203E96',
        ...CommonProjectStatusStyles,
    },
    Rejected: {
        color: '#130F0F',
        ...CommonProjectStatusStyles,
    },
}

const ProjectStatusIcon = forwardRef(({ className, value }, ref) => {
    const { t } = useTranslation()

    let projectStatusStyle = undefined
    let label = ''
    switch (value) {
        case WebStoreQuoteStatus.NotCalculated:
            projectStatusStyle = classes.NotCalculated
            label = t('Not Calculated')
            break
        case WebStoreQuoteStatus.Calculated:
            projectStatusStyle = classes.Calculated
            label = t('Calculated')
            break
        case WebStoreQuoteStatus.Ordered:
            projectStatusStyle = classes.Ordered
            label = t('Ordered')
            break
        case WebStoreQuoteStatus.Invoiced:
            projectStatusStyle = classes.Invoiced
            label = t('Invoiced')
            break
        case WebStoreQuoteStatus.Voided:
            projectStatusStyle = classes.Voided
            label = t('Voided')
            break
        case WebStoreQuoteStatus.Lost:
            projectStatusStyle = classes.Lost
            label = t('Lost')
            break
        case WebStoreQuoteStatus.Cancelled:
            projectStatusStyle = classes.Cancelled
            label = t('Cancelled')
            break
        case WebStoreQuoteStatus.PendingOrderConfirmation:
            projectStatusStyle = classes.PendingOrderConfirmation
            label = t('Pending Order Confirmation')
            break
        case WebStoreQuoteStatus.Rejected:
            projectStatusStyle = classes.Rejected
            label = t('Rejected')
            break
        case WebStoreQuoteStatus.Issued:
            projectStatusStyle = classes.Issued
            label = t('Issued')
            break
        default:
            break
    }
    return (
        <Box sx={className}>
            {value !== undefined ? (
                <Box
                    alignItems="center"
                    data-testid="quote-status-indicator"
                    display="flex"
                    justifyContent="center"
                    sx={projectStatusStyle}
                >
                    <FiberManualRecord style={{ fontSize: 16 }} />
                    <Box
                        component={Typography}
                        px={1}
                        style={{ color: 16 }}
                        sx={classes.text}
                        variant="body2"
                    >
                        {label}
                    </Box>
                </Box>
            ) : null}
        </Box>
    )
})

ProjectStatusIcon.displayName = 'ProjectStatusIcon'

ProjectStatusIcon.propTypes = {
    className: PropTypes.object,
    value: PropTypes.oneOf([
        WebStoreQuoteStatus.NotCalculated,
        WebStoreQuoteStatus.Calculated,
        WebStoreQuoteStatus.Ordered,
        WebStoreQuoteStatus.Invoiced,
        WebStoreQuoteStatus.Voided,
        WebStoreQuoteStatus.Lost,
        WebStoreQuoteStatus.Cancelled,
    ]),
}

export default ProjectStatusIcon
