import { useTranslation } from 'react-i18next'
import { CreditCardRounded, SaveAlt } from '@mui/icons-material'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import PropTypes from 'prop-types'

import { AppBar, AppLogo } from '@/common/components'
import { QuotePaymentStatus, QuoteStatus } from '@/common/utils'
import QuotePaidStatusChip from '@/features/quotes/components/common/QuotePaidStatusChip'

const classes = {
    appBar: {
        backgroundColor: 'white',
        color: 'white',
        displayPrint: 'none',
    },
    appLogo: {
        height: 32,
        px: 1,
        py: 1,
        cursor: 'pointer',
        boxSizing: 'content-box',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        gap: 2,
    },
    menuDivider: {
        backgroundColor: 'divider',
        margin: '10px 16px',
    },
}

const DocumentsAppBar = ({
    buttonLabel = 'Export PDF',
    disabled = false,
    handleExportButton,
    handlePaymentButton,
    isExporting = false,
    isInitiatingPayment,
    isPublicDocument = false,
    paymentStatus = 'Unpaid',
    paymentsEnabled = false,
    quoteStatus,
}) => {
    const { t } = useTranslation()

    const orderAndPay = [
        QuoteStatus.Draft,
        QuoteStatus.Issued,
        QuoteStatus.PendingOrderConfirmation,
        QuoteStatus.Lost,
    ].includes(quoteStatus)

    const isPaid = paymentStatus === QuotePaymentStatus.ManualPaid || paymentStatus === QuotePaymentStatus.GatewayPaid

    return (
        <AppBar
            className={classes.appBar}
            position="sticky"
        >
            <AppLogo className={classes.appLogo} />

            <Divider
                orientation="vertical"
                sx={classes.menuDivider}
                flexItem
            />

            <Box
                id="app-bar"
                sx={classes.toolbarItems}
            >
                {isPublicDocument && paymentsEnabled ? <QuotePaidStatusChip paymentStatus={paymentStatus} /> : null}

                {isPublicDocument && paymentsEnabled && !isPaid ? (
                    <Button
                        color="secondary"
                        data-testid="order-pay-button"
                        disabled={disabled || isInitiatingPayment}
                        endIcon={<CreditCardRounded />}
                        variant="contained"
                        onClick={handlePaymentButton}
                    >
                        {isInitiatingPayment
                            ? t('Initiating payment...')
                            : orderAndPay
                              ? t('Order & Pay')
                              : t('Pay Now')}
                    </Button>
                ) : null}
                <Button
                    color="primary"
                    data-testid="export-pdf"
                    disabled={disabled || isExporting}
                    endIcon={isExporting ? <CircularProgress size={20} /> : <SaveAlt />}
                    variant="contained"
                    onClick={handleExportButton}
                >
                    {isExporting ? t('Exporting PDF...') : t(buttonLabel)}
                </Button>
            </Box>
        </AppBar>
    )
}

DocumentsAppBar.propTypes = {
    handleExportButton: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    disabled: PropTypes.bool,
    handlePaymentButton: PropTypes.func,
    isExporting: PropTypes.bool,
    isInitiatingPayment: PropTypes.bool,
    isPublicDocument: PropTypes.bool,
    paymentsEnabled: PropTypes.bool,
    paymentStatus: PropTypes.oneOf([
        QuotePaymentStatus.Unpaid,
        QuotePaymentStatus.ManualPaid,
        QuotePaymentStatus.GatewayPaid,
    ]),
    quoteStatus: PropTypes.string,
}

export default DocumentsAppBar
