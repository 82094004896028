import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditCardRounded } from '@mui/icons-material'
import { Box, Button, TableCell, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateStripeCheckoutSessionMutation } from '@/app/services/integrations'
import PaymentMethodsIcon from '@/common/icons/PaymentMethodsIcon/paymentMethodsIcon'
import { getFormattedPrice, Paths, QuotePaymentStatus, QuoteStatus } from '@/common/utils'

const TotalTableRow = ({
    colspan = [3, 6],
    currencyCode,
    document,
    locale,
    organisationId,
    paymentStatus = 'Unpaid',
    paymentsEnabled = false,
    quoteId,
    quoteStatus,
    totalAmount,
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [isInitiatingPayment, setIsInitiatingPayment] = useState(false)

    const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation()

    const orderAndPay = [
        QuoteStatus.Draft,
        QuoteStatus.Issued,
        QuoteStatus.PendingOrderConfirmation,
        QuoteStatus.Lost,
    ].includes(quoteStatus)

    const isPaid = paymentStatus === QuotePaymentStatus.ManualPaid || paymentStatus === QuotePaymentStatus.GatewayPaid

    const handlePaymentButton = useCallback(async () => {
        setIsInitiatingPayment(true)
        try {
            const stripeCheckoutSessionUrl = await createStripeCheckoutSession({
                organisationId: organisationId,
                quoteId: quoteId,
                successUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.PAYMENTS_PATHNAME}/${quoteId}/${Paths.PAYMENTS_SUCCESS_PATHNAME}`,
                cancelUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quoteId}/${document}`,
            }).unwrap()

            enqueueSnackbar('Redirecting to payment gateway...', {
                variant: 'info',
                onExited: () => {
                    window.open(stripeCheckoutSessionUrl, '_self')
                    setIsInitiatingPayment(false)
                },
            })
        } catch (error) {
            console.error('Documents', 'Payment Button Error', error)
            enqueueSnackbar('Error initiating payment', {
                variant: 'error',
            })
        }
    }, [createStripeCheckoutSession, document, enqueueSnackbar, organisationId, quoteId])

    return (
        <TableRow sx={{ verticalAlign: 'top', td: { border: 0 } }}>
            {paymentsEnabled && !isPaid ? (
                <TableCell colSpan={3}>
                    <Box width={300}>
                        <Button
                            color="secondary"
                            data-testid="order-pay-button"
                            disabled={isInitiatingPayment}
                            endIcon={<CreditCardRounded />}
                            href={`${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quoteId}/${document}`}
                            variant="contained"
                            onClick={(e) => {
                                e.preventDefault()
                                handlePaymentButton()
                            }}
                        >
                            {isInitiatingPayment
                                ? t('Initiating payment...')
                                : orderAndPay
                                  ? t('Order & Pay')
                                  : t('Pay Now')}
                        </Button>
                        <PaymentMethodsIcon
                            sx={{ mt: 1, width: 300, height: 50 }}
                            titleAccess={t('Available payment methods')}
                            inheritViewBox
                        />
                    </Box>
                </TableCell>
            ) : null}
            <TableCell
                align="right"
                colSpan={paymentsEnabled && !isPaid ? colspan[0] : colspan[1]}
                sx={{ typography: 'strong1' }}
            >
                {t('Total')}
            </TableCell>
            <TableCell
                align="right"
                colSpan={2}
                sx={{ typography: 'strong1' }}
            >
                {getFormattedPrice(totalAmount, currencyCode, locale)}
            </TableCell>
        </TableRow>
    )
}

TotalTableRow.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    colspan: PropTypes.arrayOf(PropTypes.number),
    document: PropTypes.string,
    organisationId: PropTypes.string,
    paymentsEnabled: PropTypes.bool,
    paymentStatus: PropTypes.string,
    quoteId: PropTypes.string,
    quoteStatus: PropTypes.string,
}

export default TotalTableRow
