import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ChromeReaderMode, ChromeReaderModeOutlined } from '@mui/icons-material'
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import ThumbnailUri from '@/common/components/ThumbnailUri/ThumbnailUri'
import { formatCurrency } from '@/common/helpers/formatUtilities'
import useNumberFormatter from '@/common/hooks/useNumberFormatter'
import MiscItemIcon from '@/common/icons/MiscItemIcon/MiscItemIcon'

const classes = {
    tableCell: {
        py: 1.5,
    },
    tableCellBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },
}

const QuoteReviewTableRow = ({ handleOpenNotesModal, miscItem }) => {
    const { t } = useTranslation()

    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const partHasNotes = useMemo(() => {
        const { privateNotes, vendorNotes } = miscItem
        return Boolean(vendorNotes) || Boolean(privateNotes)
    }, [miscItem])

    const units = useMemo(() => (useImperialUnits ? 'in' : 'mm'), [useImperialUnits])
    const weightUnits = useMemo(() => (useImperialUnits ? 'lb' : 'kg'), [useImperialUnits])

    const { n } = useNumberFormatter({ locale: locale })
    const { n: q } = useNumberFormatter({ locale: locale, numberOfDecimalPlaces: 0 })

    const formatNumberWithX = (str) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) => n(Number(part)))
        return formattedParts.join(' x ')
    }

    const renderItemDetails = (item) => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                <Box>
                    <Typography
                        sx={{ whiteSpace: 'break-space' }}
                        variant="body2"
                    >
                        {item?.name}
                    </Typography>

                    {/* {item?.partId ? (
                        <Typography
                            sx={{ whiteSpace: 'break-space' }}
                            variant="body2"
                        >
                            {item?.partId}
                        </Typography>
                    ) : null}

                    {item?.amortizePrice ? (
                        <Typography
                            sx={{ whiteSpace: 'break-space' }}
                            variant="body2"
                        >
                            {item?.amortizePrice}
                        </Typography>
                    ) : null} */}
                </Box>

                <Button
                    color="secondary"
                    size="small"
                    startIcon={partHasNotes ? <ChromeReaderMode /> : <ChromeReaderModeOutlined />}
                    sx={{ px: 0, width: 'fit-content' }}
                    variant="text"
                    disableRipple
                    onClick={() => handleOpenNotesModal(miscItem)}
                >
                    {t('Notes')}
                </Button>
            </Box>
        )
    }

    return (
        <TableRow key={miscItem.id}>
            <TableCell
                align="center"
                sx={classes.tableCell}
            >
                <MiscItemIcon
                    fontSize="large"
                    titleAccess={t('Miscellaneous item icon')}
                />
            </TableCell>

            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderItemDetails(miscItem)}
            </TableCell>

            {/* <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {miscItem?.category}
            </TableCell> */}

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {miscItem?.dimensions ? `${formatNumberWithX(miscItem.dimensions)} ${units}` : null}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {miscItem?.weight ? `${n(miscItem.weight)} ${weightUnits}` : null}
            </TableCell>
            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {miscItem?.productionTimePerItemSeconds ? `${n(miscItem.productionTimePerItemSeconds)} s` : null}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.tableCellBorder]}
            >
                {q(miscItem?.quantity)}
            </TableCell>
            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(miscItem?.priceAdjustment ?? 0, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Typography variant="strong2">
                    {formatCurrency(miscItem?.itemPrice ?? 0, currencyCode, locale)}
                </Typography>
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Typography variant="strong2">
                    {formatCurrency(miscItem?.linePrice ?? 0, currencyCode, locale)}
                </Typography>
            </TableCell>
        </TableRow>
    )
}

QuoteReviewTableRow.propTypes = {
    handleOpenNotesModal: PropTypes.func,
    miscItem: PropTypes.object,
}

export default QuoteReviewTableRow
