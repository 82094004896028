import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Receipt, ViewQuilt } from '@mui/icons-material'
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { selectQuote, useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/quotes'
import { selectOrganisation, selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { selectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { useNumberFormatter } from '@/common/hooks'
import { QuoteStatus } from '@/common/utils'
import PriceDetailsDialog from '@/features/quotes/components/PriceDetailsDialog/PriceDetailsDialog'
import QuoteCalculatorNestDialog from '@/features/quotes/components/QuoteCalculatorNestDialog/QuoteCalculatorNestDialog'

const classes = {
    timesSection: {
        width: '250px',
        gap: '12px',
    },
    pricePercentage: {
        fontSize: '14px',
        color: 'text.secondary',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
    },
    alignRight: {
        justifyContent: 'flex-end',
    },
    inputColumn: {
        pl: 2,
    },
    ItemDetails_Calculating: {
        gap: '12px',
    },
    clickable: {
        cursor: 'pointer',
    },
    unstyledButton: {
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '24px',
    },
}

const ItemTimes = ({ quoteItemId }) => {
    const { quoteId } = useParams()
    const paidFeatures = useSelector(selectPaidFeatures)
    const organisationId = useSelector(selectOrganisationId)
    const organisationSettings = useSelector(selectOrganisation)

    const organisationHasNestingEnabled = paidFeatures?.hasMaterialConsumptionModeOptions
    const hideTimes = useSelector(selectedQuoteStatus) === QuoteStatus.NotCalculated

    const selectedQuote = useSelector((state) => selectQuote(state, { organisationId: organisationId, quoteId }))
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const { isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [openNest, setOpenNest] = useState(false)
    const handleClickOpenNest = () => {
        setOpenNest(true)
    }
    const handleCloseNest = () => {
        setOpenNest(false)
    }

    const [priceDetailsData, setPriceDetailsData] = useState(null)
    const displayPriceDetails = (quoteItem) => {
        setPriceDetailsData(quoteItem)
    }

    const hidePriceDetails = () => {
        setPriceDetailsData(null)
    }

    const { t } = useTranslation()
    const { n } = useNumberFormatter({
        locale: organisationSettings.locale,
        numberOfDecimalPlaces: 0,
    })

    const ItemRow = ({ children }) => (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
        >
            {children}
        </Box>
    )

    return (
        <>
            <Box
                alignItems="flex-start"
                display="flex"
                flex="1 0 auto"
                flexDirection="column"
                justifyContent="space-around"
                sx={classes.timesSection}
            >
                <ItemRow>
                    <Typography
                        data-testid="quote-item-times-section-title"
                        sx={classes.bold}
                    >
                        {t('Unit times')}
                    </Typography>
                    <Button
                        data-testid="nqp-quote-item-unit-times-details"
                        endIcon={<Receipt />}
                        size="small"
                        variant="text"
                        onClick={() => displayPriceDetails(quoteItem)}
                    >
                        {t('Details')}
                    </Button>
                </ItemRow>

                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-total-cutting-time-label"
                        sx={classes.itemColumn}
                    >
                        {t('Total cutting')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {hideTimes || isCalculating || isFetchingQuote
                            ? String.fromCharCode(8212)
                            : n(
                                  (quoteItem?.setupTimePerItemSeconds ?? 0) +
                                      (quoteItem?.sheetChangeTimePerItemSeconds ?? 0) +
                                      (quoteItem?.runtime ?? 0) +
                                      (quoteItem?.unloadTimePerItemSeconds ?? 0)
                              ) + 's'}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-total-folding-time-label"
                        sx={classes.itemColumn}
                    >
                        {t('Total folding')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {hideTimes || isCalculating || isFetchingQuote || quoteItem?.foldingTotalTimePerItemSeconds <= 0
                            ? String.fromCharCode(8212)
                            : n(quoteItem?.foldingTotalTimePerItemSeconds) + 's'}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-secondary-processes-time-label"
                        sx={classes.itemColumn}
                    >
                        {t('Secondary processes')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {hideTimes ||
                        isCalculating ||
                        isFetchingQuote ||
                        quoteItem?.secondaryProcessTotalTimePerItemSeconds <= 0
                            ? String.fromCharCode(8212)
                            : n(quoteItem?.secondaryProcessTotalTimePerItemSeconds) + 's'}
                    </Typography>
                </ItemRow>
                <Divider
                    orientation="horizontal"
                    flexItem
                />
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-time-per-unit-label"
                        sx={[classes.itemColumn, classes.bold]}
                    >
                        {t('Time per unit')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight, classes.bold]}>
                        {hideTimes || isCalculating || isFetchingQuote
                            ? String.fromCharCode(8212)
                            : n(
                                  (quoteItem?.setupTimePerItemSeconds ?? 0) +
                                      (quoteItem?.sheetChangeTimePerItemSeconds ?? 0) +
                                      (quoteItem?.runtime ?? 0) +
                                      (quoteItem?.unloadTimePerItemSeconds ?? 0) +
                                      (quoteItem?.foldingTotalTimePerItemSeconds ?? 0) +
                                      (quoteItem?.secondaryProcessTotalTimePerItemSeconds ?? 0)
                              ) + 's'}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-units-per-sheet-label"
                        sx={classes.bold}
                    >
                        {t('Units per sheet')}
                    </Typography>
                    <Tooltip
                        sx={[classes.itemColumn, classes.alignRight]}
                        title={quoteItem?.nestImageUri ? t('View nest') : t('No nest available')}
                    >
                        <Box sx={[classes.itemColumn, classes.alignRight]}>
                            {!hideTimes && (quoteItem?.partsPerSheet || quoteItem?.partsPerSheet !== 0) ? (
                                quoteItem?.nestImageUri ? (
                                    <IconButton
                                        aria-label="viewNest"
                                        color="primary"
                                        data-testid="nqp-quote-item-view-nest"
                                        size="small"
                                        onClick={handleClickOpenNest}
                                    >
                                        <ViewQuilt />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        aria-label="noNest"
                                        data-testid="nqp-quote-item-no-nest"
                                        size="small"
                                    >
                                        <ViewQuilt />
                                    </IconButton>
                                )
                            ) : (
                                ''
                            )}
                        </Box>
                    </Tooltip>

                    <Typography sx={classes.bold}>{!hideTimes ? quoteItem?.partsPerSheet : null}</Typography>
                </ItemRow>
            </Box>

            <QuoteCalculatorNestDialog
                handleCloseNest={handleCloseNest}
                nestImageUri={quoteItem?.nestImageUri}
                openNest={openNest}
                organisationNestingEnabled={organisationHasNestingEnabled}
                title={quoteItem?.name}
            />
            <PriceDetailsDialog
                open={!!priceDetailsData}
                quoteItem={priceDetailsData}
                quoteOverrides={selectedQuote?.quoteOverrides}
                taxRate={selectedQuote?.taxRate}
                onClose={hidePriceDetails}
            />
        </>
    )
}

ItemTimes.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemTimes)
