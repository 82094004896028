import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

const FormInputText = ({
    className = '',
    color = 'primary',
    control,
    disabled = false,
    inputProps = {},
    inputType = 'text',
    label = '',
    name,
    rules = {},
    shouldUnregister = false,
    size = 'medium',
    variant = 'outlined',
}) => {
    const { t } = useTranslation()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                <TextField
                    className={className}
                    color={color}
                    disabled={disabled}
                    error={!!fieldState.error}
                    helperText={t(fieldState.error?.message)}
                    inputProps={inputProps}
                    label={label}
                    size={size}
                    type={inputType}
                    value={value || ''}
                    variant={variant}
                    fullWidth
                    onBlur={onBlur}
                    onChange={onChange}
                />
            )}
            rules={rules}
            shouldUnregister={shouldUnregister}
        />
    )
}

FormInputText.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    inputType: PropTypes.string,
    label: PropTypes.string,
    rules: PropTypes.object,
    shouldUnregister: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
}

export default FormInputText
