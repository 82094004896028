import { api } from './api'

const apiVersion = 'v2'

export const cuttingTechnologiesApi = api
    .enhanceEndpoints({
        addTagTypes: ['CuttingTechnologies'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCuttingTechnologies: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies`,
                    params,
                }),
                transformResponse: (response) => {
                    delete response.$type

                    return response
                },
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'CuttingTechnologies', id: 'LIST' },
                              ...(Object.keys(result).map((cuttingTechnologyId) => ({
                                  type: 'CuttingTechnologies',
                                  id: cuttingTechnologyId,
                              })) ?? []),
                          ]
                        : [{ type: 'CuttingTechnologies', id: 'LIST' }],
            }),

            getCuttingTechnology: builder.query({
                query: ({ cuttingTechnologyId, organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/${cuttingTechnologyId}`,
                }),
                providesTags: (_result, _error, arg) => [{ type: 'CuttingTechnologies', id: arg.cuttingTechnologyId }],
            }),

            getCuttingTechnologyMaterials: builder.query({
                query: ({ cuttingTechnologyId, organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/${cuttingTechnologyId}/materials`,
                    method: 'GET',
                }),
                transformResponse: (response) => {
                    return response?.sort((a, b) => {
                        if (a.materialName?.toLocaleLowerCase() < b.materialName?.toLocaleLowerCase()) {
                            return -1
                        }
                        if (a.materialName?.toLocaleLowerCase() > b.materialName?.toLocaleLowerCase()) {
                            return 1
                        }
                        return 0
                    })
                },
            }),

            getMaterialThicknesses: builder.query({
                query: ({ cuttingTechnologyId, materialId, organisationId, params }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/${cuttingTechnologyId}/materials/${materialId}/thicknesses`,
                    method: 'GET',
                    params,
                }),
            }),
            getThicknessSheets: builder.query({
                query: ({ cuttingTechnologyId, materialId, organisationId, params }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/${cuttingTechnologyId}/materials/${materialId}/thicknesses/sheets`,
                    method: 'GET',
                    params,
                }),
            }),

            createCuttingTechnology: builder.mutation({
                query: ({ cuttingTechnology, organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/`,
                    method: 'POST',
                    body: cuttingTechnology,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'CuttingTechnologies', id: arg.cuttingTechnology.cuttingTechnologyId },
                    { type: 'CuttingTechnologies', id: 'LIST' },
                ],
            }),
            updateCuttingTechnology: builder.mutation({
                query: ({ cuttingTechnology, organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/cutting-technologies/${cuttingTechnology.cuttingTechnologyId}`,
                    method: 'PUT',
                    body: cuttingTechnology,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'CuttingTechnologies', id: arg.cuttingTechnology.cuttingTechnologyId },
                    { type: 'CuttingTechnologies', id: 'LIST' },
                ],
            }),

            deleteCuttingTechnology: builder.mutation({
                query: ({ cuttingTechnologyId, organisationId }) => ({
                    url: `/organisations/${organisationId}/cutting-technology/${cuttingTechnologyId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'CuttingTechnologies', id: 'LIST' },
                    { type: 'CuttingTechnologies', id: arg.cuttingTechnologyId },
                ],
            }),
        }),
    })

export const {
    useCreateCuttingTechnologyMutation,
    useDeleteCuttingTechnologyMutation,
    useGetCuttingTechnologiesQuery,
    useGetCuttingTechnologyQuery,
    useLazyGetCuttingTechnologyMaterialsQuery,
    useLazyGetMaterialThicknessesQuery,
    useLazyGetThicknessSheetsQuery,
    useUpdateCuttingTechnologyMutation,
} = cuttingTechnologiesApi
