import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CloudUploadOutlined, DeleteForeverRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import clm from 'country-locale-map'
import { codes as currencyCodes } from 'currency-codes'
import { default as localeCodes } from 'locale-codes'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'

import { useUpdateOrganisationMutation, useUploadOrganisationLogoMutation } from '@/app/services/organisation'
import { selectOrganisation, selectOrganisationId, setOrganisation } from '@/app/slices/organisationSlice'
import { DashedBorderContainer, FileUploader, OrganisationLogo, RichContentEditor } from '@/common/components'
import AutoCadVersionSelect from '@/common/components/AutoCadVersionSelect/AutoCadVersionSelect'
import CurrencyInput from '@/common/components/CurrencyInput/CurrencyInput'
import DrawingFileTypeSelect from '@/common/components/DrawingFileTypeSelect/DrawingFileTypeSelect'
import LanguageSelect from '@/common/components/LanguageSelect/LanguageSelect'
import SettingsToolbar from '@/common/components/MainAppBar/SettingsToolbar/SettingsToolbar'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    DEFAULT_CONTACT_DETAILS,
    DEFAULT_PAYMENT_DETAILS,
    DEFAULT_QUOTE_FOOTER,
    fractionToPercentage,
    getSampleFormattedCurrencyString,
    percentageToFraction,
    PROFORMA_INVOICE_NOTES,
} from '@/common/utils'
import FormInputAddress from '@/features/web-store/components/shared/FormComponents/FormInputAddress'

import TaxRates from './TaxRates'

const classes = {
    uploadActionContainer: {
        flexDirection: 'column',
        borderRadius: '8px',
        border: 'dashed 1px',
        borderColor: 'primary.main',
        padding: '16px 8px',
        textAlign: 'center',
        height: '210px',
    },
    dropzoneLabel: {
        color: 'text.primary',
        marginLeft: '6px',
    },
    logoUploadInstructions: {
        color: 'text.secondary',
        marginTop: 1,
        textAlign: 'center',
    },
    logoGridItem: {
        height: 1,
    },
    logoPreviewContainer: {
        width: '100%',
        flexDirection: 'column',
        borderRadius: '8px',
        border: 'solid 1px',
        borderColor: (theme) => theme.palette.grey[300],
        padding: '16px 8px',
        textAlign: 'center',
        position: 'relative',
    },
    logoPreview: {
        maxWidth: '100%',
        flexShrink: 0,
    },
    deleteLogoButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}

const defaultpickupAddress = 'View our website for our current address'

const Settings = ({ onSettingsUpdateError, onSettingsUpdated }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const [updateOrganisation, { isError: isUpdatingOrgError, isLoading: isUpdatingOrg }] =
        useUpdateOrganisationMutation()
    const [uploadOrganisationLogo, { isError: isUploadingLogoError, isLoading: isUploadingLogo }] =
        useUploadOrganisationLogoMutation()

    const [localeInputValue, setLocaleInputValue] = useState('')
    const [loadingLocale, setLoadingLocale] = useState(false)
    const [localeOptions, setLocaleOptions] = useState([])

    const countryList = useMemo(() => {
        return clm.getAllCountries()
    }, [])

    const getDefaultValues = useMemo(
        () => ({
            contactDetails: organisation?.contactDetails,
            currencyCode: organisation?.currencyCode,
            defaultDrawingFileType: organisation?.defaultDrawingFileType,
            defaultDrawingFileVersion: organisation?.defaultDrawingFileVersion,
            hourlyRate: organisation?.hourlyRate,
            invoiceNumberPrefix: organisation?.invoiceNumberPrefix,
            country: organisation?.country,
            language: organisation?.language,
            locale: organisation?.locale,
            logo: null,
            logoUri: organisation?.logoUri,
            minimumQuoteChargePrice: organisation?.minimumQuoteChargePrice,
            name: organisation?.name,
            nestingAlgorithmToUse: organisation?.nestingAlgorithmToUse,
            nextInvoiceNumber: organisation?.nextInvoiceNumber,
            nextProjectNumber: organisation?.nextProjectNumber,
            nextWorkOrderNumber: organisation?.nextWorkOrderNumber,
            paymentNotes: organisation?.paymentNotes ?? '',
            proformaInvoiceNotes: organisation?.proformaInvoiceNotes,
            projectNumberPrefix: organisation?.projectNumberPrefix,
            quoteFooterText: organisation?.quoteFooterText ?? '',
            rectangularNestingForSingularParts: organisation?.rectangularNestingForSingularParts,
            setupMode: organisation?.setupMode,
            setupTimeSeconds: organisation?.setupTimeSeconds,
            sheetChangeMode: organisation?.sheetChangeMode,
            sheetChangeTimeSeconds: organisation?.sheetChangeTimeSeconds,
            sheetMarkupPercentage: fractionToPercentage(organisation?.sheetMarkupPercentage) ?? '',
            taxRate: fractionToPercentage(organisation?.taxRate) ?? '',
            workOrderNumberPrefix: organisation?.workOrderNumberPrefix,
            pickupAddress: organisation?.pickupAddress || '',
            showAccelerationSettings: organisation?.showAccelerationSettings,
            calculateTaxOnSubtotal: organisation?.calculateTaxOnSubtotal,
        }),
        [organisation]
    )

    const {
        control,
        formState: { isDirty, isSubmitting, isValid },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        defaultValues: getDefaultValues,
    })

    useEffect(() => {
        reset(getDefaultValues)
    }, [organisation, getDefaultValues, reset])

    const { showDownloadPdfs, showProductionPDFs } = useToolBoxTreatments()

    const fileUploaderRef = useRef(null)

    const getLabelForLocale = (locale) => locale.name + (locale.location ? ' - ' + locale.location : '')

    const localeList = useMemo(() => {
        const dict = {}
        localeCodes.all
            .filter((locale) => locale.tag && locale.tag.split('-').length <= 7)
            .forEach((locale) => {
                const label = getLabelForLocale(locale)
                if (!dict[label]) {
                    dict[label] = { label: label, key: locale.tag }
                }
            })
        return Object.values(dict)
    }, [localeCodes])

    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            let filteredList = localeList
            if (text) {
                filteredList = localeList.filter((locale) =>
                    locale.label.toLocaleLowerCase().includes(text.toLocaleLowerCase())
                )
            }
            callback(filteredList)
        }, 500),
        []
    )

    const handleSelectFileButton = () => {
        if (fileUploaderRef.current) {
            fileUploaderRef.current.value = null
            fileUploaderRef.current.click()
        }
    }

    const handleClearFileButton = () => {
        setValue('logo', null, { shouldDirty: true })
    }

    const handleFilesAccepted = (files) => {
        if (files.length !== 1) {
            return
        }
        setValue('logo', files[0], { shouldDirty: true })
    }

    const handleLocaleChange = (event, value) => {
        if (value) {
            setValue('locale', value.key, { shouldDirty: true })
            setLocaleInputValue(value.label)
        }
    }

    const handleCurrencyChange = (event, value) => {
        if (value) {
            setValue('currencyCode', value, { shouldValidate: true, shouldDirty: true })
        }
    }

    const updateOrganisationSettings = async (organisationData) => {
        const pickupAddress =
            typeof organisationData.pickupAddress === 'string'
                ? organisationData.pickupAddress
                : organisationData.pickupAddress?.description || ''
        try {
            const updatedOrganisation = await updateOrganisation({
                organisationDto: {
                    ...organisation,
                    name: organisationData.name,
                    country: organisationData.country,
                    locale: organisationData.locale,
                    language: organisationData.language,
                    exportFileType: organisationData.defaultDrawingFileType,
                    defaultDrawingFileVersion: organisationData.defaultDrawingFileVersion,
                    hourlyRate: organisationData.hourlyRate,
                    taxRate: percentageToFraction(organisationData.taxRate),
                    setupTimeSeconds: organisationData.setupTimeSeconds,
                    sheetChangeTimeSeconds: organisationData.sheetChangeTimeSeconds,
                    setupMode: organisationData.setupMode,
                    sheetChangeMode: organisationData.sheetChangeMode,
                    projectNumberPrefix: organisationData.projectNumberPrefix,
                    nextProjectNumber: organisationData.nextProjectNumber,
                    minimumQuoteChargePrice: organisationData.minimumQuoteChargePrice,
                    sheetMarkupPercentage: percentageToFraction(organisationData.sheetMarkupPercentage),
                    quoteFooterText: organisationData.quoteFooterText,
                    paymentNotes: organisationData.paymentNotes,
                    currencyCode: organisationData.currencyCode,
                    nestingAlgorithmToUse: organisationData.nestingAlgorithmToUse,
                    rectangularNestingForSingularParts: organisationData.rectangularNestingForSingularParts,
                    contactDetails: organisationData.contactDetails,
                    proformaInvoiceNotes: organisationData.proformaInvoiceNotes,
                    invoiceNumberPrefix: organisationData.invoiceNumberPrefix,
                    workOrderNumberPrefix: organisationData.workOrderNumberPrefix,
                    nextInvoiceNumber: organisationData.nextInvoiceNumber,
                    nextWorkOrderNumber: organisationData.nextWorkOrderNumber,
                    pickupAddress: pickupAddress,
                    showAccelerationSettings: organisationData.showAccelerationSettings,
                    calculateTaxOnSubtotal: organisationData.calculateTaxOnSubtotal,
                },
            }).unwrap()

            dispatch(setOrganisation(updatedOrganisation))

            if (organisationData.logo) {
                const updatedLogo = await uploadOrganisationLogo({
                    organisationId,
                    logo: organisationData.logo,
                }).unwrap()
                setValue('logo', null)
                dispatch(setOrganisation({ ...organisation, logoUri: updatedLogo }))
            }

            onSettingsUpdated()
        } catch (error) {
            if (isUpdatingOrgError) {
                reset(getDefaultValues)
                dispatch(setOrganisation(organisation))
                onSettingsUpdateError()
            }
            if (isUploadingLogoError) {
                onSettingsUpdateError('uploadImageError')
            }
        }
    }

    const handleLocaleInputChange = (event) => {
        setLoadingLocale(true)
        setLocaleOptions([])
        setLocaleInputValue(event.target.value)
    }

    useEffect(() => {
        getOptionsDelayed(localeInputValue, (filteredOptions) => {
            setLoadingLocale(false)
            setLocaleOptions(filteredOptions)
        })
    }, [localeInputValue, getOptionsDelayed])

    return (
        <>
            <SettingsToolbar
                enableCancelButton={isDirty ? !isSubmitting : null}
                enableSaveButton={isDirty && !isSubmitting ? isValid : null}
                handleCancelClick={() => reset()}
                handleSaveClick={handleSubmit(updateOrganisationSettings)}
                isLoading={isUpdatingOrg || isUploadingLogo}
                title={t('Settings / Organisation')}
            />

            <Grid
                px={2}
                spacing={4}
                container
            >
                <Grid xs={12}>
                    <Typography
                        data-testid="organisation-settings-title"
                        mb={4}
                        sx={classes.title}
                        variant="h4"
                    >
                        {t('Organisation')}
                    </Typography>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                InputLabelProps={{
                                    'data-testid': 'organisation-name-label',
                                }}
                                inputProps={{
                                    'data-testid': 'organisation-name-input',
                                }}
                                label={t('Organisation name') + ' *'}
                                value={value}
                                autoFocus
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />
                </Grid>

                {/* DEFAULTS */}
                <Grid
                    columnSpacing={3}
                    rowSpacing={3}
                    xs={12}
                    container
                >
                    <Grid xs={12}>
                        <Typography
                            data-testid="organisation-defaults-setting"
                            id="defaults"
                            sx={classes.title}
                            variant="h6"
                        >
                            {t('Defaults')}
                        </Typography>
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="defaultDrawingFileType"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <DrawingFileTypeSelect
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="defaultDrawingFileVersion"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <AutoCadVersionSelect
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="country"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <Autocomplete
                                    className={classes.autoComplete}
                                    disableClearable={true}
                                    getOptionLabel={(option) => option.name || ''}
                                    isOptionEqualToValue={(option) => {
                                        return option.name === value
                                    }}
                                    options={countryList}
                                    renderInput={(params) => (
                                        <TextField
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ''}
                                            {...params}
                                            InputLabelProps={{
                                                sx: classes.inputLabel,
                                                'data-testid': 'organisation-country-label',
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                'data-testid': 'organisation-country-input',
                                            }}
                                            label={t('Country')}
                                            fullWidth
                                        />
                                    )}
                                    value={countryList.find((country) => country.name === value) || null}
                                    onBlur={onBlur}
                                    onChange={(e, newValue) => {
                                        onChange(newValue.name)
                                    }}
                                />
                            )}
                            rules={{ required: t('Country is required') }}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="language"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <LanguageSelect
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="locale"
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    disableClearable={true}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option) => {
                                        return option.tag !== undefined
                                            ? option.name + (option.location ? ' - ' + option.location : '')
                                            : option.label
                                    }}
                                    isOptionEqualToValue={(option) => {
                                        return option.key === value
                                    }}
                                    loading={loadingLocale}
                                    options={localeOptions}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{
                                                'data-testid': 'organisation-locality-label',
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                'data-testid': 'organisation-locality-input',
                                            }}
                                            label={t('Locality')}
                                            value={localeInputValue}
                                            fullWidth
                                            onChange={handleLocaleInputChange}
                                        />
                                    )}
                                    value={localeCodes.getByTag(value)}
                                    onBlur={handleLocaleChange}
                                    onChange={handleLocaleChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="currencyCode"
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    disableClearable={true}
                                    options={currencyCodes()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{
                                                'data-testid': 'organisation-currency-label',
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                'data-testid': 'organisation-currency-input',
                                            }}
                                            label={t('Currency')}
                                            value={value}
                                            fullWidth
                                        />
                                    )}
                                    value={value}
                                    onBlur={handleCurrencyChange}
                                    onChange={handleCurrencyChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <TextField
                            InputLabelProps={{
                                'data-testid': 'organisation-currency-preview-label',
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            inputProps={{
                                sx: classes.selectInput,
                                'data-testid': 'organisation-currency-preview',
                            }}
                            label={t('Currency preview')}
                            value={
                                getSampleFormattedCurrencyString(getValues('locale'), getValues('currencyCode')) ?? ''
                            }
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="showAccelerationSettings"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    checked={value}
                                    control={<Switch />}
                                    data-testid="organisation-show-acceleration-settings"
                                    label={t('Show cutting technology acceleration settings')}
                                    labelPlacement="start"
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            color: 'text.secondary',
                                        },
                                    }}
                                    sx={{ m: 0, justifyContent: 'space-between', width: 1 }}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* NESTING */}
                <Grid
                    columnSpacing={3}
                    rowSpacing={1}
                    xs={12}
                    container
                >
                    <Grid xs={12}>
                        <Typography
                            data-testid="organisation-nesting-setting"
                            id="nesting"
                            variant="h6"
                        >
                            {t('Nesting')}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="nestingAlgorithmToUse"
                            render={({ field: { onChange, value } }) => (
                                <RadioGroup
                                    data-testid="organisation-nesting-algorithm-to-use"
                                    value={value}
                                    onChange={(e, value) => {
                                        onChange(value)
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        data-testid="organisation-toolpath-nesting-option"
                                        key="Toolpath"
                                        label={t('True Shape nesting')}
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                color: 'text.secondary',
                                            },
                                        }}
                                        value="Toolpath"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="rectangularNestingForSingularParts"
                                                render={({ field: { onChange, value } }) => (
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                                                        <Checkbox
                                                            checked={value || false}
                                                            color="primary"
                                                            disabled={getValues('nestingAlgorithmToUse') !== 'Toolpath'}
                                                            onChange={onChange}
                                                        />
                                                    </Box>
                                                )}
                                            />
                                        }
                                        data-testid="organisation-rectangular-nesting-for-singular-parts-option"
                                        label={t('Use Rectangular Bounds nesting for quantities of 1')}
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                color: 'text.secondary',
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        data-testid="organisation-rectangular-bounds-nesting-option"
                                        key="Rectangular"
                                        label={t('Rectangular Bounds nesting')}
                                        slotProps={{
                                            typography: {
                                                variant: 'body1',
                                                color: 'text.secondary',
                                            },
                                        }}
                                        value="Rectangular"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </Grid>
                </Grid>

                {/* TAXES */}
                <Grid
                    columnSpacing={3}
                    rowSpacing={3}
                    xs={12}
                    container
                >
                    <TaxRates control={control} />
                </Grid>

                {/* QUOTE AND DOCUMENTS */}
                <Grid
                    columnSpacing={3}
                    rowSpacing={3}
                    xs={12}
                    container
                >
                    <Grid xs={12}>
                        <Typography
                            data-testid="organisation-quote-setting"
                            id="quote-and-documents"
                            sx={classes.title}
                            variant="h6"
                        >
                            {t('Quote and documents')}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="minimumQuoteChargePrice"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <CurrencyInput
                                    error={fieldState.error?.message ? true : false}
                                    helperText={fieldState.error?.message || ''}
                                    InputLabelProps={{
                                        'data-testid': 'minimum-quote-price-label',
                                    }}
                                    inputProps={{
                                        'data-testid': 'minimum-quote-price-field',
                                    }}
                                    label={t('Minimum quote price')}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ min: 0 }}
                        />
                    </Grid>

                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="projectNumberPrefix"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    InputLabelProps={{
                                        'data-testid': 'organisation-quote-number-prefix-label',
                                    }}
                                    inputProps={{
                                        'data-testid': 'organisation-quote-number-prefix-input',
                                    }}
                                    label={t('$t(Quote) number prefix')}
                                    value={value}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ maxLength: 5 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="nextProjectNumber"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    InputLabelProps={{
                                        'data-testid': 'organisation-next-quote-number-label',
                                    }}
                                    inputProps={{
                                        'data-testid': 'organisation-next-quote-number-label',
                                    }}
                                    label={t('Next $t(quote) number')}
                                    type="number"
                                    value={value}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: true, min: 0 }}
                        />
                    </Grid>
                    <Grid
                        mt={-2}
                        xs={12}
                    >
                        <Typography
                            color="text.secondary"
                            variant="small"
                        >
                            {t(
                                'Define the prefix and the number to be used when creating your next quote. The number will automatically increment with each new quote you create.'
                            )}
                        </Typography>
                    </Grid>
                    {showDownloadPdfs ? (
                        <Grid xs={6}>
                            <Controller
                                control={control}
                                name="invoiceNumberPrefix"
                                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                    <TextField
                                        error={fieldState.invalid}
                                        helperText={fieldState.error?.message || ''}
                                        InputLabelProps={{
                                            'data-testid': 'organisation-tax-invoice-prefix-number-label',
                                        }}
                                        inputProps={{
                                            sx: { ...classes.textInput, ...classes.alignEnd },
                                            'data-testid': 'organisation-tax-invoice-prefix-number-input',
                                        }}
                                        label={t('Tax invoice prefix')}
                                        value={value}
                                        fullWidth
                                        onBlur={onBlur}
                                        onChange={onChange}
                                    />
                                )}
                                rules={{ required: true, maxLength: 5 }}
                            />
                        </Grid>
                    ) : null}
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="nextInvoiceNumber"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    InputLabelProps={{
                                        'data-testid': 'organisation-next-invoice-number-label',
                                    }}
                                    inputProps={{
                                        sx: { ...classes.textInput, ...classes.alignEnd },
                                        'data-testid': 'organisation-next-invoice-number-input',
                                    }}
                                    label={t('Next invoice number')}
                                    type="number"
                                    value={value}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: true, min: 0 }}
                        />
                    </Grid>
                    <Grid
                        mt={-2}
                        xs={12}
                    >
                        <Typography
                            color="text.secondary"
                            variant="small"
                        >
                            {t(
                                'Define the prefix and the number to be used when creating your next invoice. The number will automatically increment with each quote you mark as invoiced.'
                            )}
                        </Typography>
                    </Grid>
                    {showProductionPDFs ? (
                        <Grid xs={6}>
                            <Controller
                                control={control}
                                name="workOrderNumberPrefix"
                                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                    <TextField
                                        error={fieldState.invalid}
                                        helperText={fieldState.error?.message || ''}
                                        InputLabelProps={{
                                            'data-testid': 'organisation-work-order-prefix-label',
                                        }}
                                        inputProps={{
                                            sx: { ...classes.textInput, ...classes.alignEnd },
                                            'data-testid': 'organisation-work-order-prefix-input',
                                        }}
                                        label={t('Work order prefix')}
                                        value={value}
                                        fullWidth
                                        onBlur={onBlur}
                                        onChange={onChange}
                                    />
                                )}
                                rules={{ required: true, maxLength: 5 }}
                            />
                        </Grid>
                    ) : null}
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="nextWorkOrderNumber"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    InputLabelProps={{
                                        'data-testid': 'organisation-next-work-order-number-label',
                                    }}
                                    inputProps={{
                                        sx: { ...classes.textInput, ...classes.alignEnd },
                                        'data-testid': 'organisation-next-work-order-number-input',
                                    }}
                                    label={t('Next work order number')}
                                    type="number"
                                    value={value}
                                    fullWidth
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: true, min: 0 }}
                        />
                    </Grid>
                    <Grid
                        mt={-2}
                        xs={12}
                    >
                        <Typography
                            color="text.secondary"
                            variant="small"
                        >
                            {t(
                                'Define the prefix and number to be used when creating your next work order. The number will automatically increment with each quote you convert to the status of Ordered.'
                            )}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <FormInputAddress
                            control={control}
                            label={t('Pickup address')}
                            name="pickupAddress"
                            placeholder={t(defaultpickupAddress)}
                            variant="standard"
                        />
                    </Grid>
                    <Grid
                        mt={-2}
                        xs={12}
                    >
                        <Typography
                            color="text.secondary"
                            variant="small"
                        >
                            {t('Please provide a pickup address to display when orders are marked for pickup')}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="quoteFooterText"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <RichContentEditor
                                    dataTestId="organisation-quote-footer-text"
                                    label={t('Quote footer')}
                                    placeholder={DEFAULT_QUOTE_FOOTER}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <Typography
                            color="text.secondary"
                            mt={1}
                            variant="small"
                        >
                            {t('Displayed on the $t(quote) PDF')}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="contactDetails"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <RichContentEditor
                                    dataTestId="organisation-contact-details"
                                    label={t('Contact details')}
                                    placeholder={DEFAULT_CONTACT_DETAILS}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <Typography
                            color="text.secondary"
                            mt={1}
                            variant="small"
                        >
                            {t('Displayed in the footer of the $t(quote) and invoice PDFs')}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            control={control}
                            name="paymentNotes"
                            render={({ field: { onBlur, onChange, value } }) => (
                                <RichContentEditor
                                    dataTestId="organisation-payment-notes"
                                    label={t('Terms and payment advice')}
                                    placeholder={DEFAULT_PAYMENT_DETAILS}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <Typography
                            color="text.secondary"
                            mt={1}
                            variant="small"
                        >
                            {t('Displayed in the footer of the $t(quote) and invoice PDFs')}
                        </Typography>
                    </Grid>

                    {showDownloadPdfs ? (
                        <Grid xs={12}>
                            <Controller
                                control={control}
                                name="proformaInvoiceNotes"
                                render={({ field: { onBlur, onChange, value } }) => (
                                    <RichContentEditor
                                        dataTestId="organisation-proforma-invoice-notes"
                                        label={t('Proforma invoice message')}
                                        placeholder={PROFORMA_INVOICE_NOTES}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                    />
                                )}
                            />
                            <Typography
                                color="text.secondary"
                                mt={1}
                                variant="small"
                            >
                                {t('Displayed under the header of the proforma invoice PDF')}
                            </Typography>
                        </Grid>
                    ) : undefined}
                </Grid>

                {/* LOGO */}
                <Grid
                    columnSpacing={2}
                    rowSpacing={3}
                    xs={12}
                    container
                >
                    <Grid xs={12}>
                        <Typography
                            data-testid="organisation-logo-setting"
                            id="logo"
                            sx={classes.title}
                            variant="h6"
                        >
                            {t('Logo')}
                        </Typography>
                    </Grid>

                    <Grid xs={7}>
                        <FileUploader
                            acceptedFileTypes={{
                                'image/bmp': ['.bmp'],
                                'image/gif': ['.gif'],
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpg', '.jpeg'],
                            }}
                            labelText={t('Drag and drop your organisation logo here or browse for a file')}
                            ref={fileUploaderRef}
                            singleFileUpload={true}
                            onFilesAccepted={handleFilesAccepted}
                        >
                            <DashedBorderContainer sx={classes.uploadActionContainer}>
                                <IconButton
                                    color="primary"
                                    disableRipple
                                    onClick={handleSelectFileButton}
                                >
                                    <CloudUploadOutlined style={{ fontSize: 64 }} />
                                </IconButton>
                                <Box>
                                    <Typography
                                        sx={classes.dropzoneLabel}
                                        variant="body2"
                                    >
                                        <Trans t={t}>
                                            Drag and drop your image <strong>here</strong> or browse.
                                        </Trans>
                                    </Typography>
                                </Box>
                                <Typography
                                    align="center"
                                    sx={classes.logoUploadInstructions}
                                    variant="caption"
                                >
                                    {t('Support for PNG, JPG and JPEG. Max file size: 1MB')}
                                </Typography>
                            </DashedBorderContainer>
                        </FileUploader>
                    </Grid>
                    <Grid xs={5}>
                        <Box sx={classes.logoGridItem}>
                            <DashedBorderContainer sx={classes.logoPreviewContainer}>
                                {getValues('logo') ? (
                                    <>
                                        <OrganisationLogo
                                            organisationId={organisation.organisationId}
                                            organisationLogoUri={URL.createObjectURL(getValues('logo'))}
                                            wrapperHeight={176}
                                            wrapperWidth={1}
                                        />
                                        <IconButton
                                            color="secondary"
                                            sx={classes.deleteLogoButton}
                                            onClick={handleClearFileButton}
                                        >
                                            <DeleteForeverRounded />
                                        </IconButton>
                                    </>
                                ) : organisation.logoUri ? (
                                    <OrganisationLogo
                                        organisationId={organisation.organisationId}
                                        organisationLogoUri={organisation.logoUri}
                                        wrapperHeight={176}
                                        wrapperWidth={1}
                                    />
                                ) : (
                                    <OrganisationLogo
                                        organisationId={organisation.organisationId}
                                        wrapperHeight={176}
                                        wrapperWidth={1}
                                    />
                                )}
                            </DashedBorderContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
Settings.propTypes = {
    onSettingsUpdated: PropTypes.func,
    onSettingsUpdateError: PropTypes.func,
}

export default Settings
