import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrentUser } from '@/app/slices/appSlice'
import { Input, SelectOption } from '@/common/components'

const classes = {
    organisationSelectContainer: {
        width: '100%',
        '& .MuiInputLabel-root': {
            margin: 0,
        },
    },
}

const OrganisationSelect = ({
    className,
    disabled = false,
    inputProps,
    labelPosition = 'top',
    onChange,
    value = '',
}) => {
    const { t } = useTranslation()

    const currentUser = useSelector(selectCurrentUser)

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const selectedOrganisation = event.target.value
        const organisationIndex = currentUser.organisations.findIndex((o) => o.organisationId === selectedOrganisation)
        const newSelectedOrganisation = currentUser.organisations[organisationIndex]

        setSelectedValue(newSelectedOrganisation.organisationId)

        if (typeof onChange === 'function') {
            onChange(newSelectedOrganisation.organisationId)
        }
    }

    return (
        <Box sx={classes.organisationSelectContainer}>
            <Input
                className={className}
                disabled={disabled}
                fullWidth={true}
                inputProps={inputProps}
                labelPosition={labelPosition}
                labelText={t('Organisation')}
                textAlign="left"
                type="select"
                value={selectedValue ?? ''}
                onChange={handleChange}
            >
                {currentUser.organisations.map((org) => {
                    return (
                        <SelectOption
                            key={org.organisationId}
                            value={org.organisationId}
                        >
                            {org.name}
                        </SelectOption>
                    )
                })}
            </Input>
        </Box>
    )
}

OrganisationSelect.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    labelPosition: PropTypes.oneOf(['start', 'end', 'bottom', 'top']),
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}

export default OrganisationSelect
