import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LocalShippingRounded, MyLocationRounded } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectStoreSettings } from '@/app/services/organisation'
import { selectIsContactLoggedIn, selectIsGuestLoggedIn } from '@/app/slices/web-store/webStoreAuthSlice'

import FormInputAddress from '../../components/shared/FormComponents/FormInputAddress'
import FormInputCheckbox from '../../components/shared/FormComponents/FormInputCheckbox'
import FormInputRadio from '../../components/shared/FormComponents/FormInputRadio'
import FormInputText from '../../components/shared/FormComponents/FormInputText'
import TbxTooltip from '../../components/shared/TbxTooltip'
import {
    getAddressDetailsFromAddressComponents,
    getPlaceIdFromDetails,
    hasDeliveryForAddress,
} from '../../helpers/addressUtils'
import { acceptedPatterns } from '../../helpers/utilities'

const classes = {
    orderDetails: {
        p: 3,
        gap: '24px',
        minHeight: {
            lg: 'calc(100vh - 114px)',
        },
    },
    orderDetailsSection: {
        padding: 1,
        gap: '24px',
    },
    sectionTitle: {
        width: '200px',
        flex: '1 0 auto',
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const OrderDetails = ({ form }) => {
    const { t } = useTranslation()
    const { organisationId } = useParams()

    const { clearErrors, control, formState, getValues, resetField, setError, setValue, watch } = form

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)
    const storeSettings = useSelector((state) => selectStoreSettings(state, { organisationId }))

    const shippingOptions = useMemo(() => {
        const options = []
        if (storeSettings?.webStorePickupEnabled) {
            options.push({
                label: 'Pickup',
                value: 'pickup',
                icon: <MyLocationRounded />,
            })
        }
        if (storeSettings?.webStoreDeliveryEnabled && !!storeSettings?.webStoreDeliveryRegions.length) {
            options.push({
                label: 'Delivery',
                value: 'delivery',
                icon: <LocalShippingRounded />,
            })
        }
        return options
    }, [storeSettings])

    const billingAddressFields = watch(['billingAddress.line1', 'billingAddress.googleAddress'])
    const selectedShippingOption = watch('shippingOption')
    const sameAsBillingAddress = watch('sameAsBillingAddress')

    const setAddressError = (addressType, errorMessage) => {
        if (addressType === 'billingAddress') {
            setError('sameAsBillingAddress', {
                type: 'invalidAddress',
                message: errorMessage,
            })
        } else {
            setError('deliveryAddress.googleAddress', {
                type: 'invalidAddress',
                message: errorMessage,
            })
        }
    }

    const clearAddressError = (addressType) => {
        if (addressType === 'billingAddress') {
            clearErrors('sameAsBillingAddress')
        } else {
            clearErrors('deliveryAddress.googleAddress')
        }
    }

    const hasCompletedBillingAddress = useMemo(() => {
        return billingAddressFields.some((value) => Boolean(value))
    }, [billingAddressFields])

    const setAddressPlaceId = async (addressType) => {
        const placeId = await getPlaceIdFromDetails(
            getValues(`${addressType}.line1`) ?? '',
            getValues(`${addressType}.city`) ?? '',
            getValues(`${addressType}.state`) ?? '',
            getValues(`${addressType}.country`) ?? '',
            getValues(`${addressType}.postcode`) ?? ''
        )
        return placeId
    }

    const validateAddressDeliveryStatus = useCallback(
        async (addressType) => {
            let placeId = null
            let errorMessage = ''

            if (
                (isContactLoggedIn || isGuestLoggedIn) &&
                !getValues(`${addressType}.googleAddress`)?.place_id &&
                getValues(`${addressType}.line1`)
            ) {
                placeId = await setAddressPlaceId(addressType)
            }

            if (getValues(`${addressType}.googleAddress`)?.place_id) {
                placeId = getValues(`${addressType}.googleAddress`).place_id
            }

            if (!placeId) {
                return
            }

            const validDeliveryAddress = await hasDeliveryForAddress(storeSettings.webStoreDeliveryRegions, placeId)

            if (!validDeliveryAddress) {
                errorMessage = t(
                    'We are not currently delivering to the specified location. Please indicate another address.'
                )
                setAddressError(addressType, errorMessage)
            }

            if (validDeliveryAddress) {
                clearAddressError(addressType)
            }
        },
        [isContactLoggedIn, storeSettings, setAddressPlaceId]
    )

    const setAddressDetails = (addressType, addressDetails) => {
        const { addressLine1, city, country, postCode, state } = getAddressDetailsFromAddressComponents(addressDetails)

        setValue(`${addressType}.line1`, addressLine1)
        setValue(`${addressType}.city`, city)
        setValue(`${addressType}.state`, state)
        setValue(`${addressType}.country`, country)
        setValue(`${addressType}.postCode`, postCode)

        if (addressType === 'deliveryAddress') {
            validateAddressDeliveryStatus(addressType)
        }
    }

    const clearAddressDetails = (addressType) => {
        resetField(`${addressType}.line1`)
        resetField(`${addressType}.line2`)
        resetField(`${addressType}.city`)
        resetField(`${addressType}.state`)
        resetField(`${addressType}.country`)
        resetField(`${addressType}.postCode`)
        if (addressType === 'billingAddress') {
            resetField('sameAsBillingAddress')
        }
        clearAddressError(addressType)
    }

    useEffect(() => {
        if (selectedShippingOption === 'delivery') {
            if (sameAsBillingAddress) {
                validateAddressDeliveryStatus('billingAddress')
            } else {
                setValue('deliveryAddress.googleAddress', getValues('deliveryAddress.line1'))
                validateAddressDeliveryStatus('deliveryAddress')
            }
        }
        if (selectedShippingOption === 'pickup') {
            setValue('sameAsBillingAddress', false, { shouldValidate: true })
            clearAddressError('deliveryAddress')
            clearAddressError('billingAddress')
        }
    }, [sameAsBillingAddress, selectedShippingOption])

    return (
        <Box
            component="article"
            display="flex"
            flexDirection="column"
            sx={classes.orderDetails}
        >
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Box sx={classes.sectionTitle}>
                    <Typography
                        fontWeight={700}
                        mb={0.5}
                        textTransform={'uppercase'}
                        variant="body1"
                    >
                        {t('Personal details')}
                    </Typography>
                    <Typography variant="small">{t('All fields are required')}</Typography>
                </Box>
                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Full name')}
                            name="name"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={isContactLoggedIn}
                            inputType="email"
                            label={t('Email')}
                            name="email"
                            rules={{
                                required: 'This field is required',
                                pattern: {
                                    value: acceptedPatterns.email,
                                    message: 'Invalid email address',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Company name')}
                            name="companyName"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Phone number')}
                            name="phone"
                            rules={{ required: 'This field is required' }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Typography
                    fontWeight={700}
                    sx={classes.sectionTitle}
                    textTransform={'uppercase'}
                    variant="body1"
                >
                    {t('Billing address')}
                </Typography>
                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <FormInputAddress
                            control={control}
                            label={t('Search address')}
                            name="billingAddress.googleAddress"
                            rules={hasCompletedBillingAddress ? undefined : { required: 'This field is required' }}
                            shouldUnregister={true}
                            onAddressChanged={setAddressDetails}
                            onAddressClear={clearAddressDetails}
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={true}
                            label={t('Address')}
                            name="billingAddress.line1"
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            label={t('Office, suite, etc. (optional)')}
                            name="billingAddress.line2"
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={true}
                            label={t('Zip/Postal code')}
                            name="billingAddress.postCode"
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={true}
                            label={t('City/County/Town/Suburb')}
                            name="billingAddress.city"
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={true}
                            label={t('State/Province/Region')}
                            name="billingAddress.state"
                        />
                    </Grid>
                    <Grid
                        xs={6}
                        item
                    >
                        <FormInputText
                            control={control}
                            disabled={true}
                            label={t('Country')}
                            name="billingAddress.country"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                component="section"
                display="flex"
                sx={classes.orderDetailsSection}
            >
                <Typography
                    fontWeight={700}
                    sx={classes.sectionTitle}
                    textTransform={'uppercase'}
                    variant="body1"
                >
                    {t('Shipping options')}
                </Typography>

                <Grid
                    className="sectionFormGroup"
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <FormInputRadio
                            control={control}
                            name="shippingOption"
                            options={shippingOptions}
                        />
                    </Grid>

                    {storeSettings.webStorePickupEnabled && selectedShippingOption === 'pickup' ? (
                        <Grid
                            xs={12}
                            item
                        >
                            <Typography variant="body1">{t('Pickup from our address:')}</Typography>

                            <Typography
                                style={{ fontWeight: 700 }}
                                variant="body1"
                            >
                                {storeSettings.pickupAddress || t('View our website for our current address')}
                            </Typography>
                        </Grid>
                    ) : null}

                    {storeSettings.webStoreDeliveryEnabled &&
                    !!storeSettings.webStoreDeliveryRegions.length &&
                    selectedShippingOption === 'delivery' ? (
                        <>
                            <Grid
                                xs={12}
                                item
                            >
                                <TbxTooltip
                                    title={
                                        hasCompletedBillingAddress
                                            ? ''
                                            : t('Please fulfill billing address details before selecting this option.')
                                    }
                                    arrow
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormInputCheckbox
                                            control={control}
                                            disabled={!hasCompletedBillingAddress}
                                            label={t('My delivery address is the same as my billing address')}
                                            name="sameAsBillingAddress"
                                        />
                                        {formState.errors ? (
                                            <Typography
                                                color="error"
                                                variant="caption"
                                            >
                                                {formState.errors.sameAsBillingAddress?.message}
                                            </Typography>
                                        ) : null}
                                    </div>
                                </TbxTooltip>
                            </Grid>

                            {!sameAsBillingAddress ? (
                                <>
                                    <Grid
                                        xs={12}
                                        item
                                    >
                                        <FormInputAddress
                                            control={control}
                                            label={t('Search address')}
                                            name="deliveryAddress.googleAddress"
                                            rules={{ required: t('This field is required') }}
                                            shouldUnregister={true}
                                            onAddressChanged={setAddressDetails}
                                            onAddressClear={clearAddressDetails}
                                        />
                                    </Grid>

                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            disabled={true}
                                            label={t('Address')}
                                            name="deliveryAddress.line1"
                                        />
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            label={t('Office, suite, etc. (optional)')}
                                            name="deliveryAddress.line2"
                                        />
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            disabled={true}
                                            label={t('Post Code')}
                                            name="deliveryAddress.postCode"
                                        />
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            disabled={true}
                                            label={t('City')}
                                            name="deliveryAddress.city"
                                        />
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            disabled={true}
                                            label={t('State')}
                                            name="deliveryAddress.state"
                                        />
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        item
                                    >
                                        <FormInputText
                                            control={control}
                                            disabled={true}
                                            label={t('Country')}
                                            name="deliveryAddress.country"
                                        />
                                    </Grid>
                                </>
                            ) : null}

                            <Grid
                                xs={12}
                                item
                            >
                                <Typography
                                    style={{ fontWeight: 700 }}
                                    variant="body1"
                                >
                                    {t(
                                        'We will contact you at your provided email address with a $t(quote) for an additional fee for delivery to your shipping address.'
                                    )}
                                </Typography>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </Box>
        </Box>
    )
}

//props validation
OrderDetails.propTypes = {
    form: PropTypes.object.isRequired,
}

export default OrderDetails
